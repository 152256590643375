import { formatter } from '@app/utils/utils';
import moment from 'moment';

export const userColumn = [
  {
    title: 'Mã nhân viên',
    dataIndex: 'ma',
  },
  {
    title: 'Tên nhân viên',
    dataIndex: 'ten_day_du',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'so_dien_thoai',
    align: 'right',
  },
  {
    title: 'Nhóm quyền',
    dataIndex: 'vai_tro_id',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const nhanVienColumn = [
  {
    title: 'Mã nhân viên',
    dataIndex: 'ma',
  },
  {
    title: 'Tên nhân viên',
    dataIndex: 'ten_day_du',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'so_dien_thoai',
    align: 'right',
  },
  {
    title: 'Nhóm quyền',
    dataIndex: 'loai_nguoi_dung',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const giaoVienColumn = [
  {
    title: 'Mã giáo viên',
    dataIndex: 'ma',
  },
  {
    title: 'Tên giáo viên',
    dataIndex: 'ten_day_du',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const roleColumn = [
  {
    title: 'ID vai trò',
    dataIndex: 'id',
    align: 'right',
  },
  {
    title: 'Tên',
    dataIndex: 'ten_vai_tro',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const chiNhanhColumn = [
  {
    title: 'Mã chi nhánh',
    dataIndex: 'ma_chi_nhanh',
  },
  {
    title: 'Tên chi nhánh',
    dataIndex: 'ten_chi_nhanh',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'so_dien_thoai',
    align: 'right',
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'dia_chi',
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const nienKhoaColumn = [
  {
    title: 'Mã niên khoá',
    dataIndex: 'ma_nien_khoa',
    align: 'right',
  },
  {
    title: 'Tên niên khoá',
    dataIndex: 'ten_nien_khoa',
    align: 'right',
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const khoiColumn = [
  {
    title: 'Chương trình',
    dataIndex: 'ten_chuong_trinh',
  },
  {
    title: 'Tên Cấp độ',
    dataIndex: 'ten_khoi',
  },
  {
    title: 'Mã Cấp độ',
    dataIndex: 'ma_khoi',
  },
  {
    title: 'Chứng nhận',
    dataIndex: 'ten_chung_chi',
    render: (record: string): string => {
      return record === null ? 'Chưa cấu hình chứng nhận' : record;
    },
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const productGroupColumn = [
  {
    title: 'Mã nhóm',
    dataIndex: 'ma',
  },
  {
    title: 'Tên nhóm',
    dataIndex: 'ten',
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const productColumn = [
  {
    title: 'Mã sản phẩm',
    dataIndex: 'ma',
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'ten',
  },
  {
    title: 'Đơn vị tính',
    dataIndex: 'ten_don_vi_tinh',
  },
  {
    title: 'Nhóm sản phẩm',
    dataIndex: 'ten_nhom_san_pham',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'trang_thai',
    render: (record: number): string => {
      return record === 1 ? 'Hoạt động' : 'Không hoạt động';
    },
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const boMonColumn = [
  {
    title: 'Tên bộ môn',
    dataIndex: 'ten_bo_mon',
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const monHocColumn = [
  {
    title: 'Mã khoá học',
    dataIndex: 'ma_mon_hoc',
  },
  {
    title: 'Tên khoá học',
    dataIndex: 'ten_mon_hoc',
  },
  {
    title: 'Tên Cấp độ',
    dataIndex: 'ten_khoi',
  },
  // { title: 'Bộ môn', dataIndex: 'ten_bo_mon' },
  { title: 'Điểm tối đa', dataIndex: 'diem_toi_da', align: 'right' },
  { title: 'Điểm đạt', dataIndex: 'diem_qua_mon', align: 'right' },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const lopHocColumn = [
  {
    title: 'Mã lớp',
    dataIndex: 'ma_lop',
  },
  {
    title: 'Tên lớp',
    dataIndex: 'ten_lop',
  },
  {
    title: 'Tên Cấp độ',
    dataIndex: 'ten_khoi',
  },
  {
    title: 'Giáo viên',
    dataIndex: 'giao_vien_phu_trach_ten_day_du',
  },
  {
    title: 'Ngày bắt đầu',
    dataIndex: 'ngay_bat_dau',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày kết thúc',
    dataIndex: 'ngay_ket_thuc',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Trạng thái',
    render: (record: any) => {
      const currentDate = moment();
      const dayEnd = moment(record.ngay_ket_thuc);
      if (currentDate.isSame(dayEnd, 'day')) {
        return 'Finish';
      } else if (currentDate.isBefore(dayEnd)) {
        return 'Unfinish';
      } else {
        return 'Finish';
      }
    },
  },
  {
    title: 'Số lượng',
    dataIndex: 'so_luong',
    align: 'right',
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const phongHocColumn = [
  {
    title: 'Mã phòng',
    dataIndex: 'ma_phong',
  },
  {
    title: 'Tên phòng',
    dataIndex: 'ten_phong',
  },
  {
    title: 'Số lượng',
    dataIndex: 'so_luong',
    align: 'right',
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const parentsColumn = [
  {
    title: 'Tên phụ huynh',
    dataIndex: 'ten_day_du',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'so_dien_thoai',
    align: 'right',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const studentParentColumn = [
  {
    title: 'Tên phụ huynh',
    dataIndex: 'phu_huynh_ten_day_du',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phu_huynh_so_dien_thoai',
    align: 'right',
  },
  {
    title: 'Email',
    dataIndex: 'phu_huynh_email',
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const studentsColumn = [
  {
    title: 'Mã học viên',
    dataIndex: 'ma',
  },
  {
    title: 'Tên học viên',
    dataIndex: 'ten_day_du',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'so_dien_thoai',
    align: 'right',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const thoiKhoaBieuColumn = [];

export const dangKyGioDayColumn = [
  {
    title: 'Mã giáo viên',
    dataIndex: 'ma_giao_vien',
  },
  {
    title: 'Tên giáo viên',
    dataIndex: 'ten_giao_vien',
  },
  {
    title: 'Ngày dạy',
    dataIndex: 'ngay',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Tiết dạy',
    dataIndex: 'ten_tiet_hoc',
    align: 'right',
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const hopDongLaoDongColumn = [
  {
    title: 'Số hợp đồng',
    dataIndex: 'so_hop_dong',
  },
  {
    title: 'Tên giáo viên',
    dataIndex: 'giao_vien_ten_day_du',
  },
  {
    title: 'Ngày ký',
    dataIndex: 'ngay_ky',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Loại hợp đồng',
    dataIndex: 'ten_loai_hop_dong',
  },
  {
    title: 'Thời hạn',
    dataIndex: 'thoi_han',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  { title: 'Người tạo', dataIndex: 'nguoi_tao_ten_day_du' },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return record ? date.toLocaleDateString('en-GB') : '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB');
    },
  },
];

export const uuDaiColumn = [
  {
    title: 'Mã ưu đãi',
    dataIndex: 'ma_uu_dai',
  },
  {
    title: 'Loại',
    dataIndex: 'loai',
    render: (record: string): string => {
      return record === 'SO_TIEN' ? 'Số tiền' : record === 'PHAN_TRAM' ? 'Phần trăm' : record;
    },
  },
  {
    title: 'Nhóm',
    dataIndex: 'nhom',
    render: (record: string): string => {
      return record === 'VOUCHER' ? 'Voucher' : record === 'HOC_BONG' ? 'Học bổng' : record;
    },
  },
  {
    title: 'Từ ngày',
    dataIndex: 'thoi_gian_tu',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Đến ngày',
    dataIndex: 'thoi_gian_den',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Số lượng',
    dataIndex: 'so_luong',
    align: 'right',
  },
  {
    title: 'Số lần đã dùng',
    dataIndex: 'da_dung',
    align: 'right',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const loaiHopDongColumn = [
  {
    title: 'Tên loại hợp đồng',
    dataIndex: 'ten_loai',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const dienChinhSachColumn = [
  {
    title: 'Mã diện chính sách',
    dataIndex: 'ma_dien_chinh_sach',
  },
  {
    title: 'Tên diện chính sách',
    dataIndex: 'ten_dien_chinh_sach',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const loaiDiemColumn = [
  {
    title: 'Tên loại điểm',
    dataIndex: 'ten_loai',
  },
  {
    title: 'Nhóm loại điểm',
    dataIndex: 'nhom_loai_diem',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const lyDoColumn = [
  {
    title: 'Hạng mục thu chi',
    dataIndex: 'ly_do',
  },
  {
    title: 'Cấp cha',
    dataIndex: 'ten_ly_do_cha',
  },
  {
    title: 'Loại',
    dataIndex: 'loai',
    render: (record: number): string => {
      return record === 1 ? 'Thu' : 'Chi';
    },
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const donViTinhColumn = [
  {
    title: 'Mã đơn vị tính',
    dataIndex: 'ma',
  },
  {
    title: 'Tên đơn vị tính',
    dataIndex: 'ten',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const thietBiColumn = [
  {
    title: 'Mã thiết bị',
    dataIndex: 'ma_thiet_bi',
  },
  {
    title: 'Tên thiết bị',
    dataIndex: 'ten_thiet_bi',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const chungChiColumn = [
  {
    title: 'Mã chứng nhận',
    dataIndex: 'ma_chung_chi',
  },
  {
    title: 'Tên chứng nhận',
    dataIndex: 'ten_chung_chi',
  },
  {
    title: 'Thời hạn (tháng)',
    dataIndex: 'thoi_han',
    align: 'right',
    render: (record: number) => {
      return record === 0 ? 'Không thời hạn' : record;
    },
  },
  {
    title: 'Cấp độ',
    dataIndex: 'ten_khoi',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const nganSachColumn = [
  {
    title: 'Năm',
    dataIndex: 'ten_nien_khoa',
  },
  {
    title: 'Ngân sách',
    dataIndex: 'ly_do',
  },
  {
    title: 'Mục chi',
    dataIndex: 'ly_do_con',
  },
  {
    title: 'Số tiền',
    dataIndex: 'so_tien',
    align: 'right',
    render: (record: string): string => {
      return formatter(record);
    },
  },
  {
    title: 'Còn lại',
    dataIndex: 'so_tien_con_lai',
    align: 'right',
    render: (record: string): string => {
      return formatter(record);
    },
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const capChungChiColumn = [
  {
    title: 'Mã học viên',
    dataIndex: 'ma_hoc_vien',
  },
  {
    title: 'Tên học viên',
    dataIndex: 'ten_hoc_vien',
  },
  {
    title: 'Mã lớp',
    dataIndex: 'ma_lop_hoc',
  },
  {
    title: 'Tên lớp',
    dataIndex: 'ten_lop_hoc',
    align: 'right',
  },
  {
    title: 'Chứng nhận đạt điều kiện',
    dataIndex: 'ten_chung_chi',
  },
  {
    title: 'Xếp loại',
    dataIndex: 'xep_loai',
  },
  {
    title: 'Tình trạng',
    dataIndex: 'da_cap',
    render: (record: number): string => {
      return record === 1 ? 'Đã cấp' : 'Chưa cấp';
    },
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const thuHocPhiColumn = [
  {
    title: 'Mã phiếu thu',
    dataIndex: 'ma',
  },
  {
    title: 'Ngày thu',
    dataIndex: 'ngay_thuc_hien',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Mã học viên',
    dataIndex: 'hoc_vien_ma',
  },
  {
    title: 'Tên học viên',
    dataIndex: 'hoc_vien_ten_day_du',
  },
  {
    title: 'Số tiền',
    dataIndex: 'tong_tien',
    align: 'right',
    render: (record: string): string => {
      return formatter(record);
    },
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const nhaCungCapColumn = [
  {
    title: 'Mã nhà cung cấp',
    dataIndex: 'ma',
  },
  {
    title: 'Tên nhà cung cấp',
    dataIndex: 'ten',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'so_dien_thoai',
    align: 'right',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const muaHangColumn = [
  {
    title: 'Mã đơn hàng',
    dataIndex: 'ma_phieu_mua_hang',
  },
  {
    title: 'Nhà cung cấp',
    dataIndex: 'ten_nha_cung_cap',
  },
  {
    title: 'Thành tiền trước thuế',
    dataIndex: 'thanh_tien_truoc_thue',
    align: 'right',
    render: (record: number): string => {
      return formatter(record);
    },
  },
  {
    title: 'Tiền thuế',
    dataIndex: 'thanh_tien_thue',
    align: 'right',
    render: (record: number): string => {
      return formatter(record);
    },
  },
  {
    title: 'Thành tiền sau thuế',
    dataIndex: 'thanh_tien_sau_thue',
    align: 'right',
    render: (record: number): string => {
      return formatter(record);
    },
  },
  {
    title: 'Đã thanh toán',
    dataIndex: 'so_tien_da_thanh_toan',
    align: 'right',
    render: (record: number): string => {
      return formatter(record);
    },
  },
  {
    title: 'Trạng thái thanh toán',
    dataIndex: 'trang_thai_thanh_toan',
    render: (record: number, data: any): string => {
      if (data.phan_tram_giam === 100) {
        return 'Đã thanh toán';
      } else if (record === 1) {
        return 'Đã thanh toán';
      } else if (record === 0) {
        return 'Chưa thanh toán';
      } else {
        return 'Đã thanh toán 1 phần';
      }
    },
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const banHangColumn = [
  {
    title: 'Mã đơn hàng',
    dataIndex: 'ma_phieu_ban_hang',
  },
  {
    title: 'Khách hàng',
    dataIndex: 'khach_hang_ten_day_du',
  },
  {
    title: 'Thành tiền trước thuế',
    dataIndex: 'thanh_tien_truoc_thue',
    align: 'right',
    render: (record: number): string => {
      return formatter(record);
    },
  },
  {
    title: 'Tiền thuế',
    dataIndex: 'thanh_tien_thue',
    align: 'right',
    render: (record: number): string => {
      return formatter(record);
    },
  },
  {
    title: 'Thành tiền sau thuế',
    dataIndex: 'thanh_tien_sau_thue',
    align: 'right',
    render: (record: number): string => {
      return formatter(record);
    },
  },
  {
    title: 'Đã thanh toán',
    dataIndex: 'so_tien_da_thanh_toan',
    align: 'right',
    render: (record: number): string => {
      return formatter(record);
    },
  },
  {
    title: 'Trạng thái thanh toán',
    dataIndex: 'trang_thai_thanh_toan',
    render: (record: number, data: any): string => {
      if (data.phan_tram_giam === 100) {
        return 'Đã thanh toán';
      } else if (record === 1) {
        return 'Đã thanh toán';
      } else if (record === 0) {
        return 'Chưa thanh toán';
      } else {
        return 'Đã thanh toán 1 phần';
      }
    },
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];
/*DECLARE_IMPPORT_CONFIG_HERE*/

export const diemDanhHSColumn = [
  {
    title: 'Mã lớp',
    dataIndex: 'ma_lop_hoc',
  },
  {
    title: 'Tên lớp',
    dataIndex: 'ten_lop_hoc',
  },
  {
    title: 'khoá học',
    dataIndex: 'mon_hoc_ten_mon_hoc',
  },
  {
    title: 'Tiết',
    dataIndex: 'tiet_ten_tiet_hoc',
  },
];

export const diemDanhNhanVienColumn = [
  {
    title: 'Mã nhân viên',
    dataIndex: 'nguoi_dung_ma',
  },
  {
    title: 'Họ tên',
    dataIndex: 'ho_ten',
  },
];

export const khoColumn = [
  {
    title: 'Mã kho',
    dataIndex: 'ma',
  },
  {
    title: 'Tên kho',
    dataIndex: 'ten',
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'dia_chi',
  },
  {
    title: 'Người liên hệ',
    dataIndex: 'nguoi_lien_he',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'so_dien_thoai',
    align: 'right',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const nhapKhoColumn = [
  {
    title: 'Mã phiếu nhập',
    dataIndex: 'ma_phieu_nhap_kho',
  },
  {
    title: 'Người giao',
    dataIndex: 'ho_ten_nguoi_giao',
  },
  {
    title: 'Người nhận',
    dataIndex: 'ho_ten_nguoi_nhan',
  },
  {
    title: 'Số lượng',
    dataIndex: 'tong_so',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const xuatKhoColumn = [
  {
    title: 'Mã phiếu xuất',
    dataIndex: 'ma_phieu_xuat_kho',
  },
  {
    title: 'Người giao',
    dataIndex: 'ho_ten_nguoi_giao',
  },
  {
    title: 'Người nhận',
    dataIndex: 'ho_ten_nguoi_nhan',
  },
  {
    title: 'Số lượng',
    dataIndex: 'tong_so',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const chuyenKhoColumn = [
  {
    title: 'Mã chuyển kho',
    dataIndex: 'ma_phieu_chuyen_kho',
  },
  {
    title: 'Người nhận',
    dataIndex: 'ho_ten_nguoi_nhan',
  },
  {
    title: 'Người giao',
    dataIndex: 'ho_ten_nguoi_giao',
  },
  {
    title: 'Kho nhận',
    dataIndex: 'ten_kho_nhan',
  },
  {
    title: 'Kho xuất',
    dataIndex: 'ten_kho_xuat',
  },
  {
    title: 'Số lượng',
    dataIndex: 'tong_so',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const thuTienColumn = [
  {
    title: 'Mã phiếu',
    dataIndex: 'ma',
  },
  {
    title: 'Ngày thực hiện',
    dataIndex: 'ngay_thuc_hien',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Học viên',
    dataIndex: 'hoc_vien_ten_day_du',
  },
  {
    title: 'Người nộp',
    dataIndex: 'nguoi_nop',
  },
  {
    title: 'Số tiền',
    dataIndex: 'so_tien',
    align: 'right',
    render: (record: number): string => {
      return formatter(record);
    },
  },
  { title: 'Ghi chú', dataIndex: 'ghi_chu' },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const tietColumn = [
  {
    title: 'Tên tiết',
    dataIndex: 'ten_tiet_hoc',
  },
  {
    title: 'Giờ bắt đầu',
    dataIndex: 'thoi_gian_bat_dau',
    align: 'right',
    render: (record: string): string => {
      return record.slice(0, 5);
    },
  },
  {
    title: 'Giờ kết thúc',
    dataIndex: 'thoi_gian_ket_thuc',
    align: 'right',
    render: (record: string): string => {
      return record.slice(0, 5);
    },
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const chuongTrinhColumn = [
  {
    title: 'Tên chương trình',
    dataIndex: 'ten_chuong_trinh',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const thuChiKhacColumn = [
  {
    title: 'Tên chương trình',
    dataIndex: 'ten_chuong_trinh',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];

export const cauHinhXepLoaiColumn = [
  {
    title: 'Điểm từ',
    dataIndex: 'diem_tu',
    align: 'right',
  },
  {
    title: 'Điểm đến',
    dataIndex: 'diem_den',
    align: 'right',
  },
  {
    title: 'Xếp loại',
    dataIndex: 'xep_loai',
  },
  {
    title: 'Người tạo',
    dataIndex: 'nguoi_tao_ten_day_du',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'ngay_tao',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
  {
    title: 'Ngày cập nhật',
    dataIndex: 'ngay_sua',
    align: 'right',
    render: (record: string): string => {
      const date = new Date(record);
      return date.toLocaleDateString('en-GB') || '';
    },
  },
];
