import { getAreasList, getDistrictsList } from '@app/api/app/api_getAddress';
import { getDataSelect, getDataSelect2 } from '@app/api/app/api_getDataSelect';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { API_URL } from '@app/configs/api-configs';
import { selectChinhanh } from '@app/store/slices/appSlice';
import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface IProps {
  isEditing: boolean;
  huyenID?: number;
  xaID?: number;
  tinhID?: number;
}

const NhanVienForm: React.FC<IProps> = ({ isEditing, huyenID, tinhID }) => {
  const [vaitro, setVaitro] = useState<{ value: string; label: string }[]>([{ value: '', label: '' }]);
  const [tinh, setTinh] = useState<{ value: string; label: string }[]>([]);
  const [huyen, setHuyen] = useState<any>([]);
  const [xa, setXa] = useState<any>([]);

  const chiNhanh = useSelector(selectChinhanh);

  useEffect(() => {
    const getTinhHuyenXaForForm = async () => {
      if (tinhID) {
        const listHuyen = await getDistrictsList(tinhID);
        setHuyen(listHuyen);
        console.log({ tinhID, listHuyen });
      }

      if (huyenID) {
        const listXa = await getAreasList(huyenID || 0);
        setXa(listXa);
        console.log({ huyenID, listXa });
      }
    };
    getTinhHuyenXaForForm();
  }, [tinhID, huyenID]);

  const onChangeTinh = (values: any) => {
    tinh.map(async (item: any) => {
      if (item.value === values) {
        const huyen = await getDistrictsList(item.value);
        setHuyen(huyen);
      }
    });
  };

  const onChangeHuyen = (values: any) => {
    huyen.map(async (item: any) => {
      if (item.value === values) {
        const xa = await getAreasList(item.value);
        setXa(xa);
      }
    });
  };

  useEffect(() => {
    async function getTinh() {
      const tinh = await getDataSelect2('/tinh', 'limit=1000');
      const optionsSelect = tinh.map((item: any) => {
        return { value: item.id, label: item.ten_tinh };
      });
      setTinh(optionsSelect);
    }
    async function getVaitro() {
      const vaitro = await getDataSelect(API_URL.ROLES);
      const optionsSelect = vaitro.map((item: any) => {
        return { value: item.id, label: item.ten_vai_tro };
      });
      console.log(optionsSelect);
      setVaitro(optionsSelect);
    }
    getTinh();
    getVaitro();
  }, []);

  return (
    <BaseRow gutter={[10, 0]}>
      <BaseCol span={6}>
        <BaseForm.Item name="ho" label="Họ" rules={[{ required: true, message: 'Họ không được bỏ trống!' }]}>
          <BaseInput size="small" placeholder="Nhập họ" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item name="ten" label="Tên" rules={[{ required: true, message: 'Tên không được bỏ trống!' }]}>
          <BaseInput size="small" placeholder="Nhập tên" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item
          name="ngay_sinh"
          label="Ngày sinh"
          rules={[{ required: true, message: 'Ngày sinh không được bỏ trống!' }]}
        >
          <DatePicker size="small" placeholder="Chọn ngày sinh" format="DD/MM/YYYY" style={{ width: '100%' }} />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item
          name="gioi_tinh"
          label="Giới tính"
          rules={[{ required: true, message: 'Mã không được bỏ trống!' }]}
        >
          <BaseSelect
            options={[
              { value: 1, label: 'Nam' },
              { value: 0, label: 'Nữ' },
            ]}
            placeholder="Chọn giới tính"
            size="small"
          />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item
          name="so_dien_thoai"
          label="Số điện thoại"
          rules={[
            { required: true, message: 'Số điện thoại không được bỏ trống!' },
            { pattern: /^[0-9]+$/, message: 'Vui lòng chỉ nhập số!' },
            { len: 10, message: 'Số điện thoại phải có 10 số!' },
          ]}
        >
          <BaseInput size="small" placeholder="Nhập số điện thoại" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Mã không được bỏ trống!' },
            { type: 'email', message: 'Vui lòng nhập đúng định dạng email!' },
          ]}
        >
          <BaseInput size="small" placeholder="Nhập email" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item
          name="ngay_vao"
          label="Ngày vào làm"
          rules={[{ required: true, message: 'Mã không được bỏ trống!' }]}
        >
          <DatePicker size="small" placeholder="Chọn ngày vào" format="DD/MM/YYYY" style={{ width: '100%' }} />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item name="ma" label="Mã nhân viên" rules={[{ required: true, message: 'Mã không được bỏ trống!' }]}>
          <BaseInput size="small" disabled={isEditing} placeholder="Nhập mã nhân viên" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={24}>
        <BaseForm.Item name="dia_chi" label="Địa chỉ" rules={[{ required: true, message: 'Mã không được bỏ trống!' }]}>
          <BaseInput size="small" placeholder="Nhập địa chỉ" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item name="tinh_id" label="Tỉnh" initialValue={null}>
          <BaseSelect options={tinh} onChange={onChangeTinh} placeholder="Chọn tỉnh" size="small" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item name="huyen_id" label="Huyện" initialValue={null}>
          <BaseSelect options={huyen} onChange={onChangeHuyen} placeholder="Chọn huyện" size="small" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item name="xa_id" label="Xã" initialValue={null}>
          <BaseSelect options={xa} placeholder="Chọn xã" size="small" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}></BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item
          name="tai_khoan"
          label="Tài khoản"
          rules={[{ required: true, message: 'Tài khoản không được bỏ trống!' }]}
        >
          <BaseInput size="small" placeholder="Nhập tài khoản" disabled={isEditing} />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item
          name="vai_tro_id"
          label="Nhóm quyền"
          rules={[{ required: true, message: 'Vai trò không được bỏ trống!' }]}
        >
          <BaseSelect options={vaitro} placeholder="Chọn vai trò" size="small" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item
          name="mat_khau"
          label="Mật khẩu"
          rules={[{ required: isEditing ? false : true, message: 'Mật khẩu không được bỏ trống!' }]}
        >
          <BaseInput.Password size="small" placeholder="Nhập mật khẩu" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item
          name="xac_nhan_mat_khau"
          label="Xác nhận mật khẩu"
          rules={[
            { required: isEditing ? false : true, message: 'Xác nhận mật khẩu không được bỏ trống!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('mat_khau') === value) {
                  console.log('value', value);
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Mật khẩu không khớp!'));
              },
            }),
          ]}
        >
          <BaseInput.Password size="small" placeholder="Nhập mật khẩu" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item name="id_the_tu" label="Id thẻ từ" hidden>
          <BaseInput size="small" placeholder="Nhập id thẻ từ" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item name="trinh_do_hoc_van" label="Trình độ học vấn" hidden>
          <BaseInput size="small" placeholder="Nhập trình độ" />
        </BaseForm.Item>
      </BaseCol>
      <BaseCol span={6}>
        <BaseForm.Item name="chi_nhanh_id" label="Chi nhánh" initialValue={chiNhanh} hidden>
          <BaseSelect />
        </BaseForm.Item>
      </BaseCol>
    </BaseRow>
  );
};

export default NhanVienForm;
