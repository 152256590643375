import { getDataSelect } from '@app/api/app/api_getDataSelect';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { saveChiNhanh, saveNienKhoa } from '@app/store/slices/appSlice';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import * as S from '../Header.styles';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const [chinhanh, setChinhanh] = useState<{ value: string; label: string }[]>([{ value: '', label: '' }]);
  const [nienkhoa, setNienkhoa] = useState<{ value: string; label: string }[]>([{ value: '', label: '' }]);
  const chinhanh_id = useAppSelector((state) => state.user.user.chi_nhanh_id) || '';
  const loaiNguoiDung = useAppSelector((state) => state.user.user.loai_nguoi_dung);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const cauHinhChungString = localStorage.getItem('cauHinhChung');
  const cauHinhChung = cauHinhChungString ? JSON.parse(cauHinhChungString) : null;
  const savedChinhanh = Number(localStorage.getItem('chiNhanh')) || chinhanh_id;
  const savedNienkhoa = cauHinhChung?.nien_khoa_mac_dinh || null;

  useEffect(() => {
    async function getChinhanh() {
      const chinhanh = await getDataSelect('/chi-nhanh/options');
      const optionsSelect = chinhanh?.map((item: any) => {
        return { value: item.id, label: item.ten_chi_nhanh };
      });
      setChinhanh(optionsSelect);
    }
    async function getNienKhoa() {
      const nienkhoa = await getDataSelect('/nien-khoa/options');
      const optionsSelect = nienkhoa?.map((item: any) => {
        return { value: item.id, label: item.ten_nien_khoa };
      });
      setNienkhoa(optionsSelect);
    }
    if (cauHinhChung?.nien_khoa_mac_dinh === null) {
      notificationController.error({
        message: 'Vui lòng chọn niên khoá',
      });
    }
    getNienKhoa();
    getChinhanh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({ chinhanh: savedChinhanh || chinhanh_id, nienkhoa: savedNienkhoa });
    if (savedChinhanh) {
      dispatch(saveChiNhanh(savedChinhanh));
    }
    if (savedNienkhoa) {
      dispatch(saveNienKhoa(savedNienkhoa));
    }
  }, [chinhanh_id, dispatch, form, savedChinhanh, savedNienkhoa]);

  const onChangeChiNhanh = (value: any) => {
    dispatch(saveChiNhanh(value));
  };

  const onChangeNienKhoa = (value: any) => {
    dispatch(saveNienKhoa(value));
  };

  // const leftSide = isTwoColumnsLayout ? (
  //   <S.SearchColumn xl={16} xxl={17}>
  //     <BaseRow justify="space-between">
  //       <BaseCol xl={15} xxl={12}>
  //         <HeaderSearch />
  //       </BaseCol>
  //       <BaseCol>
  //         <S.GHButton />
  //       </BaseCol>
  //     </BaseRow>
  //   </S.SearchColumn>
  // ) : (
  //   <>
  //     <BaseCol lg={10} xxl={8}>
  //       <HeaderSearch />
  //     </BaseCol>
  //     <BaseCol>
  //       <S.GHButton />
  //     </BaseCol>
  //   </>
  // );

  return (
    <BaseRow justify="space-between" align="middle">
      {/* {leftSide} */}
      <BaseCol style={{ paddingLeft: '10px' }} span={8}>
        <BaseForm form={form} layout="vertical" style={{ width: '100%' }}>
          <BaseRow gutter={[10, 10]}>
            <BaseCol span={8}>
              <BaseForm.Item name="nienkhoa">
                <BaseSelect size="small" onChange={onChangeNienKhoa} options={nienkhoa} placeholder="Niên khoá" />
              </BaseForm.Item>
            </BaseCol>
            {loaiNguoiDung?.toLowerCase() === 'ADMIN'.toLowerCase() && (
              <BaseCol span={16}>
                <BaseForm.Item name="chinhanh">
                  <BaseSelect
                    size="small"
                    onChange={onChangeChiNhanh}
                    options={chinhanh}
                    placeholder="Chi nhánh"
                    disabled={loaiNguoiDung.toLowerCase() === 'ADMIN'.toLowerCase() ? false : true}
                  />
                </BaseForm.Item>
              </BaseCol>
            )}
          </BaseRow>
        </BaseForm>
      </BaseCol>
      <S.ProfileColumn $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow align="middle" justify="end" gutter={[5, 5]}>
          <BaseCol>
            <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}>
              <BaseCol>
                <HeaderFullscreen />
              </BaseCol>

              {/* <BaseCol>
                <NotificationsDropdown />
              </BaseCol> */}

              {/* <BaseCol>
                <SettingsDropdown />
              </BaseCol> */}
            </BaseRow>
          </BaseCol>

          <BaseCol>
            <ProfileDropdown />
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  );
};
