import { notificationController } from '@app/controllers/notificationController';
import { getRoleUser } from './redux.utils';
import { roleBaoCao } from './utils';

export const roleCheckRoute = (arrCheck: any) => {
  const roleUser = JSON.parse(getRoleUser());

  const phanTuBaoCao = roleUser.find((item: any) => item.name === 'bao-cao');

  if (phanTuBaoCao && phanTuBaoCao.actions.index) {
    roleUser.push(...roleBaoCao);
  }

  const filteredArray = arrCheck
    .map((item: any) => {
      if (item.children) {
        const filteredChildren = item.children.filter((child: any) => {
          return roleUser.some((role: any) => role.name === child.key && role.actions.index === true);
        });
        return { ...item, children: filteredChildren };
      }
      console.log(item);
      return item;
    })
    .filter((item: any) => {
      if (item.key === 'dashboard') {
        return item;
      }
      return item.children && item.children.length > 0;
    });

  return filteredArray;
};

export const kiemtraNienkhoa = () => {
  const cauHinhChungString = localStorage.getItem('cauHinhChung');
  const cauHinhChung = cauHinhChungString ? JSON.parse(cauHinhChungString) : null;
  const savedNienkhoa = cauHinhChung?.nien_khoa_mac_dinh || null;
  console.log('kiemtraNienkhoa ~ savedNienkhoa:', savedNienkhoa);
  if (savedNienkhoa === null) {
    notificationController.error({
      message: 'Vui lòng chọn niên khoá',
    });
  } else {
    return savedNienkhoa;
  }
};
