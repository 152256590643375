import {
  DollarCircleOutlined,
  HomeOutlined,
  SettingOutlined,
  // BlockOutlined,
  // CompassOutlined,
  // DashboardOutlined,
  // FormOutlined,
  // HomeOutlined,
  // LayoutOutlined,
  // LineChartOutlined,
  // TableOutlined,
  UserOutlined,
} from '@ant-design/icons';
import React from 'react';
import { ReactComponent as HocTapIcon } from '@app/assets/icons/hoc-tap.svg';
import { ReactComponent as HanhChinhIcon } from '@app/assets/icons/hanh-chinh.svg';
import { ReactComponent as MuaBanIcon } from '@app/assets/icons/mua-ban.svg';
import { ReactComponent as BaoCaoIcon } from '@app/assets/icons/bao-cao.svg';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.bang-dieu-khien',
    key: 'dashboard',
    url: '/',
    icon: <HomeOutlined />,
    children: [],
  },
  {
    title: 'common.hoc_tap',
    key: 'hoc-tap',
    icon: <HocTapIcon />,
    children: [
      { title: 'common.chuong-trinh', key: 'chuong-trinh', url: '/hoc-tap/chuong-trinh' },
      { title: 'common.cap-do', key: 'cap-do', url: '/he-thong/cap-do' },
      { title: 'common.lop_hoc', key: 'lop-hoc', url: '/hoc-tap/lop-hoc' },
      { title: 'common.khoa-hoc', key: 'khoa-hoc', url: '/hoc-tap/khoa-hoc' },
      { title: 'common.phong_hoc', key: 'phong-hoc', url: '/hoc-tap/phong-hoc' },
      { title: 'common.thoi_khoa_bieu', key: 'thoi-khoa-bieu', url: '/hoc-tap/thoi-khoa-bieu' },
      { title: 'common.quan_ly_diem', key: 'quan-ly-diem', url: '/hoc-tap/quan-ly-diem' },
      { title: 'common.cap_chung_chi', key: 'cap-chung-chi', url: '/hoc-tap/cap-chung-chi' },
      { title: 'common.diem_danh_hs', key: 'diem-danh-hoc-vien', url: '/diem-danh/diem-danh-hoc-vien' },
      { title: 'common.chung-chi', key: 'chung-chi', url: '/he-thong/chung-chi' },
      // { title: 'common.bo_mon', key: 'bo-mon', url: '/hoc-tap/bo-mon' },
    ],
  },
  {
    title: 'common.hanh_chinh',
    key: 'hanh-chinh',
    icon: <HanhChinhIcon />,
    children: [
      { title: 'common.diem_danh_nhan_vien', key: 'diem-danh-nhan-vien', url: '/diem-danh/diem-danh-nhan-vien' },
      { title: 'common.dang_ky_gio_day', key: 'dang-ky-gio-day', url: '/hanh-chinh/dang-ky-gio-day' },
      { title: 'common.hop_dong_lao_dong', key: 'hop-dong', url: '/hanh-chinh/hop-dong-lao-dong' },
      { title: 'common.loai-hop-dong', key: 'loai-hop-dong', url: '/he-thong/loai-hop-dong' },
    ],
  },
  {
    title: 'common.mua_ban',
    key: 'mua-ban',
    icon: <MuaBanIcon />,
    children: [
      { title: 'common.nhom-san-pham', key: 'nhom-san-pham', url: '/he-thong/nhom-san-pham' },
      { title: 'common.san-pham', key: 'san-pham', url: '/he-thong/san-pham' },
      { title: 'common.nha_cung_cap', key: 'nha-cung-cap', url: '/mua-ban/nha-cung-cap' },
      { title: 'common.mua_hang', key: 'mua-hang', url: '/mua-ban/mua-hang' },
      { title: 'common.ban_hang', key: 'ban-hang', url: '/mua-ban/ban-hang' },
      { title: 'common.nhap_kho', key: 'nhap-kho', url: '/mua-ban/nhap-kho' },
      { title: 'common.xuat_kho', key: 'xuat-kho', url: '/mua-ban/xuat-kho' },
      { title: 'common.chuyen_kho', key: 'chuyen-kho', url: '/mua-ban/chuyen-kho' },
    ],
  },
  {
    title: 'common.tai_chinh',
    key: 'tai-chinh',
    icon: <DollarCircleOutlined />,
    children: [
      { title: 'common.ly-do', key: 'ly-do', url: '/he-thong/ly-do' },
      { title: 'common.ngan-sach', key: 'ngan-sach', url: '/hanh-chinh/ngan-sach' },
      { title: 'common.uu-dai', key: 'uu-dai', url: '/he-thong/uu-dai' },
      { title: 'common.thu_hoc_phi', key: 'thu-hoc-phi', url: '/hanh-chinh/thu-hoc-phi' },
      { title: 'common.thu_tien', key: 'thu-tien', url: '/mua-ban/thu-tien' },
      { title: 'common.chi_tien', key: 'chi-tien', url: '/mua-ban/chi-tien' },
      { title: 'common.thu-chi-khac', key: 'thu-chi-khac', url: '/mua-ban/thu-chi-khac' },
    ],
  },
  {
    title: 'common.nguoi_dung',
    key: 'nguoi-dung',
    icon: <UserOutlined />,
    children: [
      { title: 'common.nhan_vien', key: 'users', url: '/nguoi-dung/nhan-vien' },
      { title: 'common.hoc_vien', key: 'hoc-vien', url: '/nguoi-dung/hoc-vien' },
      { title: 'common.phu_huynh', key: 'phu-huynh', url: '/nguoi-dung/phu-huynh' },
      { title: 'common.giao_vien', key: 'giao-vien', url: '/nguoi-dung/giao-vien' },
      { title: 'common.vai-tro', key: 'roles', url: '/he-thong/vai-tro' },
    ],
  },
  {
    title: 'common.bao_cao',
    key: 'bao-cao',
    icon: <BaoCaoIcon />,
    children: [
      {
        title: 'baocao.tong-hop-uu-dai-hoc-phi',
        key: 'bao-cao/tong-hop-uu-dai-hoc-phi',
        url: '/bao-cao/tong-hop-uu-dai-hoc-phi',
      },
      {
        title: 'baocao.uu-dai-hoc-phi-theo-hoc-vien',
        key: 'bao-cao/uu-dai-hoc-phi-theo-hoc-vien',
        url: '/bao-cao/uu-dai-hoc-phi-theo-hoc-vien',
      },
      {
        title: 'baocao.chi-tiet-qua-trinh-dao-tao-hoc-vien',
        key: 'bao-cao/chi-tiet-qua-trinh-dao-tao-hoc-vien',
        url: '/bao-cao/chi-tiet-qua-trinh-dao-tao-hoc-vien',
      },
      {
        title: 'baocao.nhap-kho',
        key: 'bao-cao/nhap-kho',
        url: '/bao-cao/nhap-kho',
      },
      {
        title: 'baocao.xuat-kho',
        key: 'bao-cao/xuat-kho',
        url: '/bao-cao/xuat-kho',
      },
      {
        title: 'baocao.tong-hop-xuat-nhap-kho',
        key: 'bao-cao/tong-hop-xuat-nhap-kho',
        url: '/bao-cao/tong-hop-xuat-nhap-kho',
      },
      {
        title: 'baocao.tong-hop-xuat-nhap-kho-hang-hoa',
        key: 'bao-cao-tong-hop-xuat-nhap-kho/hang-hoa',
        url: '/bao-cao/tong-hop-xuat-nhap-kho-hang-hoa',
      },
      {
        title: 'baocao.bang-diem-hoc-vien-theo-lop',
        key: 'bao-cao/bang-diem-hoc-vien-theo-lop',
        url: '/bao-cao/bang-diem-hoc-vien-theo-lop',
      },
      {
        title: 'baocao.cham-cong',
        key: 'bao-cao/cham-cong',
        url: '/bao-cao/cham-cong',
      },
      {
        title: 'baocao.bao-cao-diem-danh-hoc-vien',
        key: 'bao-cao-diem-danh-hoc-vien',
        url: 'bao-cao/bao-cao-diem-danh-hoc-vien',
      },
      {
        title: 'baocao.mua-hang',
        key: 'bao-cao-mua-hang',
        url: '/bao-cao/mua-hang',
      },
      {
        title: 'baocao.ban-hang',
        key: 'bao-cao-ban-hang',
        url: '/bao-cao/ban-hang',
      },
      {
        title: 'baocao.cong-no-khach-hang',
        key: 'bao-cao-cong-no/khach-hang',
        url: '/bao-cao/cong-no-khach-hang',
      },
      {
        title: 'baocao.cong-no-nha-cung-cap',
        key: 'bao-cao-cong-no/nha-cung-cap',
        url: '/bao-cao/cong-no-nha-cung-cap',
      },
      {
        title: 'baocao.thu-hoc-phi',
        key: 'bao-cao-thu-hoc-phi',
        url: '/bao-cao/thu-hoc-phi',
      },
      {
        title: 'baocao.thu-chi',
        key: 'bao-cao-thu-chi/chi-tiet',
        url: '/bao-cao/bao-cao-thu-chi-chi-tiet',
      },
    ],
  },
  {
    title: 'common.he-thong',
    key: 'he-thong',
    icon: <SettingOutlined />,
    children: [
      { title: 'common.cau-hinh-chung', key: 'cau-hinh-chung', url: '/he-thong/cau-hinh-chung' },
      { title: 'common.chi-nhanh', key: 'chi-nhanh', url: '/he-thong/chi-nhanh' },
      { title: 'common.dien-chinh-sach', key: 'dien-chinh-sach', url: '/he-thong/dien-chinh-sach' },
      { title: 'common.kho', key: 'kho', url: '/he-thong/kho' },
      { title: 'common.nien-khoa', key: 'nien-khoa', url: '/he-thong/nien-khoa' },
      { title: 'common.loai-diem', key: 'loai-diem', url: '/he-thong/loai-diem' },
      { title: 'common.cau-hinh-xep-loai', key: 'thang-diem-xep-loai', url: '/he-thong/thang-diem-xep-loai' },
      { title: 'common.thiet-bi', key: 'thiet-bi', url: '/he-thong/thiet-bi' },
      { title: 'common.tiet', key: 'tiet', url: '/he-thong/tiet' },
    ],
  },
  // {
  //   title: 'common.bao-cao-diem-danh',
  //   key: 'bao-cao-dd',
  //   icon: <BaoCaoIcon />,
  //   children: [
  //     {
  //       title: 'common.diem-danh-hoc-vien',
  //       key: 'bao-cao-diem-danh',
  //       url: 'bao-cao-diem-danh/bao-cao-diem-danh-hoc-vien',
  //     },
  //   ],
  // },
];

// export const sidebarNavigation: SidebarNavigationItem[] = [
//   {
//     title: 'common.nft-dashboard',
//     key: 'nft-dashboard',
//     // TODO use path variable
//     url: '/',
//     icon: <NftIcon />,
//   },
//   {
//     title: 'common.medical-dashboard',
//     key: 'medical-dashboard',
//     url: '/medical-dashboard',
//     icon: <DashboardOutlined />,
//   },
//   {
//     title: 'common.apps',
//     key: 'apps',
//     icon: <HomeOutlined />,
//     children: [
//       {
//         title: 'common.feed',
//         key: 'feed',
//         url: '/apps/feed',
//       },
//     ],
//   },
//   {
//     title: 'common.authPages',
//     key: 'auth',
//     icon: <UserOutlined />,
//     children: [
//       {
//         title: 'common.login',
//         key: 'login',
//         url: '/auth/login',
//       },
//       {
//         title: 'common.signUp',
//         key: 'singUp',
//         url: '/auth/sign-up',
//       },
//       {
//         title: 'common.lock',
//         key: 'lock',
//         url: '/auth/lock',
//       },
//       {
//         title: 'common.forgotPass',
//         key: 'forgotPass',
//         url: '/auth/forgot-password',
//       },
//       {
//         title: 'common.securityCode',
//         key: 'securityCode',
//         url: '/auth/security-code',
//       },
//       {
//         title: 'common.newPassword',
//         key: 'newPass',
//         url: '/auth/new-password',
//       },
//     ],
//   },
//   {
//     title: 'common.forms',
//     key: 'forms',
//     icon: <FormOutlined />,
//     children: [
//       {
//         title: 'common.advancedForms',
//         key: 'advanced-forms',
//         url: '/forms/advanced-forms',
//       },
//     ],
//   },
//   {
//     title: 'common.dataTables',
//     key: 'dataTables',
//     url: '/data-tables',
//     icon: <TableOutlined />,
//   },
//   {
//     title: 'common.charts',
//     key: 'charts',
//     url: '/charts',
//     icon: <LineChartOutlined />,
//   },
//   {
//     title: 'common.maps',
//     key: 'maps',
//     icon: <CompassOutlined />,
//     children: [
//       {
//         title: 'common.googleMap',
//         key: 'google-maps',
//         url: '/maps/google-maps',
//       },
//       {
//         title: 'common.leafletMap',
//         key: 'leaflet-maps',
//         url: '/maps/leaflet-maps',
//       },
//       {
//         title: 'common.reactSimpleMaps',
//         key: 'react-simple-maps',
//         url: '/maps/react-simple-maps',
//       },
//       {
//         title: 'common.pigeonMaps',
//         key: 'pigeon-maps',
//         url: '/maps/pigeon-maps',
//       },
//     ],
//   },
//   {
//     title: 'common.pages',
//     key: 'pages',
//     icon: <LayoutOutlined />,
//     children: [
//       {
//         title: 'common.profilePage',
//         key: 'profile',
//         url: '/profile',
//       },
//       {
//         title: 'common.serverError',
//         key: 'serverError',
//         url: '/server-error',
//       },
//       {
//         title: 'common.clientError',
//         key: '404Error',
//         url: '/404',
//       },
//     ],
//   },
//   {
//     title: 'common.ui',
//     key: 'ui',
//     icon: <BlockOutlined />,
//     children: [
//       {
//         title: 'common.alert',
//         key: 'alert',
//         url: '/ui-components/alert',
//       },
//       {
//         title: 'common.avatar',
//         key: 'avatar',
//         url: '/ui-components/avatar',
//       },
//       {
//         title: 'common.autocomplete',
//         key: 'auto-complete',
//         url: '/ui-components/auto-complete',
//       },
//       {
//         title: 'common.badge',
//         key: 'badge',
//         url: '/ui-components/badge',
//       },
//       {
//         title: 'common.breadcrumbs',
//         key: 'breadcrumbs',
//         url: '/ui-components/breadcrumbs',
//       },
//       {
//         title: 'common.button',
//         key: 'button',
//         url: '/ui-components/button',
//       },
//       {
//         title: 'common.checkbox',
//         key: 'checkbox',
//         url: '/ui-components/checkbox',
//       },
//       {
//         title: 'common.collapse',
//         key: 'collapse',
//         url: '/ui-components/collapse',
//       },
//       {
//         title: 'common.dateTimePicker',
//         key: 'dateTimePicker',
//         url: '/ui-components/date-time-picker',
//       },
//       {
//         title: 'common.dropdown',
//         key: 'dropdown',
//         url: '/ui-components/dropdown',
//       },
//       {
//         title: 'common.input',
//         key: 'input',
//         url: '/ui-components/input',
//       },
//       {
//         title: 'common.modal',
//         key: 'modal',
//         url: '/ui-components/modal',
//       },
//       {
//         title: 'common.notification',
//         key: 'notification',
//         url: '/ui-components/notification',
//       },
//       {
//         title: 'common.pagination',
//         key: 'pagination',
//         url: '/ui-components/pagination',
//       },
//       {
//         title: 'common.popconfirm',
//         key: 'popconfirm',
//         url: '/ui-components/popconfirm',
//       },
//       {
//         title: 'common.popover',
//         key: 'popover',
//         url: '/ui-components/popover',
//       },
//       {
//         title: 'common.progress',
//         key: 'progress',
//         url: '/ui-components/progress',
//       },
//       {
//         title: 'common.radio',
//         key: 'radio',
//         url: '/ui-components/radio',
//       },
//       {
//         title: 'common.rate',
//         key: 'rate',
//         url: '/ui-components/rate',
//       },
//       {
//         title: 'common.result',
//         key: 'result',
//         url: '/ui-components/result',
//       },
//       {
//         title: 'common.select',
//         key: 'select',
//         url: '/ui-components/select',
//       },
//       {
//         title: 'common.skeleton',
//         key: 'skeleton',
//         url: '/ui-components/skeleton',
//       },
//       {
//         title: 'common.spinner',
//         key: 'spinner',
//         url: '/ui-components/spinner',
//       },
//       {
//         title: 'common.steps',
//         key: 'steps',
//         url: '/ui-components/steps',
//       },
//       {
//         title: 'common.switch',
//         key: 'switch',
//         url: '/ui-components/switch',
//       },
//       {
//         title: 'common.tabs',
//         key: 'tabs',
//         url: '/ui-components/tabs',
//       },
//       {
//         title: 'common.upload',
//         key: 'upload',
//         url: '/ui-components/upload',
//       },
//     ],
//   },
// ];
