import { apiInstance } from '@app/api/app/api_core';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { API_BASE_URL } from '@app/configs/api-configs';
import { notificationController } from '@app/controllers/notificationController';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import { Button, Form, Row } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import ThoiKhoaBieuForms from './ThoiKhoaBieuForms';

interface propCell {
  path: string;
  idCell?: number;
  onShow: () => void;
  permission: any;
}

const ThoiKhoaBieuCreate: React.FC<propCell> = ({ path, onShow, permission }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCreate = async (values: any) => {
    for (const key in values) {
      if (moment.isMoment(values[key])) {
        values[key] = values[key].format('YYYY-MM-DD');
      }
    }
    try {
      const respUsers: IRespApiSuccess = await apiInstance.post(`${API_BASE_URL}${path}`, values);
      if (respUsers.code === 200) {
        notificationController.success({
          message: 'Tạo thành công',
        });
        onShow();
        handleCancel();
      } else {
        notificationController.error({
          message: respUsers.message,
          description: respUsers.data,
        });
      }
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const showModal = () => {
    setIsModalOpen(true);
    form.resetFields();
    form.setFieldsValue({ khoi_id: null, lop_hoc: null, mon_hoc_id: null, giao_vien_id: null });
  };

  return (
    <UpdateStyles>
      <BaseButton type="primary" onClick={showModal}>
        Thêm
      </BaseButton>
      <BaseModal
        title="Thêm"
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
        centered
        size={'large'}
        footer={null}
      >
        <Form form={form} onFinish={onCreate} layout="vertical">
          <ThoiKhoaBieuForms form={form} isEditing={false} />
          <Row gutter={[10, 10]} justify="end" style={{ marginTop: 6 }}>
            {permission.create && (
              <Button size="small" type="primary" htmlType="submit">
                Lưu
              </Button>
            )}
          </Row>
        </Form>
      </BaseModal>
    </UpdateStyles>
  );
};

const UpdateStyles = styled.div`
  .ant-modal-title {
    text-align: left;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 28px;
  }
`;

export default ThoiKhoaBieuCreate;
