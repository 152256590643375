import React from 'react';
import styled from 'styled-components';

interface LoadingProps {
  size?: string;
  color?: string;
}

export const Loading: React.FC<LoadingProps> = () => {
  return (
    <SpinnerContainer>
      {/* <GlobalSpinner size={size} color={spinnerColor} /> */}
      <div className="loading-overlay">
        <div className="dashed-loading" />
      </div>
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
