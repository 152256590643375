export const filter = [
  {
    value: 'ten_day_du',
    label: 'Tên',
    type: 'string',
  },
  {
    value: 'description',
    label: 'Mô tả',
    type: 'string',
  },
  {
    value: 'created_at',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'updated_at',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const vaitroFilter = [
  {
    value: 'vai_tro.id',
    label: 'ID vai trò',
    type: 'string',
    default: true,
  },
  {
    value: 'vai_tro.ten_vai_tro',
    label: 'Tên vai trò',
    type: 'string',
  },
  {
    value: 'vai_tro.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'vai_tro.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const chiNhanhFilter = [
  {
    value: 'chi_nhanh.ma_chi_nhanh',
    label: 'Mã chi nhánh',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên chi nhánh',
    value: 'chi_nhanh.ten_chi_nhanh',
    type: 'string',
  },
  {
    label: 'Số điện thoại',
    value: 'chi_nhanh.so_dien_thoai',
    type: 'string',
  },
  {
    label: 'Địa chỉ',
    value: 'chi_nhanh.dia_chi',
    type: 'string',
  },
  { label: 'Người tạo', value: 'nguoi_dung.ten_day_du', type: 'string' },
  {
    label: 'Ngày tạo',
    value: 'chi_nhanh.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày cập nhật',
    value: 'chi_nhanh.ngay_sua',
    type: 'datetime',
  },
];

export const nienKhoaFilter = [
  {
    value: 'nien_khoa.ma_nien_khoa',
    label: 'Mã niên khoá',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên niên khoá',
    value: 'nien_khoa.ten_nien_khoa',
    type: 'string',
  },
  { label: 'Người tạo', value: 'nguoi_dung.ten_day_du', type: 'string' },
  {
    label: 'Ngày tạo',
    value: 'nien_khoa.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày cập nhật',
    value: 'nien_khoa.ngay_sua',
    type: 'datetime',
  },
];

export const khoiFilter = [
  {
    value: 'khoi.ma_khoi',
    label: 'Mã Cấp độ',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên Cấp độ',
    value: 'khoi.ten_khoi',
    type: 'string',
  },
  {
    label: 'Chương trình',
    value: 'chuong_trinh.ten_chuong_trinh',
    type: 'string',
  },
  {
    label: 'Cấp độ',
    value: 'cap_do.ten_cap_do',
    type: 'string',
  },
  { label: 'Người tạo', value: 'nguoi_dung.ten_day_du', type: 'string' },
  {
    label: 'Ngày tạo',
    value: 'khoi.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày cập nhật',
    value: 'khoi.ngay_sua',
    type: 'datetime',
  },
];

export const productGroupsFilter = [
  {
    value: 'nhom_san_pham.ma',
    label: 'Mã nhóm',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên nhóm',
    value: 'nhom_san_pham.ten',
    type: 'string',
  },
  { label: 'Người tạo', value: 'nguoi_dung.ten_day_du', type: 'string' },
  {
    label: 'Ngày tạo',
    value: 'nhom_san_pham.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày cập nhật',
    value: 'nhom_san_pham.ngay_sua',
    type: 'datetime',
  },
];

export const productFilter = [
  {
    value: 'san_pham.ma',
    label: 'Mã sản phẩm',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên sản phẩm',
    value: 'san_pham.ten',
    type: 'string',
  },
  {
    label: 'Đơn vị tính',
    value: 'san_pham.ten_don_vi_tinh',
    type: 'string',
  },
  {
    label: 'Nhóm sản phẩm',
    value: 'san_pham.nhom_san_pham_id',
    type: 'string',
  },
  { label: 'Người tạo', value: 'nguoi_dung.ten_day_du', type: 'string' },
  {
    label: 'Ngày tạo',
    value: 'san_pham.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày cập nhật',
    value: 'san_pham.ngay_sua',
    type: 'datetime',
  },
];

export const giaoVienFilter = [
  {
    label: 'Mã giáo viên',
    value: 'giao_vien.ma',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên giáo viên',
    value: 'giao_vien.ten_day_du',
    type: 'string',
  },
  {
    value: 'giao_vien.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'giao_vien.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const nhanVienFilter = [
  {
    value: 'nguoi_dung.ma',
    label: 'Mã nhân viên',
    type: 'string',
    default: true,
  },
  {
    value: 'nguoi_dung.ten_day_du',
    label: 'Tên nhân viên',
    type: 'string',
  },
  {
    label: 'Số điện thoại',
    value: 'nguoi_dung.so_dien_thoai',
    type: 'string',
  },
  {
    label: 'Email',
    value: 'nguoi_dung.email',
    type: 'string',
  },
  {
    label: 'Nhóm quyền',
    value: 'nguoi_dung.loai_nguoi_dung',
    type: 'string',
  },
  {
    label: 'Ngày tạo',
    value: 'nguoi_dung.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày sửa',
    value: 'nguoi_dung.ngay_sua',
    type: 'datetime',
  },
];

export const boMonFilter = [
  {
    value: 'bo_mon.ten_bo_mon',
    label: 'Tên bộ môn',
    type: 'string',
    default: true,
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'bo_mon.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'bo_mon.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const monHocFilter = [
  {
    value: 'mon_hoc.ma_mon_hoc',
    label: 'Mã khoá học',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên khoá học',
    value: 'mon_hoc.ten_mon_hoc',
    type: 'string',
  },
  {
    label: 'Cấp độ',
    value: 'khoi.ten_khoi',
    type: 'string',
  },
  {
    label: 'Bộ môn',
    value: 'bo_mon.ten_bo_mon',
    type: 'string',
  },
  {
    label: 'Điểm tối đa',
    value: 'mon_hoc.diem_toi_da',
    type: 'string',
  },
  {
    label: 'Điểm qua môn',
    value: 'mon_hoc.diem_qua_mon',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'mon_hoc.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'mon_hoc.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const lopHocFilter = [
  {
    value: 'lop_hoc.ma_lop',
    label: 'Mã lớp',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên lớp',
    value: 'lop_hoc.ten_lop',
    type: 'string',
  },
  {
    label: 'Tên cấp độ',
    value: 'khoi.ten_khoi',
    type: 'string',
  },
  {
    label: 'Giáo viên',
    value: 'nguoi_dung_giao_vien.ten_day_du',
    type: 'string',
  },
  {
    label: 'Ngày bắt đầu',
    value: 'lop_hoc.ngay_bat_dau',
    type: 'datetime',
  },
  {
    label: 'Ngày kết thúc',
    value: 'lop_hoc.ngay_ket_thuc',
    type: 'datetime',
  },
  {
    label: 'Số lượng',
    value: 'lop_hoc.so_luong',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    label: 'Ngày tạo',
    value: 'lop_hoc.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày sửa',
    value: 'lop_hoc.ngay_sua',
    type: 'datetime',
  },
];

export const phongHocFilter = [
  {
    value: 'phong_hoc.ma_phong',
    label: 'Mã phòng',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên phòng',
    value: 'phong_hoc.ten_phong',
    type: 'string',
  },
  {
    label: 'Số lượng',
    value: 'phong_hoc.so_luong',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    label: 'Ngày tạo',
    value: 'phong_hoc.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày sửa',
    value: 'phong_hoc.ngay_sua',
    type: 'datetime',
  },
];

export const parentsFilter = [
  {
    label: 'Tên phụ huynh',
    value: 'phu_huynh.ten_day_du',
    type: 'string',
    default: true,
  },
  {
    label: 'Mã người dùng',
    value: 'phu_huynh.ma',
    type: 'string',
  },
  {
    label: 'Số điện thoại',
    value: 'phu_huynh.so_dien_thoai',
    type: 'string',
  },
  {
    label: 'Email',
    value: 'phu_huynh.email',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    label: 'Ngày tạo',
    value: 'phu_huynh.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày sửa',
    value: 'phu_huynh.ngay_sua',
    type: 'datetime',
  },
];

export const studentsFilter = [
  {
    value: 'hoc_vien.ma',
    label: 'Mã học viên',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên học viên',
    value: 'hoc_vien.ten_day_du',
    type: 'string',
  },
  {
    label: 'Số điện thoại',
    value: 'hoc_vien.so_dien_thoai',
    type: 'string',
  },
  {
    label: 'Email',
    value: 'hoc_vien.email',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    label: 'Ngày tạo',
    value: 'hoc_vien.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày sửa',
    value: 'hoc_vien.ngay_sua',
    type: 'datetime',
  },
];

export const thoiKhoaBieuFilter = [
  {
    value: 'ma',
    label: 'Mã sản phẩm',
    type: 'string',
    default: true,
  },
];

export const dangKyGioDayFilter = [
  {
    value: 'nguoi_dung_giao_vien.ma',
    label: 'Mã giáo viên',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên giáo viên',
    value: 'nguoi_dung_giao_vien.ten_day_du',
    type: 'string',
  },
  {
    label: 'Tiết dạy',
    value: 'tiet.ten_tiet_hoc',
    type: 'string',
  },
  {
    label: 'Ngày dạy',
    value: 'thoi_gian_lam_viec.ngay_day',
    type: 'datetime',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    label: 'Ngày tạo',
    value: 'thoi_gian_lam_viec.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày sửa',
    value: 'thoi_gian_lam_viec.ngay_sua',
    type: 'datetime',
  },
];

export const hopDongLaoDongFilter = [
  {
    value: 'hop_dong.so_hop_dong',
    label: 'Số hợp đồng',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên giáo viên',
    value: 'nguoi_dung_giao_vien.ten_day_du',
    type: 'string',
  },
  {
    label: 'Ngày ký',
    value: 'hop_dong.ngay_ky',
    type: 'datetime',
  },
  {
    label: 'Loại hợp đồng',
    value: 'loai_hop_dong.ten_loai',
    type: 'string',
  },
  {
    label: 'Thời hạn',
    value: 'hop_dong.thoi_han',
    type: 'datetime',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    label: 'Ngày tạo',
    value: 'hop_dong.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày sửa',
    value: 'hop_dong.ngay_sua',
    type: 'datetime',
  },
];

export const uuDaiFilter = [
  {
    value: 'uu_dai.ma_uu_dai',
    label: 'Mã ưu đãi',
    type: 'string',
    default: true,
  },
  {
    label: 'Loại ưu đãi',
    value: 'uu_dai.loai',
    type: 'string',
  },
  {
    value: 'uu_dai.thoi_gian_tu',
    label: 'Từ ngày',
    type: 'datetime',
  },
  {
    value: 'uu_dai.thoi_gian_den',
    label: 'Đến ngày',
    type: 'datetime',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'uu_dai.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'uu_dai.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const loaiHopDongFilter = [
  {
    label: 'Tên loại hợp đồng',
    value: 'loai_hop_dong.ten_loai',
    type: 'string',
    default: true,
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'loai_hop_dong.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'loai_hop_dong.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const dienChinhSachFilter = [
  {
    value: 'dien_chinh_sach.ma_dien_chinh_sach',
    label: 'Mã diện chính sách',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên diện chính sách',
    value: 'dien_chinh_sach.ten_dien_chinh_sach',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'dien_chinh_sach.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'dien_chinh_sach.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const loaiDiemFilter = [
  {
    value: 'loai_diem.ten_loai',
    label: 'Tên loại điểm',
    type: 'string',
    default: true,
  },
  {
    label: 'Nhóm loại điểm',
    value: 'loai_diem.nhom_loai_diem',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'loai_diem.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'loai_diem.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const lyDoFilter = [
  {
    label: 'Lý do',
    value: 'ly_do.ly_do',
    type: 'string',
    default: true,
  },
  {
    label: 'Cấp cha',
    value: 'ly_do_cha.ly_do',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'ly_do.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'ly_do.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const donViTinhFilter = [
  {
    value: 'don_vi_tinh.ma',
    label: 'Mã đơn vị',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên đơn vị',
    value: 'don_vi_tinh.ten',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'don_vi_tinh.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'don_vi_tinh.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const thietBiFilter = [
  {
    value: 'thiet_bi.ma_thiet_bi',
    label: 'Mã thiết bị',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên thiết bị',
    value: 'thiet_bi.ten_thiet_bi',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'thiet_bi.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'thiet_bi.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const chungChiFilter = [
  {
    value: 'chung_chi.ma_chung_chi',
    label: 'Mã chứng nhận',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên chứng nhận',
    value: 'chung_chi.ten_chung_chi',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    label: 'Thời hạn',
    value: 'chung_chi.thoi_han',
    type: 'string',
  },
  {
    label: 'Cấp độ',
    value: 'khoi.ten_khoi',
    type: 'string',
  },
  {
    value: 'chung_chi.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'chung_chi.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const nganSachFilter = [
  {
    value: 'ngan_sach.ly_do',
    label: 'Chuyên mục',
    type: 'string',
    default: true,
  },
  {
    label: 'Tiểu mục',
    value: 'ngan_sach.ly_do_con',
    type: 'string',
  },
  {
    label: 'Số tiền',
    value: 'ngan_sach.so_tien',
    type: 'string',
  },
  {
    label: 'Số tiền',
    value: 'ngan_sach.so_tien_con_lai',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'ngan_sach.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'ngan_sach.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const capChungChiFilter = [
  {
    value: 'nguoi_dung.ma',
    label: 'Mã học viên',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên học viên',
    value: 'nguoi_dung.ten',
    type: 'string',
  },
  {
    label: 'Mã lớp',
    value: 'lop_hoc.ma_lop',
    type: 'string',
  },
  {
    label: 'Chứng nhận đạt điều kiện',
    value: 'hoc_vien_dat_chung_chi.ten_chung_chi',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'hoc_vien_dat_chung_chi.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'hoc_vien_dat_chung_chi.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const thuHocPhiFilter = [
  {
    value: 'giao_dich.ma',
    label: 'Mã phiếu thu',
    type: 'string',
    default: true,
  },
  {
    label: 'Ngày thu',
    value: 'giao_dich.ngay_thuc_hien',
    type: 'datetime',
  },
  {
    label: 'Mã học viên',
    value: 'hoc_vien.ma',
    type: 'string',
  },
  {
    label: 'Tên học viên',
    value: 'hoc_vien.ten_day_du',
    type: 'string',
  },
  {
    label: 'Số tiền',
    value: 'giao_dich.so_tien',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'giao_dich.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'giao_dich.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const quanLyDiemFilter = [
  {
    value: 'quan_ly_diem.ma',
    label: 'Mã sản phẩm',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên sản phẩm',
    value: 'quan_ly_diem.ten',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'quan_ly_diem.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'quan_ly_diem.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const nhaCungCapFilter = [
  {
    value: 'nha_cung_cap.ma',
    label: 'Mã',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên',
    value: 'nha_cung_cap.ten',
    type: 'string',
  },
  {
    label: 'Số điện thoại',
    value: 'nha_cung_cap.so_dien_thoai',
    type: 'string',
  },
  {
    label: 'Email',
    value: 'nha_cung_cap.email',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'nha_cung_cap.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'nha_cung_cap.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const muaHangFilter = [
  {
    value: 'mua_hang.ma_phieu_mua_hang',
    label: 'Mã đơn hàng',
    type: 'string',
    default: true,
  },
  {
    label: 'Nhà cung cấp',
    value: 'nha_cung_cap.ten',
    type: 'string',
  },
  {
    value: 'mua_hang.trang_thai_thanh_toan',
    label: 'Trạng thái thanh toán',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'mua_hang.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'mua_hang.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const banHangFilter = [
  {
    value: 'ban_hang.ma_phieu_ban_hang',
    label: 'Mã đơn hàng',
    type: 'string',
    default: true,
  },
  {
    label: 'Khách hàng',
    value: 'khach_hang.ten_day_du',
    type: 'string',
  },
  // {
  //   value: 'ban_hang.trang_thai_thanh_toan',
  //   label: 'Trạng thái thanh toán',
  //   type: 'string',
  // },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'ban_hang.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'ban_hang.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const khoFilter = [
  {
    value: 'kho.ma',
    label: 'Mã sản phẩm',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên sản phẩm',
    value: 'kho.ten',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'kho.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'kho.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const nhapKhoFilter = [
  {
    value: 'nhap_kho.ma_phieu_nhap_kho',
    label: 'Mã phiếu nhập',
    type: 'string',
    default: true,
  },
  {
    label: 'Người giao',
    value: 'nhap_kho.ho_ten_nguoi_giao',
    type: 'string',
  },
  {
    label: 'Người nhận',
    value: 'nhap_kho.ho_ten_nguoi_nhan',
    type: 'string',
  },
  {
    label: 'Số lượng',
    value: 'nhap_kho.tong_so',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'nhap_kho.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'nhap_kho.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const xuatKhoFilter = [
  {
    value: 'xuat_kho.ma_phieu_xuat_kho',
    label: 'Mã phiếu xuất',
    type: 'string',
    default: true,
  },
  {
    label: 'Người giao',
    value: 'xuat_kho.ho_ten_nguoi_giao',
    type: 'string',
  },
  {
    label: 'Người nhận',
    value: 'xuat_kho.ho_ten_nguoi_nhan',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'xuat_kho.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'xuat_kho.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const chuyenKhoFilter = [
  {
    value: 'chuyen_kho.ma_phieu_chuyen_kho',
    label: 'Mã chuyển kho',
    type: 'string',
    default: true,
  },
  {
    label: 'Người nhận',
    value: 'chuyen_kho.ho_ten_nguoi_nhan',
    type: 'string',
  },
  {
    label: 'Người giao',
    value: 'chuyen_kho.ho_ten_nguoi_giao',
    type: 'string',
  },
  {
    label: 'Kho nhận',
    value: 'kho_nhan.ten_kho',
    type: 'string',
  },
  {
    label: 'Kho xuất',
    value: 'kho_xuat.ten_kho',
    type: 'string',
  },
  {
    label: 'Số lượng',
    value: 'chuyen_kho.tong_so',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'chuyen_kho.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'chuyen_kho.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const thuTienFilter = [
  {
    value: 'giao_dich.ma',
    label: 'Mã phiếu thu',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên khách hàng',
    value: 'giao_dich.hoc_vien_ten_day_du',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    label: 'Ngày tạo',
    value: 'giao_dich.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày cập nhật',
    value: 'giao_dich.ngay_sua',
    type: 'datetime',
  },
];

export const chiTienFilter = [
  {
    value: 'giao_dich.ma',
    label: 'Mã phiếu chi',
    type: 'string',
    default: true,
  },
  {
    label: 'Người nhận',
    value: 'nha_cung_cap.ten',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    label: 'Ngày tạo',
    value: 'giao_dich.ngay_tao',
    type: 'datetime',
  },
  {
    label: 'Ngày cập nhật',
    value: 'giao_dich.ngay_sua',
    type: 'datetime',
  },
];

export const tonKhoFilter = [
  {
    value: 'ton_kho.ma',
    label: 'Mã sản phẩm',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên sản phẩm',
    value: 'ton_kho.ten',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'ton_kho.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'ton_kho.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const diemDanhHocVienFilter = [
  {
    value: 'diem_danh_hoc_vien.ma',
    label: 'Mã sản phẩm',
    type: 'string',
    default: true,
  },
  {
    label: 'Tên sản phẩm',
    value: 'diem_danh_hoc_vien.ten',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'diem_danh_hoc_vien.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'diem_danh_hoc_vien.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const tietFilter = [
  {
    value: 'tiet.ten_tiet_hoc',
    label: 'Tên',
    type: 'string',
    default: true,
  },
  {
    label: 'Giờ bắt đầu',
    value: 'tiet.thoi_gian_bat_dau',
    type: 'string',
  },
  {
    label: 'Giờ kết thúc',
    value: 'tiet.thoi_gian_ket_thuc',
    type: 'string',
  },
  {
    value: 'tiet.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'tiet.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const chuongTrinhFilter = [
  {
    label: 'Tên chương trình',
    value: 'chuong_trinh.ten_chuong_trinh',
    type: 'string',
    default: true,
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'chuong_trinh.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'chuong_trinh.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const thuChiKhacFilter = [
  {
    label: 'Mã phiếu',
    value: 'giao_dich.ma',
    type: 'string',
    default: true,
  },
  {
    label: 'Ngày thực hiện',
    value: 'giao_dich.ngay_thuc_hien',
    type: 'datetime',
  },
  {
    label: 'Người nhận',
    value: 'giao_dich.nguoi_nhan',
    type: 'string',
  },
  {
    label: 'Người nộp',
    value: 'giao_dich.nguoi_nop',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'thu_chi_khac.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'thu_chi_khac.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

export const cauHinhXepLoaiFilter = [
  {
    value: 'diem_tu',
    label: 'Điểm từ',
    type: 'string',
    default: true,
  },
  {
    label: 'Điểm đến',
    value: 'diem_den',
    type: 'string',
  },
  {
    label: 'Xếp loại',
    value: 'xep_loai',
    type: 'string',
  },
  {
    label: 'Người tạo',
    value: 'nguoi_dung.ten_day_du',
    type: 'string',
  },
  {
    value: 'cau_hinh_xep_loai.ngay_tao',
    label: 'Ngày tạo',
    type: 'datetime',
  },
  {
    value: 'cau_hinh_xep_loai.ngay_sua',
    label: 'Ngày cập nhật',
    type: 'datetime',
  },
];

/*DECLARE_IMPPORT_CONFIG_HERE*/
