import { getDataSelect2 } from '@app/api/app/api_getDataSelect';
import { API_URL } from '@app/configs/api-configs';
import { selectChinhanh } from '@app/store/slices/appSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BaseForm } from '../forms/BaseForm/BaseForm';
import { BaseSelect } from '../selects/BaseSelect/BaseSelect';
import { SelectProps } from '@app/interfaces/interfaces';

interface IProps extends SelectProps {
  monHocID?: number;
  lopID?: number;
}

const GiaoVienMonHocSelect: React.FC<IProps> = (IProps) => {
  const path = API_URL.GIAO_VIEN_MON_HOC_OPTION;
  const chiNhanhUser = useSelector(selectChinhanh);
  const [options, setoptions] = useState<{ value: string; label: string }[]>([{ value: '', label: '' }]);

  useEffect(() => {
    async function getData() {
      const params = [
        `f[0][field]=chi_nhanh_id&f[0][operator]=equal&f[0][value]=${chiNhanhUser}`,
        IProps.monHocID
          ? `f[1][field]=giao_vien_phu_trach_mon_hoc.mon_hoc_id&f[1][operator]=equal&f[1][value]=${IProps.monHocID}`
          : IProps.initValues
          ? `f[2][field]=giao_vien.id&f[2][operator]=equal&f[2][value]=${IProps.initValues}`
          : ``,
      ]
        .filter(Boolean)
        .join('&');
      const data = await getDataSelect2(path, params);
      let optionsSelect = [{ value: '', label: '' }];
      if (data) {
        optionsSelect = data.map((item: any) => {
          return { value: item.giao_vien_id, label: `${item.giao_vien_ten_day_du}` };
        });
      }
      setoptions(optionsSelect);
    }

    if (IProps.monHocID) getData();
  }, [IProps.initValues, IProps.monHocID, chiNhanhUser, path]);
  return (
    <BaseForm.Item name={IProps.name} label={IProps.label} rules={IProps.rules}>
      <BaseSelect
        allowClear
        options={options}
        placeholder="Chọn giáo viên phụ trách"
        size="small"
        mode={IProps.mode}
        showSearch
        onChange={IProps.onChange}
        disabled={IProps.disabled}
      />
    </BaseForm.Item>
  );
};

export default GiaoVienMonHocSelect;
