import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import DeleteIcon from '@app/assets/icon-components/DeleteIcon';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { toggleReload } from '@app/store/slices/appSlice';
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

type Filter = {
  field: any;
  operator: string;
  value: string | null;
  type?: string;
};

interface IProps {
  option: any;
  setParam: any;
  initialValue: any[];
  defaultParams?: string;
  onShow?: any;
  defaultLength?: number;
}

const optionText = [
  {
    value: 'contain',
    label: 'Nhập tìm kiếm',
  },
];

const optionDateTime = [
  {
    value: 'equal_to',
    label: 'Chọn ngày',
  },
  {
    value: 'less_than',
    label: 'Nhỏ hơn ngày',
  },
  {
    value: 'greater_than',
    label: 'Lớn hơn ngày',
  },
  {
    value: 'less_than_or_equal_to',
    label: 'Nhỏ hơn hoặc bằng ngày',
  },
  {
    value: 'greater_than_or_equal_to',
    label: 'Lớn hơn hoặc bằng ngày',
  },
];

const Filter: React.FC<IProps> = ({ option, setParam, initialValue, defaultParams, onShow, defaultLength = 1 }) => {
  const [form] = BaseForm.useForm();
  const [listFilter, setListFilter] = useState<Filter[]>([]);
  const dispatch = useDispatch();

  const handleClickAddRow = () => {
    setListFilter([...listFilter, { field: '', operator: '', value: '' }]);
  };

  const [optionOperator, setOptionOperator] = useState<any>(optionText);

  const renderFilter = (index: number) => {
    if (listFilter[index]?.type === 'datetime') {
      return <DatePicker format="DD/MM/YYYY" size="small" style={{ width: '100%' }} />;
    } else {
      return <BaseInput />;
    }
  };

  const onFinsh = (values: any) => {
    dispatch(toggleReload('DANH_SACH'));
    onShow && onShow();
    const f: any = {};
    values.filter.forEach((filter: any, i: any) => {
      const number = defaultParams ? i + defaultLength : i;
      f[`f[${number}][field]`] = filter.field;
      f[`f[${number}][operator]`] = filter.operator;
      if (moment.isMoment(values.filter[i].value)) {
        f[`f[${number}][value]`] = moment(new Date(filter.value).toUTCString()).format('YYYY-MM-DD');
      } else {
        f[`f[${number}][value]`] = values.filter[i].value;
      }
    });
    const param = Object.entries(f)
      .map(([key, value]: any) => `${key}=${value}`)
      .join('&');
    setParam(param);
  };

  const onChangeField = (index: number) => (value: any) => {
    const foundItem = option.find((item: any) => item.value === value);
    listFilter[index] = {
      field: foundItem?.value,
      operator: foundItem?.operator,
      value: null,
      type: foundItem?.type,
    };
    form.setFields([
      {
        name: ['filter', index, 'operator'],
        value: listFilter[index]?.type === 'datetime' ? 'equal_to' : 'contain',
      },
      {
        name: ['filter', index, 'value'],
        value: null,
      },
    ]);
    foundItem?.type === 'string' ? setOptionOperator(optionText) : setOptionOperator(optionDateTime);

    setListFilter([...listFilter]);
  };

  return (
    <BaseForm form={form} onFinish={onFinsh} layout="horizontal" style={{ marginTop: '10px' }}>
      <BaseForm.List name="filter" initialValue={initialValue}>
        {(listFilter, { add, remove }) => (
          <BaseRow gutter={[5, 5]}>
            {listFilter.map(({ key, name }, index) => (
              <BaseCol span={20} key={key}>
                <BaseRow gutter={[5, 0]}>
                  <BaseCol span={24} md={7}>
                    <BaseForm.Item name={[name, 'field']}>
                      <BaseSelect options={option} placeholder="Lọc theo trường" onChange={onChangeField(name)} />
                    </BaseForm.Item>
                  </BaseCol>
                  <BaseCol span={22} md={7}>
                    <BaseForm.Item name={[name, 'operator']}>
                      <BaseSelect size="small" options={optionOperator} />
                    </BaseForm.Item>
                  </BaseCol>
                  <BaseCol span={22} md={8}>
                    <BaseForm.Item name={[name, 'value']}>{renderFilter(index)}</BaseForm.Item>
                  </BaseCol>
                  <BaseCol span={2} style={{ paddingTop: '5px' }}>
                    <DeleteIcon onClick={() => remove(name)} />
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            ))}
            <BaseCol span={4}>
              <BaseButton icon={<PlusOutlined />} type="primary" onClick={() => (add(), handleClickAddRow())} />
            </BaseCol>
          </BaseRow>
        )}
      </BaseForm.List>
      <BaseRow>
        <BaseCol span={24}>
          <BaseButton icon={<FilterOutlined />} type="primary" htmlType="submit">
            Lọc
          </BaseButton>
        </BaseCol>
      </BaseRow>
    </BaseForm>
  );
};

export default Filter;
