import { API_BASE_URL } from '@app/configs/api-configs';
import { notificationController } from '@app/controllers/notificationController';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import { apiInstance } from './api_core';

export const getDistrictsList = async (idProvinces: any) => {
  const districtsList: { value: string; label: string }[] = [];
  try {
    const respDistricts: IRespApiSuccess = await apiInstance.get(
      `${API_BASE_URL}/huyen?f[0][field]=tinh_id&f[0][operator]=equal&f[0][value]=${idProvinces}`,
    );
    if (respDistricts.code === 200) {
      respDistricts.data.collection.map((item: any) => {
        districtsList.push({ value: item.id, label: item.ten_huyen });
      });
      return districtsList;
    } else {
      notificationController.error({
        message: respDistricts.message,
      });
    }
  } catch (error: any) {
    notificationController.error({
      message: 'Có lỗi xảy ra vui lòng thử lại sau',
      description: error.message,
    });
  }
};

export const getAreasList = async (idDistricts: string | number) => {
  const areasList: { value: string; label: string }[] = [];
  try {
    const respDistricts: IRespApiSuccess = await apiInstance.get(
      `${API_BASE_URL}/xa?f[0][field]=huyen_id&f[0][operator]=equal&f[0][value]=${idDistricts}`,
    );
    if (respDistricts.code === 200) {
      respDistricts.data.collection.map((item: any) => {
        areasList.push({ value: item.id, label: item.ten });
      });
      return areasList;
    } else {
      notificationController.error({
        message: respDistricts.message,
      });
    }
  } catch (error: any) {
    notificationController.error({
      message: 'Có lỗi xảy ra vui lòng thử lại sau',
      description: error.message,
    });
  }
};
