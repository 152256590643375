import {
  NewPasswordData,
  resetPassword,
  ResetPasswordRequest,
  SecurityCodePayload,
  setNewPassword,
  signUp,
  SignUpRequest,
  verifySecurityCode,
} from '@app/api/auth.api';
import { deleteNienKhoa, deleteToken, persistToken, readToken } from '@app/services/localStorage.service';
import { setUser } from '@app/store/slices/userSlice';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteChiNhanh,
  deletePermission,
  persistPermisstion,
  readPermisstion,
} from './../../services/localStorage.service';

export interface AuthSlice {
  token: string;
  permission: string;
}

const initialState: AuthSlice = {
  token: readToken(),
  permission: readPermisstion(),
};

export const setToken = createAction('user/setToken', (newToken) => {
  persistToken(newToken);

  return {
    payload: newToken,
  };
});

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: any, { dispatch }) => {
  dispatch(setUser(loginPayload));
  setToken(loginPayload.token);
  persistPermisstion(loginPayload.phan_quyen);
  return {
    token: readToken(),
    permission: readPermisstion(),
  };
});

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deletePermission();
  dispatch(setUser(null));
  deleteChiNhanh();
  deleteNienKhoa();
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.permission = action.payload.permission;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
