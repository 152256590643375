export const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const API_URL = {
  LOGIN: '/auth/login',
  RESET: '/auth/reset-pass',
  LOGOUT: '/auth/logout',
  FORGOTPASS: '/auth/forgot-pass',
  DEFAULTPERMISSION: '/roles/default-permisson',
  SANPHAM: '/san-pham',
  SANPHAMHOATDONG: '/san-pham/active',
  NHOMSANPHAM: '/nhom-san-pham',
  PROVINCES: '/provinces',
  DISTRICT: '/district',
  AREA: '/area',
  USERS: '/users',
  ROLES: '/roles',
  CHINHANH: '/chi-nhanh',
  CHINHANH_OPTION: '/chi-nhanh/options',
  NIENKHOA: '/nien-khoa',
  NIENKHOA_OPTION: '/nien-khoa/options',
  NGANSACH: '/ngan-sach',
  KHOI: '/chuong-trinh',
  CHUONG_TRINH_OPTIONS: '/chuong-trinh/options',
  BOMON: '/bo-mon',
  LOPHOC: '/lop-hoc',
  PHONGHOC: '/phong-hoc',
  BO_MON: '/bo-mon',
  MON_HOC: '/mon-hoc',
  CAP_DO: '/cap-do',
  CAP_DO_OPTIONS: '/cap-do/options',
  LOP_HOC: '/lop-hoc',
  PHONG_HOC: '/phong-hoc',
  GIAO_VIEN: '/giao-vien',
  PARENTS: '/phu-huynh',
  STUDENTS: '/hoc-vien',
  STUDENT_PARENT: '/hoc-vien-co-phu-huynh',
  OPTION_PARENTS: '/phu-huynh/options',
  THOI_KHOA_BIEU: '/thoi-khoa-bieu',
  DANG_KY_GIO_DAY: '/dang-ky-gio-day',
  DANG_KY_GIO_DAY_OPTION: '/dang-ky-gio-day/options',
  HOP_DONG_LAO_DONG: '/hop-dong',
  UU_DAI: '/uu-dai',
  LOAI_HOP_DONG: '/loai-hop-dong',
  DIEN_CHINH_SACH: '/dien-chinh-sach',
  LOAI_DIEM: '/loai-diem',
  LY_DO: '/ly-do',
  LY_DO_OPTION: '/ly-do/options',
  DON_VI_TINH: '/don-vi-tinh',
  THIET_BI: '/thiet-bi',
  CHUNG_CHI: '/chung-chi',
  NGAN_SACH: '/ngan-sach',
  CAP_CHUNG_CHI: '/cap-chung-chi',
  THU_HOC_PHI: '/thu-hoc-phi',
  QUAN_LY_DIEM: '/quan-ly-diem',
  GIAO_VIEN_OPTION: '/giao-vien/options',
  NGUOI_DUNG_OPTION: '/users/options',
  TIET: '/tiet',
  TIET_OPTION: '/tiet/options',
  HOC_VIEN_OPTION: '/hoc-vien/options',
  LOP_HOC_OPTION: '/lop-hoc/options',
  MON_HOC_OPTION: '/khoa-hoc/options',
  PHONG_HOC_OPTION: '/phong-hoc/options',
  SAN_PHAM_OPTION: '/san-pham/options',
  SAN_PHAM_TON_KHO: '/san-pham-ton-kho',
  NHA_CUNG_CAP: '/nha-cung-cap',
  DIEM_DANH_HS: '/diem-danh-hoc-vien',
  DIEM_DANH_NHAN_VIEN: '/diem-danh-nhan-vien',
  MUA_HANG: '/mua-hang',
  KHO: '/kho',
  KHOA_HOC: '/khoa-hoc',
  BAN_HANG: '/ban-hang',
  NHAP_KHO: '/nhap-kho',
  XUAT_KHO: '/xuat-kho',
  CHUYEN_KHO: '/chuyen-kho',
  THU_TIEN: '/thu-tien',
  KE_TOAN: '/tai-khoan-ke-toan',
  EXPORT_THU_TIEN: '/thu-tien/export/phieu-thu',
  EXPORT_THU_HOC_PHI: '/thu-hoc-phi/export/phieu-thu',
  CHI_TIEN: '/chi-tien',
  EXPORT_CHI_TIEN: '/chi-tien/export/phieu-chi',
  LOP_HOC_HOC_VIEN: '/lop-hoc/hoc-vien',
  LOP_HOC_HOC_VIEN_OPTION: '/lop-hoc/hoc-vien/options',
  GIAO_VIEN_MON_HOC: '/giao-vien-phu-trach-mon-hoc',
  GIAO_VIEN_MON_HOC_OPTION: '/giao-vien-phu-trach-mon-hoc/options',
  MON_HOC_CO_LOAI_DIEM: '/mon-hoc-co-loai-diem',
  KHOI_OPTION: '/cap-do/options',
  CAU_HINH_CHUNG: '/cau-hinh-chung',
  DOI_MAT_KHAU: '/users/change-password',
  LOAI_DIEM_OPTION: '/loai-diem/options',
  MON_HOC_CO_LOAI_DIEM_OPTION: '/mon-hoc-co-loai-diem/options',
  BC_UU_DAI_HOC_PHI: '/bao-cao-tong-hop-uu-dai-hoc-phi',
  BC_UU_DAI_HOC_PHI_THEO_HV: '/bao-cao-uu-dai-hoc-phi-theo-hoc-vien',
  BC_QUA_TRINH_DAO_TAO_HV: '/bao-cao-chi-tiet-qua-trinh-dao-tao-hoc-vien',
  BC_NHAP_KHO: '/bao-cao-nhap-kho',
  BC_XUAT_KHO: '/bao-cao-xuat-kho',
  BC_MUA_HANG: '/bao-cao-mua-hang',
  BC_BAN_HANG: '/bao-cao-ban-hang',
  BC_CONG_NO_KH: '/bao-cao-cong-no/khach-hang',
  BC_CONG_NO_NCC: '/bao-cao-cong-no/nha-cung-cap',
  BC_TON_KHO: '/bao-cao-tong-hop-xuat-nhap-kho',
  BC_TON_KHO_HANG_HOA: '/bao-cao-tong-hop-xuat-nhap-kho/hang-hoa',
  BC_THU_CHI_CHI_TIET: '/bao-cao-thu-chi/chi-tiet',
  BC_THU_HOC_PHI: '/bao-cao-thu-hoc-phi',
  BC_DIEM_HC_THEO_LOP: '/bao-cao-bang-diem-hoc-vien-theo-lop',
  BC_CHAM_CONG: '/bao-cao-cham-cong',
  BC_DASHBOARD: '/bao-cao/dashboard',
  DIEM_DANH_HOC_VIEN: '/bao-cao-diem-danh-hoc-vien',
  CHUONG_TRINH: '/chuong-trinh',
  THU_CHI_KHAC: '/thu-chi-khac',
  CAU_HINH_XEP_LOAI: '/thang-diem-xep-loai',
  /*new-api-path-here*/
};

export const FILE_EXPORT = {
  UU_DAI: 'import-uu-dai.xlsx',
  THOI_KHOA_BIEU: 'import-thoi-khoa-bieu.xlsx',
  QUAN_LY_DIEM: 'import-quan-ly-diem.xlsx',
  HOC_VIEN_LOP_HOC: 'import-hoc-vien-lop-hoc.xlsx',
  PHU_HUYNH: 'import-phu-huynh.xlsx',
  NGUOI_DUNG_HOC_VIEN: 'import-nguoi-dung-hoc-vien.xlsx',
  NGAN_SACH: 'import_ngan_sach.xlsx',
};
