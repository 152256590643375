import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import UploadFile from '@app/components/customs/upload/UploadFile';
import { useState } from 'react';
import { RcFile, UploadFile as UploadFileInterface } from 'antd/lib/upload';
import { API_BASE_URL, FILE_EXPORT } from '@app/configs/api-configs';
import { notificationController } from '@app/controllers/notificationController';
import { getToken } from '@app/utils/redux.utils';
import { reloadComponent, selectChinhanh } from '@app/store/slices/appSlice';
import { useDispatch, useSelector } from 'react-redux';

const ThoiKhoaBieuImport: React.FC = () => {
  const chiNhanhUser = useSelector(selectChinhanh);
  const fileMau = FILE_EXPORT.THOI_KHOA_BIEU;
  const dispatch = useDispatch();

  const handleUpload = () => {
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append('file', file as RcFile);
    });
    formData.append('chi_nhanh_id', chiNhanhUser.toString());
    setUploading(true);
    fetch(`${API_BASE_URL}/thoi-khoa-bieu/upload`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    })
      .then(async (res) => {
        const data: any = await res.json();
        dispatch(reloadComponent());
        if (data.data) {
          if (data.data.that_bai === 0) {
            notificationController.success({
              message: `Thành công: ${data.data.thanh_cong}, Thất bại: ${data.data.that_bai}`,
            });
          } else {
            notificationController.warning({
              message: `Thành công: ${data.data.thanh_cong}, Thất bại: ${data.data.that_bai}`,
            });
            if (data.data.danh_sach_that_bai.length > 0) {
              data.data.danh_sach_that_bai.forEach((e: any) => {
                notificationController.warning({
                  message: e.message,
                });
              });
            }
          }
        } else {
          notificationController.error({
            message: data.message,
          });
        }
      })
      .catch((error) => {
        notificationController.error({
          message: error.message,
        });
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<UploadFileInterface[]>([]);

  return (
    <UploadFile fileList={fileList} setFileList={setFileList} title="Import" fileMau={fileMau}>
      <BaseButton onClick={handleUpload} loading={uploading} style={{ marginTop: 4 }}>
        {uploading ? 'Đang tải' : 'Tải file lên'}
      </BaseButton>
    </UploadFile>
  );
};

export default ThoiKhoaBieuImport;
