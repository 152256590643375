import { apiInstance } from '@app/api/app/api_core';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { API_BASE_URL, API_URL } from '@app/configs/api-configs';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import { doLogin } from '@app/store/slices/authSlice';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import * as S from './LoginForm.styles';
import imageLogin from '../../../assets/images/image-login.png';
import { useResponsive } from '@app/hooks/useResponsive';
import { useDispatch } from 'react-redux';
import { saveCauHinhChung } from '@app/store/slices/cauHinhChungSlice';
import axios, { AxiosRequestConfig } from 'axios';

interface LoginFormData {
  email: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();
  const dispatch1 = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values: LoginFormData) => {
    setLoading(true);
    try {
      const resp: IRespApiSuccess = await apiInstance.post(`${API_BASE_URL}${API_URL.LOGIN}`, values);
      if (resp.code === 200) {
        notificationController.success({
          message: 'Đăng nhập thành công',
        });
        getCauHinhChung(resp.data.token);
        dispatch(doLogin(resp.data))
          .unwrap()
          .then(() => navigate('/'))
          .catch((err) => {
            notificationController.error({ message: err.message });
          });
      } else {
        notificationController.error({
          message: resp.message,
        });
      }
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
    setLoading(false);
  };

  const getCauHinhChung = async (token: string) => {
    const config: AxiosRequestConfig = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_BASE_URL}${API_URL.CAU_HINH_CHUNG}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const request = await axios.request(config);
      const cauHinhChung: { [key: string]: number } = {};
      request.data.data.collection.map((item: any) => {
        cauHinhChung[item.key] = item.value * 1;
        if (item.key === 'ten_cty') {
          cauHinhChung[item.key] = item.value;
        }
        if (item.key === 'logo_big' || item.key === 'logo_small') {
          cauHinhChung[item.key] = item.value;
        }
      });
      dispatch1(saveCauHinhChung(cauHinhChung));
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
    // try {
    //   const respUsers: IRespApiSuccess = await apiInstance.get(`${API_BASE_URL}${API_URL.CAU_HINH_CHUNG}`);
    //   const cauHinhChung: { [key: string]: number } = {};
    //   respUsers.data.map((item: any) => {
    //     cauHinhChung[item.key] = item.value * 1;
    //   });
    //   dispatch1(saveCauHinhChung(cauHinhChung));
    // } catch (error: any) {
    //   notificationController.error({
    //     message: 'Có lỗi xảy ra vui lòng thử lại sau',
    //     description: error.message,
    //   });
    // }
  };

  return (
    <BaseRow align="middle" justify="space-between" style={{ width: '80%' }}>
      {isDesktop && (
        <BaseCol span={12}>
          <BaseImage src={imageLogin} preview={false} />
        </BaseCol>
      )}
      <BaseCol span={isDesktop ? 12 : 24}>
        <Auth.FormWrapper>
          <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
            <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
            <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
            <Auth.FormItem
              name="tai_khoan"
              label={t('common.username')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInput placeholder={t('common.username')} />
            </Auth.FormItem>
            <Auth.FormItem
              label={t('common.password')}
              name="mat_khau"
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInputPassword placeholder={t('common.password')} />
            </Auth.FormItem>
            <Auth.ActionsWrapper style={{ justifyContent: 'end' }}>
              {/* <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item> */}
              <Link to="/auth/forgot-password">
                <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
              </Link>
            </Auth.ActionsWrapper>
            <BaseForm.Item noStyle>
              <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
                {t('common.login')}
              </Auth.SubmitButton>
            </BaseForm.Item>
            {/* <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <GoogleIcon />
            </Auth.SocialIconWrapper>
            {t('login.googleLink')}
          </Auth.SocialButton>
        </BaseForm.Item> 
        <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <FacebookIcon />
            </Auth.SocialIconWrapper>
            {t('login.facebookLink')}
          </Auth.SocialButton>
        </BaseForm.Item>
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('login.noAccount')}{' '}
            <Link to="/auth/sign-up">
              <Auth.LinkText>{t('common.here')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>*/}
          </BaseForm>
        </Auth.FormWrapper>
      </BaseCol>
    </BaseRow>
  );
};
