// suppress the findDOMNode error until the issue - https://github.com/ant-design/ant-design/issues/26136 - resolved

const consoleError = console.error.bind(console);
console.error = (errObj, ...args) => {
  if (process.env.NODE_ENV === 'development' && typeof errObj === 'string' && args.includes('findDOMNode')) {
    return;
  }
  consoleError(errObj, ...args);
};

export const thoiHanChungChi = [
  {
    value: 0,
    label: 'Không thời hạn',
  },
  {
    value: 6,
    label: '6 Tháng',
  },
  {
    value: 12,
    label: '12 Tháng',
  },
  {
    value: 24,
    label: '24 Tháng',
  },
  {
    value: 36,
    label: '36 Tháng',
  },
];

export const nhomLoaiDiem = [
  { value: 'OT', label: 'Oral test' },
  { value: 'PT', label: 'Paper test' },
];
