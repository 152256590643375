import { getDataSelect2 } from '@app/api/app/api_getDataSelect';
import { API_URL } from '@app/configs/api-configs';
import { SelectProps } from '@app/interfaces/interfaces';
import { selectChinhanh } from '@app/store/slices/appSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BaseForm } from '../forms/BaseForm/BaseForm';
import { BaseSelect } from '../selects/BaseSelect/BaseSelect';
import moment from 'moment';
import { notificationController } from '@app/controllers/notificationController';

interface tietItem extends SelectProps {
  giaoVienID?: number;
  ngay?: any;
}
const TietSelect: React.FC<tietItem> = (IProps) => {
  const chiNhanhUser = useSelector(selectChinhanh);
  const [options, setoptions] = useState<{ value: string; label: string }[]>([{ value: '', label: '' }]);
  let ngay: string = moment().format('YYYY-MM-DD');

  if (IProps.ngay) ngay = moment(IProps.ngay).format('YYYY-MM-DD');

  useEffect(() => {
    async function getData() {
      const pathDangKyDay = API_URL.DANG_KY_GIO_DAY_OPTION;
      const params = [
        `f[0][field]=chi_nhanh_id&f[0][operator]=equal&f[0][value]=${chiNhanhUser}`,
        `f[1][field]=thoi_gian_lam_viec.ngay&f[1][operator]=equal&f[1][value]=${ngay}`,
        `f[2][field]=thoi_gian_lam_viec.giao_vien_id&f[2][operator]=equal&f[2][value]=${IProps.giaoVienID}`,
      ]
        .filter(Boolean)
        .join('&');

      const data = await getDataSelect2(pathDangKyDay, params);
      let optionsSelect = [{ value: '', label: '' }];
      if (data && data.length > 0) {
        optionsSelect = data.map((item: any) => {
          return { value: item.value, label: item.label };
        });
        setoptions(optionsSelect);
        return;
      }
      setoptions(optionsSelect);
      notificationController.warning({
        message: 'Giáo viên chưa đăng ký giờ dạy',
      });
    }

    // getAllData();
    if (IProps.giaoVienID && ngay) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IProps.giaoVienID, IProps.ngay, chiNhanhUser]);

  return (
    <BaseForm.Item name={IProps.name} label={IProps.label} rules={IProps.rules} initialValue={IProps.initValues}>
      <BaseSelect allowClear options={options} placeholder="Chọn Tiết đăng ký" size="small" mode={IProps.mode} />
    </BaseForm.Item>
  );
};

export default TietSelect;
