import { apiInstance } from '@app/api/app/api_core';
import { getDataById } from '@app/api/app/api_getDataById';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { API_BASE_URL } from '@app/configs/api-configs';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import { Button, Form, Popconfirm, Row, Tag } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import ThoiKhoaBieuForm from './ThoiKhoaBieuForm';

interface propCell {
  path: string;
  idCell: number;
  rsData: {
    id: number | null;
    mon_hoc_ten_mon_hoc: string;
    giao_vien_ten_day_du: string;
    ten_lop_hoc: string;
    ten_phong_hoc: string;
  };
  onShow: () => void;
  permission: any;
}

const ThoiKhoaBieuEdit: React.FC<propCell> = ({ path, idCell, rsData, onShow, permission }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const user = useAppSelector((state) => state.user.user);

  const onUpdate = async (values: any) => {
    for (const key in values) {
      if (moment.isMoment(values[key])) {
        values[key] = values[key].format('YYYY-MM-DD');
      }
    }
    try {
      const respUpdate: IRespApiSuccess = await apiInstance.patch(`${API_BASE_URL}${path}/${idCell}`, values);
      if (respUpdate.code === 200) {
        notificationController.success({
          message: 'Cập nhật thành công',
        });
        onShow();
      } else {
        notificationController.error({
          message: respUpdate.message,
        });
      }
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  // xử lý sau khi xoá
  const handleRemove = async () => {
    try {
      const respDelete: IRespApiSuccess = await apiInstance.delete(`${API_BASE_URL}${path}/${idCell}`);
      if (respDelete.code === 200) {
        notificationController.success({
          message: 'Xoá thành công',
        });
        setIsModalOpen(false);
        onShow();
      } else {
        notificationController.error({
          message: respDelete.message,
        });
      }
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    onDataById();
  };

  const onDataById = async () => {
    const fieldsValue = await getDataById(idCell, path);
    if (!fieldsValue) {
      // TODO: Gan loi vao day
      return;
    }

    Object.keys(fieldsValue).forEach((key) => {
      if (fieldsValue[key]) {
        if (/ngay_|_ngay/.test(key) || /ngay/.test(key) || /thoi_gian|_thoi/.test(key)) {
          fieldsValue[key] = moment(fieldsValue[key]);
        }
      }
    });
    console.log('🚀 ~ onDataById ~ fieldsValue:', fieldsValue);
    fieldsValue && form.setFieldsValue(fieldsValue);
    setDataForm(fieldsValue);
  };

  return (
    <UpdateStyles>
      <div onClick={showModal} style={{ textAlign: 'center', minWidth: '200px' }}>
        <div style={{ textAlign: 'center', fontWeight: '700', fontSize: 12 }}>
          <Tag color="#108ee9">{rsData.mon_hoc_ten_mon_hoc}</Tag>
        </div>
        <div style={{ textAlign: 'center', fontWeight: '700', fontSize: 12, color: '#108ee9' }}>
          {rsData.ten_phong_hoc}
        </div>
        {user.loai_nguoi_dung !== 'GV' && <div style={{ fontSize: 13 }}>{rsData.giao_vien_ten_day_du}</div>}
        <div style={{ fontSize: 13, fontWeight: '700', textAlign: 'center', color: '#f50' }}>{rsData.ten_lop_hoc}</div>
      </div>
      <BaseModal
        title="Sửa"
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
        centered
        size={'large'}
        footer={null}
      >
        <Form form={form} onFinish={onUpdate} layout="vertical" disabled={!permission.edit}>
          <ThoiKhoaBieuForm form={form} isEditing={!permission.edit} dataForm={dataForm} />
          <Row gutter={[10, 10]} justify="end" style={{ marginTop: 6 }}>
            {permission.delete && (
              <Popconfirm title="Bạn có muốn xoá không?" okText="Có" cancelText="Không" onConfirm={handleRemove}>
                <Button size="small" type="primary" danger style={{ marginRight: 6 }}>
                  Xoá
                </Button>
              </Popconfirm>
            )}
            {permission.edit && (
              <Button size="small" type="primary" htmlType="submit">
                Lưu
              </Button>
            )}
          </Row>
        </Form>
      </BaseModal>
    </UpdateStyles>
  );
};

const UpdateStyles = styled.div`
  .ant-modal-title {
    text-align: left;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 28px;
  }
`;

export default ThoiKhoaBieuEdit;
