import { DatePicker, Form, List, Radio, RadioChangeEvent, Space, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash'; // Import Lodash

import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseList } from '@app/components/common/BaseList/BaseList';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import KhoiSelect from '@app/components/common/common/KhoiSelect';
import LopSelect from '@app/components/common/common/LopSelect';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

import { apiInstance } from '@app/api/app/api_core';
import { getDataSelect2 } from '@app/api/app/api_getDataSelect';
import { API_BASE_URL, API_URL } from '@app/configs/api-configs';
import { notificationController } from '@app/controllers/notificationController';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import { selectChinhanh } from '@app/store/slices/appSlice';
import { getRoleUser } from '@app/utils/redux.utils';

import DataContextProvider from '@app/contexts/DataContext';
import styled from 'styled-components';
import ThoiKhoaBieuCalendar from './ThoiKhoaBieuCalendar';
import ThoiKhoaBieuEdit from './ThoiKhoaBieuEdit';
import ThoiKhoaBieuImport from './ThoiKhoaBieuImport';
import ThoiKhoaBieuCreate from './ThoiKhoaBieuCreate';

const ThoiKhoaBieu: React.FC = () => {
  const { t } = useTranslation();
  const path = API_URL.THOI_KHOA_BIEU;
  const page = 'common.thoi_khoa_bieu';
  const [form] = Form.useForm();
  const userPermission = JSON.parse(getRoleUser());
  const permission = userPermission?.filter((item: any) => item.name === path.replace(/\//g, ''))[0].actions;
  const [showData, setShowData] = useState<Item[]>([]);
  const [tietData, setTietData] = useState<any[]>([]);
  const [fromDate, setFromDate] = useState<any>(moment());
  const [khoiID, setKhoiID] = useState();
  const [viewType, setViewType] = useState('tuan');

  const chiNhanhUser = useSelector(selectChinhanh);

  interface Item {
    id: number;
    tiet_id: number;
    ngay: string;
    tiet_ten_tiet_hoc: string;
    giao_vien_ten_day_du: string;
    mon_hoc_ten_mon_hoc: string;
    wd: string;
  }

  const handleData = useCallback((vData: Item[], vDataTiet: any[]) => {
    const dataResult = vData.map((item: any) => {
      const wd = `t${moment(item.ngay).isoWeek()}_${moment(item.ngay).isoWeekday()}`;
      return { wd, ...item };
    });
    // Biến đổi dữ liệu thành cấu trúc mới
    const groupedData = _.groupBy(dataResult, (item) => `${item.tiet_id}-${item.wd}`);

    // Biến đổi dữ liệu thành cấu trúc mới
    //[tiet_id: ..., tuan_ngay: [dữ liệu tkb], ]
    const rsTKB = _.map(groupedData, (group, key) => {
      const [tiet_id, wd] = key.split('-');
      const groupData = group.map((item) => _.omit(item, 'tiet_id', 'wd'));

      return {
        tiet_id: parseInt(tiet_id), // Chuyển đổi 'tiet_id' sang kiểu số nếu cần
        [wd]: groupData,
      };
    });

    //Map với dữ liệu tiết

    const rsData = vDataTiet.map((item) => {
      const existingItems = _.filter(rsTKB, { tiet_id: item.id });
      const newItem = _.assign({}, item, ...existingItems);
      return newItem;
    });

    return rsData;
  }, []);

  const dataTable = handleData(showData, tietData);

  const renderColumns = useCallback((vDay: any) => {
    if (!vDay) vDay = moment();
    const vDate = new Date(vDay);
    const rs: any[] = [];
    const wNumber = moment(vDate).isoWeek();
    const sWeek = moment(vDate).startOf('isoWeek');
    let i = 1; //1: t2 ... 7:CN
    while (i < 8) {
      const data = {
        // dataIndex: `t${wNumber}_${i}`,
        title: i === 7 ? 'CN' : `T${i + 1}`,
        key: `t${wNumber}_${i}`,

        children: [
          {
            title: `${sWeek.format('DD-MM-YYYY')}`,
            dataIndex: `t${wNumber}_${i}`,
            key: `t${wNumber}_${i}`,
            width: 200,
            ellipsis: 'true',
            render: (data: any) => renderCell(data),
          },
        ],
      };

      rs.push(data);
      sWeek.add(1, 'days');
      i++;
    }

    return rs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCell = useCallback((data: any[]) => {
    if (!data) return <Space></Space>;

    return (
      <BaseList
        // bordered
        size="small"
        dataSource={data}
        renderItem={(item: any) => (
          <List.Item>
            <ThoiKhoaBieuEdit
              onShow={getSchedule}
              path={path}
              idCell={item.id}
              key={item.id}
              rsData={item}
              permission={permission}
            />
          </List.Item>
        )}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnByDate = useMemo(() => renderColumns(fromDate), [fromDate, renderColumns]);

  const column = useMemo(
    () => [
      {
        title: 'Tiết',
        dataIndex: 'ten_tiet_hoc',
        key: 'ten_tiet_hoc',
        align: 'center',
        width: 150,
        ellipsis: 'true',

        render: (_text: any, record: any) => {
          return (
            <>
              <div style={{ fontWeight: '700' }}>{record.ten_tiet_hoc}</div>
              <div>
                ({record.thoi_gian_bat_dau.slice(0, 5)}-{record.thoi_gian_ket_thuc.slice(0, 5)})
              </div>
            </>
          );
        },
      },
      ...columnByDate,
    ],
    [columnByDate],
  );

  const getTiet = useCallback(async () => {
    const pathTiet = API_URL.TIET_OPTION;
    const params = `f[0][field]=chi_nhanh_id&f[0][operator]=equal&f[0][value]=${chiNhanhUser}`;
    // const params = `f[0][field]=chi_nhanh_id&f[0][operator]=equal&f[0][value]=4&page=1&limit=0&sort_direction=desc&sort_column=ngay_tao`;
    const data = await getDataSelect2(pathTiet, params);

    setTietData(data);

    return data;
  }, [chiNhanhUser]);

  const getSchedule = useCallback(async () => {
    const khoi_id = form.getFieldValue('khoi_id');
    const ngay = form.getFieldValue('ngay');
    const lop_id = form.getFieldValue('lop_id');
    let tu_ngay, den_ngay;

    if (ngay) {
      const ngayMoment = moment(ngay);
      // xem theo tuan
      if (viewType === 'tuan') {
        tu_ngay = ngayMoment.startOf('isoWeek').format('YYYY-MM-DD');
        den_ngay = ngayMoment.endOf('isoWeek').format('YYYY-MM-DD');
      }
      if (viewType === 'thang') {
        //xem theo thang
        tu_ngay = ngayMoment.startOf('month').format('YYYY-MM-DD');
        den_ngay = ngayMoment.endOf('month').format('YYYY-MM-DD');
      }
    }
    let params = [
      `f[0][field]=thoi_khoa_bieu.ngay&f[0][operator]=between&f[0][value]=["${tu_ngay}","${den_ngay}"]`,
      khoi_id ? `f[1][field]=thoi_khoa_bieu.khoi_id&f[1][operator]=equal&f[1][value]=${khoi_id}` : '',
      lop_id ? `f[2][field]=thoi_khoa_bieu.lop_id&f[2][operator]=equal&f[2][value]=${lop_id}` : '',
      `f[3][field]=thoi_khoa_bieu.chi_nhanh_id&f[3][operator]=equal&f[3][value]=${chiNhanhUser}`,
    ]
      .filter(Boolean)
      .join('&');

    params += '&sort_column=tiet_id&sort_direction=asc';

    try {
      const result: IRespApiSuccess = await apiInstance.get(`${API_BASE_URL}${path}`, { params });
      if (result.code === 200) {
        setShowData(result.data.collection);
      }
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
  }, [chiNhanhUser, form, path, viewType]);

  useEffect(() => {
    getSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewType, chiNhanhUser]);

  function onChangeView(e: RadioChangeEvent) {
    setViewType(e.target.value);
  }

  useEffect(() => {
    getTiet();
  }, [getTiet]);

  return (
    <DataContextProvider>
      <PageTitle>{t(`${page.toLowerCase()}`)}</PageTitle>
      <BaseRow gutter={[10, 12]}>
        <BaseCol span={24}>
          <BaseCard padding="0.5rem">
            <BaseRow justify="space-between" align="middle">
              <BaseCol>
                <Typography.Title level={3}>{t(`${page}`)}</Typography.Title>
              </BaseCol>
            </BaseRow>
            <BaseRow justify="space-between">
              <BaseCol xs={24} md={18} style={{ marginBottom: '6px' }}>
                <Form form={form} onFinish={getSchedule} layout="vertical">
                  <BaseRow gutter={[12, 0]}>
                    <BaseCol xs={24} md={4}>
                      <KhoiSelect
                        name="khoi_id"
                        // rules={[{ required: true, message: 'Cấp độ không được bỏ trống!' }]}
                        onChange={(e: any) => setKhoiID(e)}
                      ></KhoiSelect>
                    </BaseCol>
                    <BaseCol xs={24} md={4}>
                      <LopSelect name="lop_id" khoiID={khoiID}></LopSelect>
                    </BaseCol>
                    <BaseCol xs={24} md={4}>
                      <BaseForm.Item name="ngay" initialValue={fromDate}>
                        <DatePicker
                          size="small"
                          placeholder="Chọn từ ngày"
                          format="DD/MM/YYYY"
                          onChange={(val: any) => {
                            setFromDate(val === null ? moment() : moment(val));
                          }}
                          style={{ width: '100%' }}
                        />
                      </BaseForm.Item>
                    </BaseCol>

                    <BaseCol xs={24} md={4}>
                      <BaseButton
                        size="small"
                        className="button-create"
                        type="primary"
                        htmlType="submit"
                        style={{ paddingLeft: '10px' }}
                      >
                        Xem thời khoá biểu
                      </BaseButton>
                    </BaseCol>
                  </BaseRow>
                </Form>
              </BaseCol>
              <BaseCol xs={24} md={6}>
                <BaseRow gutter={[12, 6]}>
                  <BaseCol>
                    {permission.create && (
                      // <Create onShow={getSchedule} path={`${path}/bulk`} sizeForm="large">
                      //   <ThoiKhoaBieuForms />
                      // </Create>
                      <ThoiKhoaBieuCreate onShow={getSchedule} path={`${path}/bulk`} permission={permission} />
                    )}
                  </BaseCol>
                  <BaseCol>{permission.create && <ThoiKhoaBieuImport />}</BaseCol>
                </BaseRow>
              </BaseCol>
            </BaseRow>
          </BaseCard>
        </BaseCol>
        <BaseCol span={24}>
          <BaseCard padding="0.8rem">
            <BaseCol xs={24} md={4}>
              <Radio.Group onChange={onChangeView} value={viewType}>
                <Radio value={'tuan'}>Tuần</Radio>
                <Radio value={'thang'}>Tháng</Radio>
              </Radio.Group>
            </BaseCol>
            {viewType === 'tuan' && (
              <BaseCol>
                {permission.index && (
                  <StyleDataTable
                    columns={column}
                    dataSource={dataTable}
                    pagination={false}
                    scroll={{ x: 1024, y: 450 }}
                    rowKey="ten_tiet_hoc"
                  />
                )}
              </BaseCol>
            )}
            {viewType === 'thang' && (
              <BaseCol>
                {permission.index && <ThoiKhoaBieuCalendar dataCalendar={showData} dateData={fromDate} />}
              </BaseCol>
            )}
          </BaseCard>
        </BaseCol>
      </BaseRow>
    </DataContextProvider>
  );
};

export const StyleDataTable = styled(BaseTable)`
  .ant-table-tbody > ant-table-cell {
    white-space: normal;
  }

  .ant-table-thead > tr > th {
    white-space: normal;
    text-align: center;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: inherit;
  }
  .ant-list-item {
    padding: 0;
  }
  .ant-list-item:hover {
    background-color: aliceblue;
  }
`;

export default ThoiKhoaBieu;
