import { RootState } from './../store';
import { createSlice } from '@reduxjs/toolkit';

interface detailSlice {
  id: number;
}

const initialState: detailSlice = {
  id: 0,
};

const detailSlice = createSlice({
  name: 'detail',
  initialState,
  reducers: {
    saveDetailId: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const selectDetailId = (state: RootState) => state.detail.id;
export const { saveDetailId } = detailSlice.actions;
export default detailSlice.reducer;
