import { WithChildrenProps } from '@app/types/generalTypes';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export const PageTitle: React.FC<WithChildrenProps> = ({ children }) => {
  const cauHinhChungString = localStorage.getItem('cauHinhChung');
  const cauHinhChung = cauHinhChungString ? JSON.parse(cauHinhChungString) : null;

  return (
    <Helmet>
      <title>
        {children} | {`${cauHinhChung?.ten_cty || null}`}
      </title>
    </Helmet>
  );
};
