import { API_BASE_URL, API_URL } from '@app/configs/api-configs';
import { notificationController } from '@app/controllers/notificationController';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import { apiInstance } from './api_core';

export const getDataSelect = async (path: string) => {
  try {
    const respSelect: IRespApiSuccess = await apiInstance.get(`${API_BASE_URL}${path}`);
    if (respSelect.code === 200) {
      return respSelect.data.collection;
    } else {
      notificationController.error({
        message: 'Không thể lấy danh sách chọn',
        description: respSelect.message,
      });
    }
  } catch (error: any) {
    notificationController.error({
      message: 'Không thể lấy danh sách chọn',
      description: error.message,
    });
  }
};

export const getDataSelect2 = async (path: string, params: string | null) => {
  try {
    const respSelect: IRespApiSuccess = await apiInstance.get(`${API_BASE_URL}${path}`, {
      params,
    });

    if (respSelect.code === 200) {
      return respSelect.data.collection;
    } else {
      notificationController.error({
        message: 'Không thể lấy danh sách chọn',
        description: respSelect.message,
      });
    }
  } catch (error: any) {
    notificationController.error({
      message: 'Không thể lấy danh sách chọn',
      description: error.message,
    });
  }
};

export const getParentOptions = async (params: string) => {
  const paramDefault = `f[0][field]=chi_nhanh_id&f[0][operator]=equal&f[0][value]=1&page=1`;
  const path = API_URL.OPTION_PARENTS;
  const options: { value: string; label: string }[] = [];
  try {
    const result: any = await getDataSelect2(path, params ? params : paramDefault);
    result.map((item: any) => {
      options.push({ value: item.value, label: item.label });
    });

    return options;
  } catch (error: any) {
    notificationController.error({
      message: 'Có lỗi xảy ra vui lòng thử lại sau',
      description: error.message,
    });
  }
};
