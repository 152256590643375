/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from 'react';

export const DataContext = createContext<any | undefined>(undefined);

const DataContextProvider = (props: any) => {
  const [data, setData] = useState<any>([]);

  return <DataContext.Provider value={{ data, setData }}>{props.children}</DataContext.Provider>;
};

export default DataContextProvider;
