import { RootState } from './../store';
import { createSlice } from '@reduxjs/toolkit';

interface detailSlice {
  cauhinh: any;
}

const initialState: detailSlice = {
  cauhinh: {},
};

const cauHinhChungSlice = createSlice({
  name: 'cauHinhChung',
  initialState,
  reducers: {
    saveCauHinhChung: (state, action) => {
      state.cauhinh = action.payload;
      localStorage.setItem('cauHinhChung', JSON.stringify(state.cauhinh));
    },
  },
});

export const selectCauHinhChung = (state: RootState) => state.cauHinhChung.cauhinh;
export const { saveCauHinhChung } = cauHinhChungSlice.actions;
export default cauHinhChungSlice.reducer;
