import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface SecurityCodeFormProps {
  onBack?: () => void;
  onFinish?: () => void;
}

export const SecurityCodeForm: React.FC<SecurityCodeFormProps> = ({ onBack }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();

  const navigateBack = useCallback(() => navigate(-1), [navigate]);
  // const navigateForward = useCallback(() => navigate('/auth/new-password'), [navigate]);

  // const [securityCode, setSecurityCode] = useState('');
  // const [isLoading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (securityCode.length === 6) {
  //     setLoading(true);
  //     dispatch(doVerifySecurityCode({ code: securityCode }))
  //       .unwrap()
  //       .then(onFinish || navigateForward)
  //       .catch((err) => {
  //         notificationController.error({ message: err.message });
  //         setLoading(false);
  //       });
  //   }
  // }, [securityCode, navigateForward, onFinish, dispatch]);

  return (
    <BaseRow justify="start">
      <BaseCol>
        <Auth.FormWrapper style={{ marginLeft: 0 }}>
          <BaseForm layout="vertical" requiredMark="optional">
            <Auth.BackWrapper onClick={onBack || navigateBack}>
              <Auth.BackIcon />
              {t('common.back')}
            </Auth.BackWrapper>
            <BaseTypography.Title level={4}>Vui lòng kiểm tra hòm thư Email của bạn</BaseTypography.Title>
            {/* <S.ContentWrapper>
          <S.ImageWrapper>
            <BaseImage src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>{t('securityCodeForm.title')}</Auth.FormTitle>
          <S.VerifyEmailDescription>{t('common.verifCodeSent')}</S.VerifyEmailDescription>
          {isLoading ? <BaseSpin /> : <VerificationCodeInput autoFocus onChange={setSecurityCode} />}
          <Link to="/" target="_blank">
            <S.NoCodeText>{t('securityCodeForm.noCode')}</S.NoCodeText>
          </Link>
        </S.ContentWrapper> */}
          </BaseForm>
        </Auth.FormWrapper>
      </BaseCol>
    </BaseRow>
  );
};
