import { useAppSelector } from '@app/hooks/reduxHooks';
import { Calendar, Tag } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';
import 'moment/locale/vi';
import React from 'react';
import styled from 'styled-components';

import locale from 'antd/es/date-picker/locale/vi_VN';

interface Iprop {
  dataCalendar: any;
  dateData: Moment;
}

const ThoiKhoaBieuCalendar: React.FC<Iprop> = ({ dataCalendar }) => {
  const user = useAppSelector((state) => state.user.user);

  const dateCellRender = (dateData: Moment) => {
    console.log(dateData);
    const listData = dataCalendar.filter((value: any) => {
      console.log('value', value);
      return moment(value.ngay).format('YYYY-MM-DD') === dateData.format('YYYY-MM-DD');
    });
    console.log(listData);
    return (
      <Event>
        {listData.map((item: any) => (
          <li key={item.id}>
            <div style={{ textAlign: 'center', fontWeight: '700', fontSize: 12 }}>
              <Tag color="#108ee9">{item.mon_hoc_ten_mon_hoc}</Tag>
            </div>
            <div style={{ textAlign: 'center', fontWeight: '700', fontSize: 12, color: '#108ee9' }}>
              {item.tiet_ten_tiet_hoc}
            </div>
            <div style={{ textAlign: 'center', fontWeight: '700', fontSize: 12, color: '#3bb273' }}>
              {item.ten_phong_hoc}
            </div>
            {user.loai_nguoi_dung !== 'GV' && (
              <div style={{ fontSize: 12, textAlign: 'center' }}>{item.giao_vien_ten_day_du}</div>
            )}
            <div style={{ fontSize: 12, fontWeight: '700', textAlign: 'center', color: '#f50' }}>
              {item.ten_lop_hoc}
            </div>
          </li>
        ))}
      </Event>
    );
  };

  return <StyleCalendar dateCellRender={dateCellRender} mode="month" locale={locale} />;
};

const Event = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #7768ae;
  }
`;

const StyleCalendar = styled(Calendar)`
  .ant-radio-group {
    display: none;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 28px;
  }
  .ant-picker-cell-in-view .ant-picker-calendar-date-value {
    color: var(--primary-color);
  }
  .ant-picker-body {
    overflow: auto scroll;
  }
  .ant-picker-content {
    min-width: 1200px;
  }
`;

export default ThoiKhoaBieuCalendar;
