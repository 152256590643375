import { apiInstance } from '@app/api/app/api_core';
import { getDataSelect2 } from '@app/api/app/api_getDataSelect';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseList } from '@app/components/common/BaseList/BaseList';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { API_BASE_URL, API_URL } from '@app/configs/api-configs';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import { selectChinhanh } from '@app/store/slices/appSlice';
import { Space } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { StyleDataTable } from '../HocTap/ThoiKhoaBieu/ThoiKhoaBieu';

interface Item {
  id: number;
  tiet_id: number;
  ngay: string;
  tiet_ten_tiet_hoc: string;
  giao_vien_ten_day_du: string;
  mon_hoc_ten_mon_hoc: string;
  wd: string;
}

const Customer = () => {
  const chiNhanh = useSelector(selectChinhanh);
  const user = useAppSelector((state) => state.user.user);
  const [tietData, setTietData] = useState<any[]>([]);
  const [showData, setShowData] = useState<Item[]>([]);
  const [data, setData] = useState<any>();
  const chiNhanhUser = useSelector(selectChinhanh);
  const path = API_URL.THOI_KHOA_BIEU;
  const fromDate = moment();

  const getTiet = useCallback(async () => {
    const pathTiet = API_URL.TIET_OPTION;
    const params = `f[0][field]=chi_nhanh_id&f[0][operator]=equal&f[0][value]=${chiNhanhUser}`;
    const data = await getDataSelect2(pathTiet, params);

    setTietData(data);

    return data;
  }, [chiNhanhUser]);

  const getSchedule = useCallback(async () => {
    const ngay = moment();
    let tu_ngay, den_ngay;

    if (ngay) {
      const ngayMoment = moment(ngay);
      tu_ngay = ngayMoment.startOf('isoWeek').format('YYYY-MM-DD');
      den_ngay = ngayMoment.endOf('isoWeek').format('YYYY-MM-DD');
    }
    let params = [`f[0][field]=thoi_khoa_bieu.ngay&f[0][operator]=between&f[0][value]=["${tu_ngay}","${den_ngay}"]`]
      .filter(Boolean)
      .join('&');

    params += '&sort_column=tiet_id&sort_direction=asc';

    try {
      const result: IRespApiSuccess = await apiInstance.get(`${API_BASE_URL}${path}`, { params });
      if (result.code === 200) {
        setShowData(result.data.collection);
      }
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
  }, [path]);

  const renderCell = useCallback((data: any[]) => {
    if (!data) return <Space></Space>;

    return (
      <BaseList
        size="small"
        dataSource={data}
        renderItem={(item: any) => (
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontWeight: '700' }}>{item.mon_hoc_ten_mon_hoc}</div>
            <div>{item.ten_phong_hoc}</div>
            <div style={{ fontSize: 14 }}>{item.giao_vien_ten_day_du}</div>
            <div style={{ fontSize: 14 }}>{item.ten_lop_hoc}</div>
          </div>
        )}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleData = useCallback((vData: Item[], vDataTiet: any[]) => {
    const dataResult = vData.map((item: any) => {
      const wd = `t${moment(item.ngay).isoWeek()}_${moment(item.ngay).isoWeekday()}`;
      return { ...item, wd };
    });

    const rsTKB = dataResult.reduce((result: any[], item) => {
      const { tiet_id, wd, id, giao_vien_ten_day_du, mon_hoc_ten_mon_hoc, ten_lop_hoc, ten_phong_hoc } = item;
      const existingItem = result.find((x: { tiet_id: number }) => x.tiet_id === tiet_id);

      if (existingItem && existingItem[wd]) {
        existingItem[wd].push({ id, mon_hoc_ten_mon_hoc, giao_vien_ten_day_du, ten_lop_hoc, ten_phong_hoc });
      } else {
        const newItem: any = {
          tiet_id,
          [wd]: [{ id, mon_hoc_ten_mon_hoc, giao_vien_ten_day_du, ten_lop_hoc, ten_phong_hoc }],
        };
        result.push(newItem);
      }
      return result;
    }, []);

    // map vao du lieu tiet hoc
    const rsData = vDataTiet.map((item) => {
      const existingItem = rsTKB.find((x) => x.tiet_id === item.id);
      if (existingItem) {
        const newItem = { ...item, ...existingItem };
        return newItem;
      }
      return item;
    });
    return rsData;
  }, []);

  const dataTable = handleData(showData, tietData);

  const renderColumns = useCallback((vDay: any) => {
    if (!vDay) vDay = moment();
    const vDate = new Date(vDay);
    const rs: any[] = [];
    const wNumber = moment(vDate).isoWeek();
    const sWeek = moment(vDate).startOf('isoWeek');
    let i = 1; //1: t2 ... 7:CN
    while (i < 8) {
      const data = {
        dataIndex: `t${wNumber}_${i}`,
        title: i === 7 ? 'CN' : `T${i + 1}`,
        key: `t${wNumber}_${i}`,
        children: [
          {
            title: `${sWeek.format('DD-MM-YYYY')}`,
            dataIndex: `t${wNumber}_${i}`,
            key: `t${wNumber}_${i}`,
            width: 200,
            render: (data: any) => renderCell(data),
          },
        ],
      };

      rs.push(data);
      sWeek.add(1, 'days');
      i++;
    }

    return rs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnByDate = useMemo(() => renderColumns(fromDate), [renderColumns, fromDate]);

  const column = useMemo(
    () => [
      {
        title: 'Tiết',
        dataIndex: 'ten_tiet_hoc',
        key: 'ten_tiet_hoc',
        align: 'center',
        render: (_text: any, _record: any) => (
          <div>
            <p style={{ fontWeight: '700' }}>{_record.ten_tiet_hoc}</p>
            <p>
              ({_record.thoi_gian_bat_dau}-{_record.thoi_gian_ket_thuc})
            </p>
          </div>
        ),
      },
      ...columnByDate,
    ],
    [columnByDate],
  );

  useEffect(() => {
    getTiet();
    getSchedule();
  }, [getSchedule, getTiet]);

  const optionsDoughnut: any = {
    responsive: true,
    plugins: {
      datalabels: {
        formatter: (value: any, ctx: any) => {
          let sum = 0;
          const dataArr = ctx.chart.data.datasets[0].data as number[];
          dataArr.forEach((data) => {
            sum += data * 1;
          });
          const percentage = ((value * 100) / sum).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
        font: {
          weight: 'bold',
        },
      },
    },
  };

  const getData = async () => {
    const params = `chi_nhanh_id=${chiNhanh}&loai_nguoi_dung=HV`;
    const results: IRespApiSuccess = await apiInstance.get(`${API_BASE_URL}${API_URL.BC_DASHBOARD}`, {
      params: params,
    });
    if (results?.code === 200) {
      setData(results.data);
    }
  };

  const dataPieChart = {
    labels: ['Có mặt', 'Vắng'],
    datasets: [
      {
        label: 'Số lượng',
        data: [data?.rp_diem_danh.present, data?.rp_diem_danh.absent],
        backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)'],
        hoverOffset: 4,
        borderWidth: 4,
      },
    ],
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseRow gutter={[14, 10]}>
        <BaseCol span={10} md={14} className="user_info">
          <BaseCard padding="0.5rem">
            <BaseTypography.Title level={3}>Thông tin cá nhân</BaseTypography.Title>
            <BaseRow>
              <BaseCol span={8} className="title">
                <div>Tên</div>
                <div>Email</div>
                <div>Số điện thoại</div>
                <div>Ngày sinh</div>
                <div>Địa chỉ</div>
                <div>Ngày vào</div>
                <div>Phân loại</div>
                <div>Giới tính</div>
              </BaseCol>
              <BaseCol span={16} className="content">
                <div>{user.ten_day_du}</div>
                <div>{user.email}</div>
                <div>{user.so_dien_thoai}</div>
                <div>{moment(user.ngay_sinh).format('DD/MM/YYYY')}</div>
                <div>{user.dia_chi}</div>
                <div>{moment(user.ngay_vao).format('DD/MM/YYYY')}</div>
                <div>{user.ten_vai_tro}</div>
                <div>{user.gioi_tinh === 1 ? 'Nam' : 'Nữ'}</div>
              </BaseCol>
            </BaseRow>
          </BaseCard>
        </BaseCol>
        <BaseCol span={24} md={10} xl={8}>
          <BaseCard padding="0.5rem">
            <BaseTypography.Title level={3}>Điểm danh</BaseTypography.Title>
            <Doughnut data={dataPieChart} options={optionsDoughnut} style={{ maxHeight: '300px' }} />
          </BaseCard>
        </BaseCol>
      </BaseRow>
      <BaseRow style={{ marginTop: '10px' }}>
        <BaseCol span={24}>
          <BaseCard padding="0.5rem">
            <BaseTypography.Title level={3}>Thời khoá biểu</BaseTypography.Title>
            <StyleDataTable
              columns={column}
              dataSource={dataTable}
              pagination={false}
              scroll={{ x: 800 }}
              rowKey="ten_tiet_hoc"
            />
          </BaseCard>
        </BaseCol>
      </BaseRow>
    </>
  );
};

export default Customer;
