import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from '@app/hooks/useResponsive';
import React from 'react';
import * as S from './MainSider/MainSider.styles';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
  logo: string;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider, logo }) => {
  const { mobileOnly } = useResponsive();
  // const img = theme === 'dark' ? logoDark : logo;
  // const theme = useAppSelector((state) => state.theme.theme);

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/">
        <img src={logo} alt="Logo" height={70} />
        {/* <S.BrandSpan>Lightence</S.BrandSpan> */}
      </S.SiderLogoLink>
      {!mobileOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
