import { BaseFormProps } from '@app/components/common/forms/BaseForm/BaseForm';
import { Rule } from 'antd/lib/form';
import { NumericLiteral } from 'typescript';

export type Dimension = number | string;

export type ChartData = number[];

export type xData = number[] | string[];

// export type LanguageType = 'de' | 'en';
export type LanguageType = 'vi' | 'en';

export type ThemeType = 'light' | 'dark';

export interface ChartSeries {
  seriesName: string;
  value: number;
  data: {
    day: number;
    value: NumericLiteral;
  };
  name: string;
}

export interface IFilter {
  page: number;
  limit: number;
  sort_column: string;
  sort_direction: string;
}

export interface IRespApiSuccess {
  code: number;
  status: boolean;
  message: string;
  data: any;
}

export interface IActions {
  [key: string]: boolean;
}

export interface IMyObject {
  name: string;
  actions: IActions;
}

export type ChartSeriesData = ChartSeries[];

export type Severity = 'success' | 'error' | 'info' | 'warning';

export type TwoFactorAuthOption = 'email' | 'phone';

export type ActivityStatusType = 'sold' | 'booked' | 'added';

export enum CurrencyTypeEnum {
  USD = 'USD',
  ETH = 'ETH',
  BTC = 'BTC',
}

export interface PaymentCard {
  cvc: string;
  expiry: string;
  name: string;
  number: string;
  // eslint-disable-next-line
  focused: any;
  background: string;
  isEdit: boolean;
}

export interface SelectProps extends BaseFormProps {
  name?: string;
  label?: string;
  rules?: Rule[] | undefined;
  mode?: 'multiple' | 'tags' | undefined;
  initValues?: any;
  hidden?: boolean;
  onChange?: (value: any) => void;
  isEditing?: boolean;
}
