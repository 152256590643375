import { RootState } from './../store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  dataTable: [],
  chiNhanh: 0,
  nienKhoa: 0,
  reload: false,
  reloadData: {
    default: false,
  } as { [key: string]: boolean },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    reloadComponent: (state) => {
      state.reload = !state.reload;
    },
    toggleReload: (state, action: PayloadAction<string>) => {
      const isOpenModal = state.reloadData[action.payload];
      if (typeof isOpenModal === 'undefined' || isOpenModal === null) {
        state.reloadData[action.payload] = true;
      } else {
        state.reloadData[action.payload] = !isOpenModal;
      }
    },
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    getDataTable: (state, action) => {
      state.dataTable = action.payload;
    },
    saveChiNhanh: (state, action) => {
      state.chiNhanh = action.payload;
      localStorage.setItem('chiNhanh', JSON.stringify(state.chiNhanh));
    },
    saveNienKhoa: (state, action) => {
      state.nienKhoa = action.payload;
      localStorage.setItem('nienKhoa', JSON.stringify(state.nienKhoa));
    },
  },
});

export const selectChinhanh = (state: RootState) => state.app.chiNhanh;
export const selectNienKhoa = (state: RootState) => state.app.nienKhoa;
export const { saveChiNhanh, saveNienKhoa, reloadComponent, toggleReload } = appSlice.actions;
const appState = (state: RootState) => state.app;
export const appSelector = {
  reloadData: (name: string) => createSelector(appState, (app) => app.reloadData[name]),
};
export default appSlice.reducer;
