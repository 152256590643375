import { useEffect, useState } from 'react';
import { API_URL } from '@app/configs/api-configs';
import { selectChinhanh } from '@app/store/slices/appSlice';
import { useSelector } from 'react-redux';
import { BaseSelect } from '../selects/BaseSelect/BaseSelect';
import { getDataSelect } from '@app/api/app/api_getDataSelect';
import { BaseForm } from '../forms/BaseForm/BaseForm';
import { SelectProps } from '@app/interfaces/interfaces';

const ChiNhanhSelect: React.FC<SelectProps> = (IProps) => {
  const path = API_URL.CHINHANH_OPTION;
  const chiNhanhUser = useSelector(selectChinhanh);
  const [options, setoptions] = useState<{ value: string; label: string }[]>([{ value: '', label: '' }]);

  useEffect(() => {
    async function getData() {
      const data = await getDataSelect(path);
      const optionsSelect = data.map((item: any) => {
        return { value: item.value, label: item.label };
      });
      setoptions(optionsSelect);
    }
    getData();
  }, [path]);
  return (
    <BaseForm.Item name={IProps.name} label={IProps.label} rules={IProps.rules} initialValue={chiNhanhUser} hidden>
      <BaseSelect allowClear options={options} placeholder="Chọn chi nhánh" size="small" mode={IProps.mode} />
    </BaseForm.Item>
  );
};

export default ChiNhanhSelect;
