import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import Admin from './Admin';
import './styles.css';
import { useAppSelector } from '@app/hooks/reduxHooks';
import Customer from './Customer';

const BangDieuKhien = () => {
  const { t } = useTranslation();
  const page = 'common.bang-dieu-khien';

  const loaiNguoiDung = useAppSelector((state) => state.user.user).loai_nguoi_dung;

  const renderDashBoard = () => {
    if (loaiNguoiDung === 'ADMIN' || loaiNguoiDung === 'NV') {
      return <Admin />;
    } else {
      return <Customer />;
    }
  };

  return (
    <>
      <PageTitle>{t(`${page.toLowerCase()}`)}</PageTitle>
      {renderDashBoard()}
    </>
  );
};

export default BangDieuKhien;
