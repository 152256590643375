import { apiInstance } from '@app/api/app/api_core';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { API_BASE_URL, API_URL } from '@app/configs/api-configs';
import { notificationController } from '@app/controllers/notificationController';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as S from './ForgotPasswordForm.styles';
import imageLogin from '../../../assets/images/image-login.png';
import { useResponsive } from '@app/hooks/useResponsive';

interface ForgotPasswordFormData {
  email: string;
}

export const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values: ForgotPasswordFormData) => {
    setLoading(true);
    try {
      const resp: IRespApiSuccess = await apiInstance.post(`${API_BASE_URL}${API_URL.FORGOTPASS}`, values);
      if (resp.code === 200) {
        navigate('/auth/security-code');
      } else {
        notificationController.error({
          message: resp.message,
        });
      }
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
    // dispatch(doResetPassword(values))
    //   .unwrap()
    //   .then(() => {
    //     navigate('/auth/security-code');
    //   })
    //   .catch((err) => {
    //     notificationController.error({ message: err.message });
    //     setLoading(false);
    //   });
  };

  return (
    <BaseRow align="middle" justify="space-between" style={{ width: '80%' }}>
      {isDesktop && (
        <BaseCol span={12}>
          <BaseImage src={imageLogin} preview={false} />
        </BaseCol>
      )}
      <BaseCol span={isDesktop ? 12 : 24}>
        <Auth.FormWrapper>
          <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
            <Auth.BackWrapper onClick={() => navigate(-1)}>
              <Auth.BackIcon />
              {t('common.back')}
            </Auth.BackWrapper>
            <Auth.FormTitle>{t('common.resetPassword')}</Auth.FormTitle>
            <S.Description>{t('forgotPassword.description')}</S.Description>
            <Auth.FormItem
              name="email"
              label={t('common.email')}
              rules={[{ required: true, message: t('common.emailError') }]}
            >
              <Auth.FormInput placeholder={t('common.email')} />
            </Auth.FormItem>
            <BaseForm.Item noStyle>
              <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
                {t('forgotPassword.sendInstructions')}
              </S.SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </Auth.FormWrapper>
      </BaseCol>
    </BaseRow>
  );
};
