import { ConfigProvider } from 'antd';
// import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import viVN from 'antd/lib/locale/vi_VN';
import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import 'typeface-montserrat';
import 'typeface-roboto';
import 'typeface-inter';
import { AppRouter } from './components/router/AppRouter';
import { useAppSelector } from './hooks/reduxHooks';
import { useLanguage } from './hooks/useLanguage';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import GlobalStyle from './styles/GlobalStyle';
import { themeObject } from './styles/themes/themeVariables';
import { API_BASE_URL } from './configs/api-configs';

const App: React.FC = () => {
  const cauHinhChungString = localStorage.getItem('cauHinhChung');
  const cauHinhChung = cauHinhChungString ? JSON.parse(cauHinhChungString) : null;
  useEffect(() => {
    const changeFavicon = () => {
      const link: any = document.querySelector("link[rel~='icon']");
      if (link) {
        console.log(link.href);
        link.href = `${API_BASE_URL}${cauHinhChung?.logo_small?.replace('public', '')}`;
      }
    };

    changeFavicon();

    return () => {
      // Đặt lại favicon về mặc định nếu cần thiết
      const link: any = document.querySelector("link[rel~='icon']");
      if (link) {
        link.href = '/s.png';
      }
    };
  }, [cauHinhChung, cauHinhChung?.logo_small]);

  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  // usePWA();

  // useAutoNightMode();

  useThemeWatcher();

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={language === 'en' ? enUS : viVN}>
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

export default App;
