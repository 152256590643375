import { useEffect, useState } from 'react';
import { API_URL } from '@app/configs/api-configs';
import { selectChinhanh } from '@app/store/slices/appSlice';
import { useSelector } from 'react-redux';
import { BaseForm } from '../forms/BaseForm/BaseForm';
import { BaseSelect } from '../selects/BaseSelect/BaseSelect';
import { getDataSelect2 } from '@app/api/app/api_getDataSelect';
import { SelectProps } from '@app/interfaces/interfaces';

interface lydoProps extends SelectProps {
  loai?: number;
  cap_cha?: boolean;
}

const TietOption: React.FC<lydoProps> = (IProps) => {
  const path = API_URL.TIET_OPTION;
  const chiNhanhUser = useSelector(selectChinhanh);
  const [options, setoptions] = useState<{ value: string; label: string }[]>([{ value: '', label: '' }]);
  const params = `f[0][field]=chi_nhanh_id&f[0][operator]=equal&f[0][value]=${chiNhanhUser}&page=1&limit=0&sort_direction=desc&sort_column=ngay_tao`;

  useEffect(() => {
    async function getData() {
      const data = await getDataSelect2(path, params);
      const optionsSelect = data.map((item: any) => {
        return { value: item.value, label: item.label };
      });
      setoptions(optionsSelect);
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chiNhanhUser]);

  return (
    <BaseForm.Item name={IProps.name} label={IProps.label} rules={IProps.rules} initialValue={IProps.initialValues}>
      <BaseSelect allowClear options={options} placeholder="Chọn" size="small" mode={IProps.mode} showSearch />
    </BaseForm.Item>
  );
};

export default TietOption;
