import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { API_BASE_URL, API_URL } from '@app/configs/api-configs';
import { Form } from 'antd';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { getDataById } from '@app/api/app/api_getDataById';
import moment from 'moment';
import { notificationController } from '@app/controllers/notificationController';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import { apiInstance } from '@app/api/app/api_core';
import NhanVienForm from './NhanVienForm';

interface IProps {
  isEditing?: boolean;
  idRecord: number;
  onShow: () => void;
  path: string;
}

const NhanVienUpdate: React.FC<IProps> = (IProps) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [huyen, setHuyen] = useState<any>(0);
  const [xa, setXa] = useState<any>(0);
  const [tinh, setTinh] = useState<any>(0);

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const showModal = () => {
    setIsModalOpen(true);
    getDataUserByID();
  };

  const getDataUserByID = useCallback(async () => {
    const pathUser = API_URL.USERS;
    const fieldsValue = await getDataById(IProps.idRecord, pathUser);
    if (fieldsValue) {
      const { nguoiDung } = fieldsValue;
      // Fix cac truong ngay_thang
      Object.keys(nguoiDung).forEach((key) => {
        if (nguoiDung[key]) {
          if (/ngay_|_ngay/.test(key)) {
            nguoiDung[key] = moment(nguoiDung[key]);
          }
        }
      });

      nguoiDung && form.setFieldsValue(nguoiDung);

      setTinh(nguoiDung.tinh_id);

      if (nguoiDung.huyen_id) {
        setHuyen(nguoiDung.huyen_id);
      }

      if (nguoiDung.xa_id) {
        setXa(nguoiDung.xa_id);
      }
    }

    return fieldsValue;
  }, [IProps.idRecord, form]);

  const onUpdate = async (values: any) => {
    for (const key in values) {
      if (moment.isMoment(values[key])) {
        values[key] = values[key].format('YYYY-MM-DD');
      }
    }
    console.log(values);
    try {
      const respUpdate: IRespApiSuccess = await apiInstance.patch(
        `${API_BASE_URL}${IProps.path}/${IProps.idRecord}`,
        values,
      );
      if (respUpdate.code === 200) {
        notificationController.success({
          message: 'Cập nhật thành công',
        });
        IProps.onShow();
        handleCancel();
      } else {
        notificationController.error({
          message: respUpdate.message,
        });
      }
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
  };
  return (
    <>
      <EditOutlined onClick={showModal} style={{ fontSize: '20px', cursor: 'pointer' }} title="sửa" />
      <BaseModal
        title="Sửa"
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
        centered
        width={1000}
        footer={null}
      >
        <BaseRow gutter={[12, 0]}>
          <LopHocChiTietStyles>
            <BaseForm form={form} onFinish={onUpdate}>
              <NhanVienForm isEditing huyenID={huyen} xaID={xa} tinhID={tinh} />
              <BaseRow gutter={[10, 10]} justify="end">
                <BaseButton size="small" type="primary" htmlType="submit">
                  Lưu
                </BaseButton>
              </BaseRow>
            </BaseForm>
          </LopHocChiTietStyles>
        </BaseRow>
      </BaseModal>
    </>
  );
};

const LopHocChiTietStyles = styled.div`
  .ant-input-disabled,
  .ant-input-number-disabled,
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker.ant-picker-disabled,
  .ant-picker-input > input[disabled],
  .ant-input[disabled] {
    background: #ffffff;
    border-radius: 0px;
    border: 0px;
    color: #000;
    cursor: text;
  }
  .table-form {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input,
    .ant-input-number-input,
    .ant-input-number {
      border-radius: 0px;
    }
    .ant-form-item {
      margin-bottom: 0px;
    }
    .ant-input-disabled,
    .ant-input-number-disabled,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-picker.ant-picker-disabled,
    .ant-picker-input > input[disabled],
    .ant-input[disabled] {
      background: #f9f9f9;
      border: 1px solid var(--border-base-color);
      color: #000;
      cursor: text;
    }
  }
  .hidden-arrow-down {
    .ant-select-arrow {
      display: none;
    }
  }
  .border-1 {
    border: 1px solid var(--border-base-color);
    text-align: center;
  }
  .ant-select-disabled .ant-select-arrow,
  .ant-picker.ant-picker-disabled .ant-picker-suffix {
    display: none;
  }
`;

export default NhanVienUpdate;
