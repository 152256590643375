import { getDataSelect } from '@app/api/app/api_getDataSelect';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { saveChiNhanh } from '@app/store/slices/appSlice';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import * as S from '../Header.styles';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { notificationController } from '@app/controllers/notificationController';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import { saveCauHinhChung } from '@app/store/slices/cauHinhChungSlice';
import { apiInstance } from '@app/api/app/api_core';
import { API_BASE_URL, API_URL } from '@app/configs/api-configs';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  const [chinhanh, setChinhanh] = useState<{ value: string; label: string }[]>([{ value: '', label: '' }]);
  const [nienkhoa, setNienkhoa] = useState<{ value: string; label: string }[]>([{ value: '', label: '' }]);
  const chinhanh_id = useAppSelector((state) => state.user.user.chi_nhanh_id) || '';
  const loaiNguoiDung = useAppSelector((state) => state.user.user.loai_nguoi_dung);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const cauHinhChungString = localStorage.getItem('cauHinhChung');
  const cauHinhChung = cauHinhChungString ? JSON.parse(cauHinhChungString) : null;
  const savedChinhanh = Number(localStorage.getItem('chiNhanh')) || chinhanh_id;
  const savedNienkhoa = cauHinhChung?.nien_khoa_mac_dinh || null;

  useEffect(() => {
    async function getChinhanh() {
      const chinhanh = await getDataSelect('/chi-nhanh/options');
      const optionsSelect = chinhanh?.map((item: any) => {
        return { value: item.id, label: item.ten_chi_nhanh };
      });
      setChinhanh(optionsSelect);
    }
    async function getNienKhoa() {
      const nienkhoa = await getDataSelect('/nien-khoa/options');
      const optionsSelect = nienkhoa?.map((item: any) => {
        return { value: item.id, label: item.ten_nien_khoa };
      });
      setNienkhoa(optionsSelect);
    }
    if (cauHinhChung?.nien_khoa_mac_dinh === null) {
      notificationController.error({
        message: 'Vui lòng chọn niên khoá',
      });
    }
    getNienKhoa();
    getChinhanh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdate = async (values: any) => {
    try {
      const respUsers: IRespApiSuccess = await apiInstance.patch(`${API_BASE_URL}${API_URL.CAU_HINH_CHUNG}`, values);
      if (respUsers.code === 200) {
        const cauHinhChung: { [key: string]: number } = {};
        respUsers.data.map((item: any) => {
          cauHinhChung[item.key] = item.value * 1;
        });
        dispatch(saveCauHinhChung(cauHinhChung));
      } else {
        notificationController.error({
          message: respUsers.message,
          description: respUsers.data,
        });
      }
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({ chinhanh: savedChinhanh || chinhanh_id, nienkhoa: savedNienkhoa });
    if (savedChinhanh) {
      dispatch(saveChiNhanh(savedChinhanh));
    }
  }, [chinhanh_id, dispatch, form, savedChinhanh, savedNienkhoa]);

  const onChangeChiNhanh = (value: any) => {
    dispatch(saveChiNhanh(value));
  };

  const onChangeNienKhoa = (value: any) => {
    // dispatch(saveNienKhoa(value));
    onUpdate({ ...cauHinhChung, nien_khoa_mac_dinh: value });
  };
  return (
    <BaseRow justify="space-between" align="middle">
      <BaseCol>
        <ProfileDropdown />
      </BaseCol>
      <BaseCol span={20}>
        <BaseRow>
          {/* <BaseCol>
            <NotificationsDropdown />
          </BaseCol> */}
          <BaseCol style={{ paddingLeft: '10px' }} span={20}>
            <BaseForm form={form} layout="vertical" style={{ width: '100%' }}>
              <BaseRow gutter={[10, 10]}>
                <BaseCol span={11}>
                  <BaseForm.Item name="nienkhoa">
                    <BaseSelect size="small" onChange={onChangeNienKhoa} options={nienkhoa} placeholder="Niên khoá" />
                  </BaseForm.Item>
                </BaseCol>
                {loaiNguoiDung.toLowerCase() === 'ADMIN'.toLowerCase() && (
                  <BaseCol span={13}>
                    <BaseForm.Item name="chinhanh">
                      <BaseSelect
                        size="small"
                        onChange={onChangeChiNhanh}
                        options={chinhanh}
                        placeholder="Chi nhánh"
                        disabled={loaiNguoiDung.toLowerCase() === 'ADMIN'.toLowerCase() ? false : true}
                      />
                    </BaseForm.Item>
                  </BaseCol>
                )}
              </BaseRow>
            </BaseForm>
          </BaseCol>
          {/* <BaseCol>
            <HeaderSearch />
          </BaseCol> */}
          {/* <BaseCol>
            <SettingsDropdown />
          </BaseCol> */}
        </BaseRow>
      </BaseCol>
      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
      </S.BurgerCol>
    </BaseRow>
  );
};
