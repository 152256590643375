import { roleCheckRoute } from '@app/utils/check';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { SidebarNavigationItem, sidebarNavigation } from '../sidebarNavigation';
import * as S from './SiderMenu.styles';

interface SiderContentProps {
  toggleSider: () => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SiderMenu: React.FC<SiderContentProps> = ({ toggleSider }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  // const openedSubmenu = sidebarNavigation.find(({ children }) =>
  //   children?.some(({ url }) => url === location.pathname),
  // );
  // const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      // defaultOpenKeys={defaultOpenKeys}
      // onClick={toggleSider}
      items={roleCheckRoute(sidebarNavigation).map((nav: SidebarNavigationItem) => {
        const isSubMenu = nav.children?.length;
        return {
          key: nav.key,
          title: t(nav.title),
          label: isSubMenu ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
          icon: nav.icon,
          children:
            isSubMenu &&
            nav.children &&
            nav.children.map((childNav: any) => {
              return {
                key: childNav.key,
                label: <Link to={childNav.url || ''}>{t(childNav.title)}</Link>,
                title: t(childNav.title),
              };
            }),
        };
      })}
    />
  );
};

export default SiderMenu;
