import { apiInstance } from '@app/api/app/api_core';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import Create from '@app/components/customs/crud/Create';
import Delete from '@app/components/customs/crud/Delete';
import CustomPagination from '@app/components/customs/CustomPagination';
import Filter from '@app/components/customs/filter/Filter';
import { API_BASE_URL, API_URL } from '@app/configs/api-configs';
import { nhanVienColumn } from '@app/configs/columns-configs';
import { nhanVienFilter } from '@app/configs/filter-configs';
import DataContextProvider from '@app/contexts/DataContext';
import { notificationController } from '@app/controllers/notificationController';
import { IFilter, IRespApiSuccess } from '@app/interfaces/interfaces';
import { selectChinhanh } from '@app/store/slices/appSlice';
import { getRoleUser } from '@app/utils/redux.utils';
import { Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NhanVienForm from './NhanVienForm';
import NhanVienUpdate from './NhanVienUpdate';

const NhanVien: React.FC = () => {
  const { t } = useTranslation();
  const path = API_URL.USERS;
  const page = 'common.NHAN_VIEN';
  const [param, setParam] = useState('');
  const chiNhanh = useSelector(selectChinhanh);

  const defaultParams =
    'f[0][field]=nguoi_dung.loai_nguoi_dung&f[0][operator]=includes&f[0][value]=["ADMIN","NV"]' +
    `&f[1][field]=nguoi_dung.chi_nhanh_id&f[1][operator]=equal&f[1][value]=${chiNhanh}`;

  const userPermission = JSON.parse(getRoleUser());
  const permission = userPermission?.filter((item: any) => item.name === path.replace(/\//g, ''))[0].actions;
  const defaultFilter = nhanVienFilter
    .filter((f) => f.default)
    .map((f) => ({ field: f.value, operator: 'contain', value: '' }));

  const [filter, setFilter] = useState<IFilter>({
    page: 1,
    limit: 20,
    sort_direction: 'desc',
    sort_column: 'ngay_tao',
  });

  const [showData, setShowData] = useState([]);
  const [totalPage, setTotalPage] = useState<any>();

  const f = Object.entries(filter)
    .map(([key, value]: any) => `${key}=${value}`)
    .join('&');

  const onShow = async () => {
    const params = param ? `${defaultParams}&${param}&${f}` : `${defaultParams}&${f}`;
    try {
      const respUsers: IRespApiSuccess = await apiInstance.get(`${API_BASE_URL}${path}`, {
        params: params,
      });
      if (respUsers.code === 200) {
        setTotalPage(respUsers.data.total);
        setShowData(respUsers.data.collection);
      }
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
  };

  const handlePageChange = (page: number) => {
    setFilter({ ...filter, page: page });
  };

  const column: any = [
    {
      title: 'STT',
      dataIndex: 'index',
      align: 'right',
      render: (_text: any, _record: any, index: any) => {
        return (filter.page - 1) * filter.limit + index + 1;
      },
    },
    {
      title: 'Thao tác',
      align: 'center',
      render: (record: any) => {
        return (
          <Space>
            {permission.delete && <Delete id={record.id} onShow={onShow} path={path} />}
            {permission.edit && <NhanVienUpdate onShow={onShow} idRecord={record.id} path={path}></NhanVienUpdate>}
          </Space>
        );
      },
    },
  ];
  column.push(...nhanVienColumn);

  useEffect(() => {
    onShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param, filter.page, chiNhanh]);

  return (
    <DataContextProvider>
      <PageTitle>{t(`${page.toLowerCase()}`)}</PageTitle>
      <BaseRow gutter={[10, 12]}>
        <BaseCol span={24}>
          <BaseCard padding="0.5rem">
            <BaseRow justify="space-between" align="middle">
              <BaseCol>
                <Typography.Title level={3}>{t(`${page.toLowerCase()}`)}</Typography.Title>
              </BaseCol>
              <BaseCol>
                {permission.create && (
                  <Create path={path} sizeForm="large" onShow={onShow}>
                    <NhanVienForm isEditing={false} />
                  </Create>
                )}
              </BaseCol>
            </BaseRow>
            <BaseRow>
              <BaseCol span={16}>
                <Filter
                  initialValue={defaultFilter}
                  defaultParams={defaultParams}
                  option={nhanVienFilter}
                  setParam={setParam}
                />
              </BaseCol>
            </BaseRow>
          </BaseCard>
        </BaseCol>
        <BaseCol span={24}>
          <BaseCard padding="0.5rem">
            <BaseTable dataSource={showData} pagination={false} scroll={{ x: 800 }} rowKey="ma" columns={column} />
            <CustomPagination
              totalItems={totalPage}
              itemsPerPage={filter.limit}
              currentPage={filter.page}
              onPageChange={handlePageChange}
            />
          </BaseCard>
        </BaseCol>
      </BaseRow>
    </DataContextProvider>
  );
};

export default NhanVien;
