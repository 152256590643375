import { apiInstance } from '@app/api/app/api_core';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { API_BASE_URL } from '@app/configs/api-configs';
import { notificationController } from '@app/controllers/notificationController';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import { toggleReload } from '@app/store/slices/appSlice';
import { Form } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

interface IProps {
  children?: React.ReactNode;
  path: string;
  sizeForm?: 'small' | 'medium' | 'large';
  onShow?: () => void;
}

const Create: React.FC<IProps> = ({ children, path, sizeForm, onShow }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const showModal = () => {
    dispatch(toggleReload('SELECT'));
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onCreate = async (values: any) => {
    for (const key in values) {
      if (moment.isMoment(values[key])) {
        if (path === '/tiet') {
          values[key] = values[key].format('HH:mm:ss');
        } else {
          values[key] = values[key].format('YYYY-MM-DD');
        }
      }
    }
    try {
      const respUsers: IRespApiSuccess = await apiInstance.post(`${API_BASE_URL}${path}`, values);
      if (respUsers.code === 200) {
        notificationController.success({
          message: 'Tạo thành công',
        });
        onShow ? onShow() : dispatch(toggleReload('DANH_SACH'));
        handleCancel();
      } else {
        notificationController.error({
          message: respUsers.message,
          description: respUsers.data,
        });
      }
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
  };

  return (
    <>
      <BaseButton onClick={showModal} size="small" className="button-create" type="primary">
        Thêm
      </BaseButton>
      <BaseModal
        title="Thêm"
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
        centered
        size={sizeForm}
        footer={null}
      >
        <Form form={form} onFinish={onCreate} layout="vertical">
          {children}
          <BaseRow gutter={[10, 10]} justify="end">
            <BaseButton size="small" type="primary" htmlType="submit">
              Lưu
            </BaseButton>
          </BaseRow>
        </Form>
      </BaseModal>
    </>
  );
};

export default Create;
