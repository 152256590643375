import styled from 'styled-components';
import { Modal as AntModal } from 'antd';

export const Modal = styled(AntModal)`
  .ant-modal-header,
  .ant-modal-body {
    border: none;
    padding: 12px 24px;
  }
  .ant-modal-title {
    color: var(--primary-color);
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 28px;
  }
  .ant-modal-close {
    right: 4px;
  }
`;
