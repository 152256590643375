import { apiInstance } from '@app/api/app/api_core';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { API_BASE_URL, API_URL } from '@app/configs/api-configs';
import { notificationController } from '@app/controllers/notificationController';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as S from './NewPasswordForm.styles';

interface NewPasswordFormData {
  password: string;
  confirmPassword: string;
}
export const NewPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const url = window.location.href;

  // Parse các tham số từ URL
  const parsed = queryString.parseUrl(url);
  const token = parsed.query.token;
  const email = parsed.query.email;

  // const handleSubmit = (values: NewPasswordFormData) => {
  //   setLoading(true);
  //   dispatch(doSetNewPassword({ newPassword: values.password }))
  //     .unwrap()
  //     .then(() => {
  //       navigate('/auth/login');
  //       notificationController.success({
  //         message: t('newPassword.successMessage'),
  //         description: t('newPassword.successDescription'),
  //       });
  //     })
  //     .catch((err) => {
  //       notificationController.error({ message: err.message });
  //       setLoading(false);
  //     });
  // };

  const handleSubmit = async (values: NewPasswordFormData) => {
    setLoading(true);
    try {
      const resp: IRespApiSuccess = await apiInstance.post(`${API_BASE_URL}${API_URL.RESET}`, {
        email: email,
        token_resset_pass: token,
        mat_khau_moi: values.password,
      });
      if (resp.code === 200) {
        notificationController.success({
          message: 'Tạo thành công mật khẩu mới',
        });
        navigate('/auth/login');
      } else {
        notificationController.error({
          message: resp.message,
        });
      }
    } catch (error: any) {
      notificationController.error({
        message: 'Có lỗi xảy ra vui lòng thử lại sau',
        description: error.message,
      });
    }
    setLoading(false);
  };

  return (
    <Auth.FormWrapper style={{ marginLeft: 0 }}>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('newPassword.title')}</Auth.FormTitle>
        <Auth.FormItem
          name="password"
          label={t('common.password')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="confirmPassword"
          label={t('common.confirmPassword')}
          dependencies={['password']}
          rules={[
            { required: true, message: t('common.requiredField') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
          hasFeedback
        >
          <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.resetPassword')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
