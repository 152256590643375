import { getDataSelect2 } from '@app/api/app/api_getDataSelect';
import { API_URL } from '@app/configs/api-configs';
import { SelectProps } from '@app/interfaces/interfaces';
import { selectChinhanh } from '@app/store/slices/appSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BaseForm } from '../forms/BaseForm/BaseForm';
import { BaseSelect } from '../selects/BaseSelect/BaseSelect';

const PhongSelect: React.FC<SelectProps> = (IProps) => {
  const path = API_URL.PHONG_HOC_OPTION;
  const chiNhanhUser = useSelector(selectChinhanh);
  const [options, setoptions] = useState<{ value: string; label: string }[]>([{ value: '', label: '' }]);

  useEffect(() => {
    async function getData() {
      const params = `f[0][field]=chi_nhanh_id&f[0][operator]=equal&f[0][value]=${chiNhanhUser}`;
      const data = await getDataSelect2(path, params);
      const optionsSelect = data.map((item: any) => {
        return { value: item.value, label: item.label };
      });
      setoptions(optionsSelect);
    }

    getData();
  }, [chiNhanhUser, path]);

  return (
    <BaseForm.Item name={IProps.name} label={IProps.label} rules={IProps.rules}>
      <BaseSelect
        allowClear
        options={options}
        placeholder="Chọn phòng học"
        size="small"
        mode={IProps.mode}
        showSearch
      />
    </BaseForm.Item>
  );
};

export default PhongSelect;
