import { DollarOutlined, UserOutlined } from '@ant-design/icons';
import { apiInstance } from '@app/api/app/api_core';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { API_BASE_URL, API_URL } from '@app/configs/api-configs';
import { IRespApiSuccess } from '@app/interfaces/interfaces';
import { selectChinhanh } from '@app/store/slices/appSlice';
import { ConvertTextUser } from '@app/utils/converts';
import { formatter } from '@app/utils/utils';
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect, useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

export interface Data {
  rp_so_luong_nguoi_dung: RpSoLuongNguoiDung[];
  rp_thu_chi_hang_thang: RpThuChiHangThang[];
  rp_thu_chi_hang_tuan: RpThuChiHangTuan[];
  rp_gioi_tinh_hoc_vien: RpGioiTinhHocVien[];
  rp_het_han_hop_dong: RpHetHanHopDong[];
  rp_so_luong_hoc_vien_dang_hoc: number;
  rp_trang_thai_lop_hoc: RpTrangThaiLopHoc[];
}

export interface RpTrangThaiLopHoc {
  trang_thai_str: string;
  so_luong: string;
}

export interface RpSoLuongNguoiDung {
  loai_nguoi_dung: string;
  so_luong: string;
}

export interface RpThuChiHangThang {
  thang_str: string;
  thang: string;
  tong_tien_thu: string;
  tong_tien_thu_hoc_phi: string;
  tong_tien_chi: string;
}
export interface RpThuChiHangTuan {
  tuan: string;
  tong_tien_thu: string;
  tong_tien_thu_hoc_phi: string;
  tong_tien_chi: string;
}

export interface RpGioiTinhHocVien {
  gioi_tinh: number;
  gioi_tinh_str: string;
  so_luong: string;
}

export interface RpHetHanHopDong {
  giao_vien_ten_day_du: string;
  ngay_ky: Date;
  thoi_han: Date;
  ten_loai_hop_dong: string;
  ngay_con_lai: number;
}

const DataCard = ({ bgColor, icon, title, data }: { bgColor: string; icon: JSX.Element; title: string; data: any }) => (
  <BaseCol xs={12} xl={6}>
    <BaseCard padding="0.5rem">
      <BaseRow gutter={[10, 10]}>
        <BaseCol span={10}>
          <div className="bg-icon" style={{ backgroundColor: bgColor }}>
            {icon}
          </div>
        </BaseCol>
        <BaseCol span={14}>
          <div className="text">{title}</div>
          <div className="text-data">{data}</div>
        </BaseCol>
      </BaseRow>
    </BaseCard>
  </BaseCol>
);

const Admin = () => {
  const chiNhanh = useSelector(selectChinhanh);
  const [data, setData] = useState<Data>();
  const [soTien, setSoTien] = useState<any>([]);

  const convertDataLopHoc = (data: string) => {
    switch (data) {
      case 'finish':
        return 'Đã hoàn thành';
      case 'unfinish':
        return 'Số lớp đang học';
      default:
        return data;
    }
  };

  const getData = async () => {
    const params = `chi_nhanh_id=${chiNhanh}&loai_nguoi_dung=ADMIN`;
    const results: IRespApiSuccess = await apiInstance.get(`${API_BASE_URL}${API_URL.BC_DASHBOARD}`, {
      params: params,
    });
    if (results?.code === 200) {
      setData(results.data);
      const tong_tien = results.data.rp_thu_chi_hang_thang.reduce(
        (totals: { tong_tien_thu: number; tong_tien_chi: number; tong_tien_thu_hoc_phi: number }, item: any) => {
          const soTienThu = parseFloat(item.tong_tien_thu);
          const soTienChi = parseFloat(item.tong_tien_chi);
          const soTienThuHocPhi = parseFloat(item.tong_tien_thu_hoc_phi);
          return {
            tong_tien_thu: totals.tong_tien_thu + soTienThu,
            tong_tien_chi: totals.tong_tien_chi + soTienChi,
            tong_tien_thu_hoc_phi: totals.tong_tien_thu_hoc_phi + soTienThuHocPhi,
          };
        },
        { tong_tien_thu: 0, tong_tien_chi: 0, tong_tien_thu_hoc_phi: 0 },
      );

      setSoTien((prevData: any) => ({
        ...prevData,
        tong_tien_thu: `${formatter(tong_tien.tong_tien_thu + tong_tien.tong_tien_thu_hoc_phi)}đ`,
        tong_tien_chi: `${formatter(tong_tien.tong_tien_chi)}đ`,
        tong_tien_thu_hoc_phi: `${formatter(tong_tien.tong_tien_thu_hoc_phi)}đ`,
        tong_tien_thu_khac: `${formatter(tong_tien.tong_tien_thu)}đ`,
      }));
    }
  };

  useEffect(() => {
    chiNhanh !== 0 && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chiNhanh]);

  const options: any = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
        formatter: (value: any) => {
          return formatter(value);
        },
        color: '#333',
      },
    },
  };

  const optionsDoughnut: any = {
    responsive: true,
    plugins: {
      datalabels: {
        formatter: (value: any, ctx: any) => {
          let sum = 0;
          const dataArr = ctx.chart.data.datasets[0].data as number[];
          dataArr.forEach((data) => {
            sum += data * 1;
          });
          const percentage = ((value * 100) / sum).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
        font: {
          weight: 'bold',
        },
      },
    },
  };

  const dataLineChart = {
    labels: data?.rp_thu_chi_hang_thang.map((item: RpThuChiHangThang) => item.thang_str),
    datasets: [
      {
        label: 'Tổng thu',
        data: data?.rp_thu_chi_hang_thang.map((item: RpThuChiHangThang) => {
          return parseFloat(item.tong_tien_thu) + parseFloat(item.tong_tien_thu_hoc_phi);
        }),
        backgroundColor: 'rgb(75,192,192)',
        borderColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Tổng chi',
        data: data?.rp_thu_chi_hang_thang.map((item: RpThuChiHangThang) => item.tong_tien_chi),
        backgroundColor: 'rgb(255, 205, 86)',
        borderColor: 'rgb(255, 205, 86)',
      },
    ],
  };

  const dataBarChart = {
    labels: data?.rp_thu_chi_hang_tuan.map((item: RpThuChiHangTuan) => item.tuan),
    datasets: [
      {
        label: 'Tổng thu',
        data: data?.rp_thu_chi_hang_tuan.map(
          (item: RpThuChiHangTuan) => parseFloat(item.tong_tien_thu) + parseFloat(item.tong_tien_thu_hoc_phi),
        ),
        backgroundColor: 'rgb(75,192,192)',
        borderColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Tổng chi',
        data: data?.rp_thu_chi_hang_tuan.map((item: RpThuChiHangTuan) => parseFloat(item.tong_tien_chi)),
        backgroundColor: 'rgb(255, 205, 86)',
        borderColor: 'rgb(255, 205, 86)',
      },
    ],
  };

  const dataPieChart = {
    labels: data?.rp_gioi_tinh_hoc_vien.map((item: RpGioiTinhHocVien) => item.gioi_tinh_str),
    datasets: [
      {
        label: 'Số lượng',
        data: data?.rp_gioi_tinh_hoc_vien.map((item: RpGioiTinhHocVien) => item.so_luong),
        backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 206, 86)'],
        hoverOffset: 1,
        borderWidth: 1,
      },
    ],
  };

  const colors = [
    'rgb(255, 99, 132)',
    'rgb(54, 162, 235)',
    'rgb(255, 206, 86)',
    'rgb(75, 192, 192)',
    'rgb(153, 102, 255)',
    'rgb(255, 159, 64)',
  ];

  const columns: any = [
    {
      title: 'STT',
      dataIndex: 'index',
      align: 'right',
      render: (_text: any, _record: any, index: any) => index + 1,
    },
    {
      title: 'Họ và tên',
      dataIndex: 'giao_vien_ten_day_du',
    },
    {
      title: 'Ngày ký hợp đồng',
      dataIndex: 'ngay_ky',
      render: (record: string): string => {
        const date = new Date(record);
        return record ? date.toLocaleDateString('en-GB') : '';
      },
      align: 'right',
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'thoi_han',
      render: (record: string): string => {
        const date = new Date(record);
        return record ? date.toLocaleDateString('en-GB') : '';
      },
      align: 'right',
    },
    {
      title: 'Loại hợp đồng',
      dataIndex: 'ten_loai_hop_dong',
    },
    {
      title: 'Ngày còn lại',
      dataIndex: 'ngay_con_lai',
      render: (record: number): number => {
        return Math.round(record);
      },
      align: 'right',
    },
  ];

  return (
    <div style={{ padding: '1rem' }}>
      <BaseRow gutter={[14, 10]}>
        {data !== undefined &&
          data.rp_so_luong_nguoi_dung.map((item: RpSoLuongNguoiDung, index: number) => {
            const bgColor = colors[index % colors.length];
            return (
              <DataCard
                key={index}
                bgColor={bgColor}
                data={item.so_luong}
                title={ConvertTextUser(item.loai_nguoi_dung)}
                icon={<UserOutlined />}
              />
            );
          })}
        <DataCard
          bgColor={'rgb(255, 159, 64)'}
          data={data?.rp_so_luong_hoc_vien_dang_hoc}
          title={'Đang học'}
          icon={<UserOutlined />}
        />
        <DataCard bgColor={'rgb(233, 102, 219)'} data={soTien.tong_tien_thu} title={'Thu'} icon={<DollarOutlined />} />
        <DataCard bgColor={'rgb(111, 39, 99)'} data={soTien.tong_tien_chi} title={'Chi'} icon={<DollarOutlined />} />
        {data?.rp_trang_thai_lop_hoc.map((item: RpTrangThaiLopHoc, index: number) => {
          const bgColor = colors[index % colors.length];
          if (item.trang_thai_str !== 'finish') {
            return (
              <DataCard
                key={index}
                bgColor={bgColor}
                data={item.so_luong}
                title={convertDataLopHoc(item.trang_thai_str)}
                icon={<UserOutlined />}
              />
            );
          }
        })}
      </BaseRow>
      <BaseRow gutter={[14, 10]} style={{ marginTop: '20px' }}>
        <BaseCol xl={12} xs={24}>
          <BaseCard padding="0.5rem">
            <BaseTypography.Title level={3}>Thu & Chi tháng</BaseTypography.Title>
            <Line data={dataLineChart as any} options={options} style={{ maxWidth: '100%' }} />
          </BaseCard>
        </BaseCol>
        <BaseCol xl={12} xs={24}>
          <BaseCard padding="0.5rem">
            <BaseTypography.Title level={3}>Học viên</BaseTypography.Title>
            <Pie data={dataPieChart} options={optionsDoughnut} style={{ maxHeight: '500px' }} />
          </BaseCard>
        </BaseCol>
        <BaseCol span={24}>
          <BaseCard padding="0.5rem">
            <BaseTypography.Title level={3}>Thu & Chi tuần</BaseTypography.Title>
            <Bar data={dataBarChart} options={options} style={{ maxWidth: '100%' }} />
          </BaseCard>
        </BaseCol>
        <BaseCol span={24}>
          <BaseTable columns={columns} dataSource={data?.rp_het_han_hop_dong} rowKey="id" />
        </BaseCol>
      </BaseRow>
    </div>
  );
};

export default Admin;
