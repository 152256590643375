import { API_BASE_URL } from '@app/configs/api-configs';
import { useResponsive } from 'hooks/useResponsive';
import React, { useEffect, useState } from 'react';
import { Overlay } from '../../../../common/Overlay/Overlay';
import { SiderLogo } from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';
import * as S from './MainSider.styles';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, ...props }) => {
  // const { isDesktop, mobileOnly, tabletOnly } = useResponsive();
  const { mobileOnly } = useResponsive();

  // const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);
  const cauHinhChungString = localStorage.getItem('cauHinhChung');
  const cauHinhChung = cauHinhChungString ? JSON.parse(cauHinhChungString) : null;
  const [logo, setLogo] = useState<any>();

  const toggleSider = () => {
    setCollapsed(!isCollapsed);
    setLogo(
      isCollapsed
        ? `${API_BASE_URL}${cauHinhChung?.logo_big.replace('public', '')}`
        : `${API_BASE_URL}${cauHinhChung?.logo_small.replace('public', '')}`,
    );
  };

  const closeSider = () => {
    setCollapsed(true);
    setLogo(`${API_BASE_URL}${cauHinhChung?.logo_small.replace('public', '')}`);
  };

  useEffect(() => {
    setLogo(`${API_BASE_URL}${cauHinhChung?.logo_small?.replace('public', '')}`);
  }, [cauHinhChung?.logo_small]);

  return (
    <>
      <S.Sider
        trigger={null}
        collapsed={isCollapsed}
        // collapsed={!isDesktop && isCollapsed}
        collapsedWidth={mobileOnly ? 0 : 80}
        // collapsedWidth={80}
        // collapsible={isCollapsible}
        width={260}
        {...props}
      >
        <SiderLogo isSiderCollapsed={isCollapsed} logo={logo} toggleSider={toggleSider} />
        <S.SiderContent>
          <SiderMenu toggleSider={closeSider} />
        </S.SiderContent>
      </S.Sider>
      {mobileOnly && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
