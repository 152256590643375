import authReducer from '@app/store/slices/authSlice';
import themeReducer from '@app/store/slices/themeSlice';
import userReducer from '@app/store/slices/userSlice';
import appReducer from '@app/store/slices/appSlice';
import detailReducer from '@app/store/slices/detailSlice';
import cauHinhChungReducer from '@app/store/slices/cauHinhChungSlice';

export default {
  user: userReducer,
  auth: authReducer,
  // nightMode: nightModeReducer,
  theme: themeReducer,
  app: appReducer,
  detail: detailReducer,
  cauHinhChung: cauHinhChungReducer,
  // pwa: pwaReducer,
};
