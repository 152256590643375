import { useEffect, useState } from 'react';
import { API_URL } from '@app/configs/api-configs';
import { selectChinhanh } from '@app/store/slices/appSlice';
import { useSelector } from 'react-redux';
import { BaseSelect } from '../selects/BaseSelect/BaseSelect';
import { getDataSelect2 } from '@app/api/app/api_getDataSelect';
import { BaseForm } from '../forms/BaseForm/BaseForm';
import { SelectProps } from '@app/interfaces/interfaces';
interface IProps extends SelectProps {
  khoiID?: number;
}

const MonHocSelect: React.FC<IProps> = (IProps) => {
  const path = API_URL.MON_HOC_OPTION;
  const chiNhanhUser = useSelector(selectChinhanh);
  const [options, setoptions] = useState<{ value: string; label: string }[]>([{ value: '', label: '' }]);

  useEffect(() => {
    async function getData() {
      const params = [
        `f[0][field]=chi_nhanh_id&f[0][operator]=equal&f[0][value]=${chiNhanhUser}`,
        IProps.khoiID
          ? `f[1][field]=mon_hoc.khoi_id&f[1][operator]=equal&f[1][value]=${IProps.khoiID}`
          : IProps.initValues
          ? `f[2][field]=mon_hoc.id&f[2][operator]=equal&f[2][value]=${IProps.initValues}`
          : ``,
        ,
      ]
        .filter(Boolean)
        .join('&');

      const data = await getDataSelect2(path, params);
      if (data) {
        const optionsSelect = data.map((item: any) => {
          return { value: item.value, label: item.label };
        });
        setoptions(optionsSelect);
      }
    }

    getData();
  }, [IProps.initValues, IProps.khoiID, chiNhanhUser, path]);

  return (
    <BaseForm.Item name={IProps.name} label={IProps.label} rules={IProps.rules}>
      <BaseSelect
        allowClear
        options={options}
        placeholder="Chọn khoá học"
        size="small"
        mode={IProps.mode}
        showSearch
        disabled={IProps.disabled}
        onChange={IProps.onChange}
      />
    </BaseForm.Item>
  );
};

export default MonHocSelect;
