/* eslint-disable prettier/prettier */
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
// import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import MedicalDashboardPage from '@app/pages/DashboardPages/MedicalDashboardPage';

//--------------------------------------------------------//
import NhanVien from '@app/pages/apps/Users/NhanVien/NhanVien';
import BangDieuKhien from '@app/pages/apps/BangDieuKhien/BangDieuKhien';
const GiaoVienPage = React.lazy(() => import('@app/pages/apps/Users/GiaoVien/GiaoVien'));
const RolesPage = React.lazy(() => import('@app/pages/apps/Systems/Roles/Roles'));
const BranchsPage = React.lazy(() => import('@app/pages/apps/Systems/ChiNhanh/ChiNhanh'));
const SessionsPage = React.lazy(() => import('@app/pages/apps/Systems/NienKhoa/NienKhoa'));
const KhoiPage = React.lazy(() => import('@app/pages/apps/Systems/Khoi/CapDo'));
const ProductsPage = React.lazy(() => import('@app/pages/apps/Systems/SanPham/SanPham'));
const ProductGroupsPage = React.lazy(() => import('@app/pages/apps/Systems/NhomSanPham/NhomSanPham'));
const BoMonPage = React.lazy(() => import('@app/pages/apps/HocTap/BoMon/BoMon'));
const MonHocPage = React.lazy(() => import('@app/pages/apps/HocTap/MonHoc/MonHoc'));
const LopHocPage = React.lazy(() => import('@app/pages/apps/HocTap/LopHoc/LopHoc'));
const PhongHocPage = React.lazy(() => import('@app/pages/apps/HocTap/PhongHoc/PhongHoc'));
const ParentsPage = React.lazy(() => import('@app/pages/apps/Users/Parents/Parents'));
const StudentsPage = React.lazy(() => import('@app/pages/apps/Users/Students/Students'));
const ThoiKhoaBieuPage = React.lazy(() => import('@app/pages/apps/HocTap/ThoiKhoaBieu/ThoiKhoaBieu'));
const DangKyGioDayPage = React.lazy(() => import('@app/pages/apps/HanhChinh/DangKyGioDay/DangKyGioDay'));
const HopDongLaoDongPage = React.lazy(() => import('@app/pages/apps/HanhChinh/HopDongLaoDong/HopDongLaoDong'));
const UuDaiPage = React.lazy(() => import('@app/pages/apps/Systems/UuDai/UuDai'));
const LoaiHopDongPage = React.lazy(() => import('@app/pages/apps/Systems/LoaiHopDong/LoaiHopDong'));
const DienChinhSachPage = React.lazy(() => import('@app/pages/apps/Systems/DienChinhSach/DienChinhSach'));
const LoaiDiemPage = React.lazy(() => import('@app/pages/apps/Systems/LoaiDiem/LoaiDiem'));
const LyDoPage = React.lazy(() => import('@app/pages/apps/Systems/LyDo/LyDo'));
const DonViTinhPage = React.lazy(() => import('@app/pages/apps/Systems/DonViTinh/DonViTinh'));
const ThietBiPage = React.lazy(() => import('@app/pages/apps/Systems/ThietBi/ThietBi'));
const ChungChiPage = React.lazy(() => import('@app/pages/apps/Systems/ChungChi/ChungChi'));
const NganSachPage = React.lazy(() => import('@app/pages/apps/HanhChinh/NganSach/NganSach'));
const CapChungChiPage = React.lazy(() => import('@app/pages/apps/HocTap/CapChungChi/CapChungChi'));
const ThuHocPhiPage = React.lazy(() => import('@app/pages/apps/HanhChinh/ThuHocPhi/ThuHocPhi'));
const QuanLyDiemPage = React.lazy(() => import('@app/pages/apps/HocTap/QuanLyDiem/QuanLyDiem'));
const NhaCungCapPage = React.lazy(() => import('@app/pages/apps/MuaBan/NhaCungCap/NhaCungCap'));
const DiemDanhHsPage = React.lazy(() => import('@app/pages/apps/DiemDanh/DiemDanhHs/DiemDanhHs'));
const DiemDanhNhanVienPage = React.lazy(() => import('@app/pages/apps/DiemDanh/DiemDanhNhanVien/DiemDanhNhanVien'));
const MuaHangPage = React.lazy(() => import('@app/pages/apps/MuaBan/MuaHang/MuaHang'));
const BanHangPage = React.lazy(() => import('@app/pages/apps/MuaBan/BanHang/BanHang'));
const KhoPage = React.lazy(() => import('@app/pages/apps/Systems/Kho/Kho'));
const NhapKhoPage = React.lazy(() => import('@app/pages/apps/MuaBan/NhapKho/NhapKho'));
const XuatKhoPage = React.lazy(() => import('@app/pages/apps/MuaBan/XuatKho/XuatKho'));
const ChuyenKhoPage = React.lazy(() => import('@app/pages/apps/MuaBan/ChuyenKho/ChuyenKho'));
const ThuTienPage = React.lazy(() => import('@app/pages/apps/MuaBan/ThuTien/ThuTien'));
const ChiTienPage = React.lazy(() => import('@app/pages/apps/MuaBan/ChiTien/ChiTien'));
const CauHinhChungPage = React.lazy(() => import('@app/pages/apps/Systems/CauHinhChung/CauHinhChung'));
const TongHopUuDaiHocPhiPage = React.lazy(() => import('@app/pages/apps/BaoCao/TongHopUuDaiHocPhi/TongHopUuDaiHocPhi'));
const ChiTietQuaTrinhDaoTaoHocVienPage = React.lazy(
  () => import('@app/pages/apps/BaoCao/ChiTietQuaTrinhDaoTaoHocVien/ChiTietQuaTrinhDaoTaoHocVien'),
);
const UuDaiHocPhiTheoHocVienPage = React.lazy(
  () => import('@app/pages/apps/BaoCao/UuDaiHocPhiTheoHocVien/UuDaiHocPhiTheoHocVien'),
);
const NhapKhoBCPage = React.lazy(() => import('@app/pages/apps/BaoCao/NhapKho/NhapKho'));
const XuatKhoBCPage = React.lazy(() => import('@app/pages/apps/BaoCao/XuatKho/XuatKho'));
const TonKhoPage = React.lazy(() => import('@app/pages/apps/BaoCao/TonKho/TonKho'));
const TonKhoHangHoaPage = React.lazy(() => import('@app/pages/apps/BaoCao/TonKho/TonKhoHangHoa'));
const BangDiemHocVienTheoLopPage = React.lazy(
  () => import('@app/pages/apps/BaoCao/BangDiemHocVienTheoLop/BangDiemHocVienTheoLop'),
);
const ChamCongPage = React.lazy(() => import('@app/pages/apps/BaoCao/ChamCong/ChamCong'));
const DiemDanhHocVienPage = React.lazy(() => import('@app/pages/apps/BaoCao/BaoCaoDiemDanh/DiemDanhHocVien'));
const BaoCaoMuaHangPage = React.lazy(() => import('@app/pages/apps/BaoCao/MuaHang/MuaHang'));
const BaoCaoBanHangPage = React.lazy(() => import('@app/pages/apps/BaoCao/BanHang/BanHang'));
const BaoCaoCongNoKhachHangPage = React.lazy(() => import('@app/pages/apps/BaoCao/CongNo/BaoCaoCongNoKH'));
const BaoCaoCongNoNhaCungCapPage = React.lazy(() => import('@app/pages/apps/BaoCao/CongNo/BaoCaoCongNoNCC'));
const BaoCaoThuHocPhiPage = React.lazy(() => import('@app/pages/apps/BaoCao/ThuHocPhi/ThuHocPhi'));
const BaoCaoThuChiPage = React.lazy(() => import('@app/pages/apps/BaoCao/ThuChi/ThuChi'));
const TietPage = React.lazy(() => import('@app/pages/apps/Systems/Tiet/Tiet'));
const ChuongTrinhPage = React.lazy(() => import('@app/pages/apps/HocTap/ChuongTrinh/ChuongTrinh'));
const ThuChiKhacPage = React.lazy(() => import('@app/pages/apps/MuaBan/ThuChiKhac/ThuChiKhac'));
const CauHinhXepLoaiPage = React.lazy(() => import('@app/pages/apps/Systems/CauHinhXepLoai/CauHinhXepLoai'));
/*import-component-here*/
//--------------------------------------------------------//
const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const MedicalDashboard = withLoading(MedicalDashboardPage);
// const NftDashboard = withLoading(NftDashboardPage);
const NewsFeed = withLoading(NewsFeedPage);
const AdvancedForm = withLoading(AdvancedFormsPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

const DataTables = withLoading(DataTablesPage);
const Charts = withLoading(ChartsPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

//--------------------------------------------------------//
const GiaoVien = withLoading(GiaoVienPage);
const Roles = withLoading(RolesPage);
const Branchs = withLoading(BranchsPage);
const Sessions = withLoading(SessionsPage);
const Khoi = withLoading(KhoiPage);
const Products = withLoading(ProductsPage);
const ProductGroups = withLoading(ProductGroupsPage);
const BoMon = withLoading(BoMonPage);
const MonHoc = withLoading(MonHocPage);
const LopHoc = withLoading(LopHocPage);
const PhongHoc = withLoading(PhongHocPage);
const Parents = withLoading(ParentsPage);
const Students = withLoading(StudentsPage);
const ThoiKhoaBieu = withLoading(ThoiKhoaBieuPage);
const DangKyGioDay = withLoading(DangKyGioDayPage);
const HopDongLaoDong = withLoading(HopDongLaoDongPage);
const UuDai = withLoading(UuDaiPage);
const LoaiHopDong = withLoading(LoaiHopDongPage);
const DienChinhSach = withLoading(DienChinhSachPage);
const LoaiDiem = withLoading(LoaiDiemPage);
const LyDo = withLoading(LyDoPage);
const DonViTinh = withLoading(DonViTinhPage);
const ThietBi = withLoading(ThietBiPage);
const ChungChi = withLoading(ChungChiPage);
const NganSach = withLoading(NganSachPage);
const CapChungChi = withLoading(CapChungChiPage);
const ThuHocPhi = withLoading(ThuHocPhiPage);
const QuanLyDiem = withLoading(QuanLyDiemPage);
const NhaCungCap = withLoading(NhaCungCapPage);
const DiemDanhHs = withLoading(DiemDanhHsPage);
const DiemDanhNhanVien = withLoading(DiemDanhNhanVienPage);
const MuaHang = withLoading(MuaHangPage);
const BanHang = withLoading(BanHangPage);
const Kho = withLoading(KhoPage);
const NhapKho = withLoading(NhapKhoPage);
const XuatKho = withLoading(XuatKhoPage);
const ChuyenKho = withLoading(ChuyenKhoPage);
const ThuTien = withLoading(ThuTienPage);
const ChiTien = withLoading(ChiTienPage);
const CauHinhChung = withLoading(CauHinhChungPage);
const TongHopUuDaiHocPhi = withLoading(TongHopUuDaiHocPhiPage);
const ChiTietQuaTrinhDaoTaoHocVien = withLoading(ChiTietQuaTrinhDaoTaoHocVienPage);
const UuDaiHocPhiTheoHocVien = withLoading(UuDaiHocPhiTheoHocVienPage);
const NhapKhoBC = withLoading(NhapKhoBCPage);
const XuatKhoBC = withLoading(XuatKhoBCPage);
const TonKho = withLoading(TonKhoPage);
const TonKhoHangHoa = withLoading(TonKhoHangHoaPage);
const BangDiemHocVienTheoLop = withLoading(BangDiemHocVienTheoLopPage);
const ChamCong = withLoading(ChamCongPage);
const DiemDanhHocVien = withLoading(DiemDanhHocVienPage);
const BaoCaoMuaHang = withLoading(BaoCaoMuaHangPage);
const BaoCaoBanHang = withLoading(BaoCaoBanHangPage);
const BaoCaoCongNoKhachHang = withLoading(BaoCaoCongNoKhachHangPage);
const BaoCaoCongNoNhaCungCap = withLoading(BaoCaoCongNoNhaCungCapPage);
const BaoCaoThuHocPhi = withLoading(BaoCaoThuHocPhiPage);
const BaoCaoThuChi = withLoading(BaoCaoThuChiPage);
const Tiet = withLoading(TietPage);
const ChuongTrinh = withLoading(ChuongTrinhPage);
const ThuChiKhac = withLoading(ThuChiKhacPage);
const CauHinhXepLoai = withLoading(CauHinhXepLoaiPage);
/*import-component-with-loading-here*/
//--------------------------------------------------------//

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route element={protectedLayout}>
          {/* ================================================= */}
          {/* WEB ============================================= */}
          {/* ================================================= */}
          <Route path="he-thong">
            {/*Declare sub-route here*/}
            <Route path="vai-tro" element={<Roles />} />
            <Route path="chi-nhanh" element={<Branchs />} />
            <Route path="nien-khoa" element={<Sessions />} />
            <Route path="cap-do" element={<Khoi />} />
            <Route path="san-pham" element={<Products />} />
            <Route path="nhom-san-pham" element={<ProductGroups />} />
            <Route path="uu-dai" element={<UuDai />} />
            <Route path="loai-hop-dong" element={<LoaiHopDong />} />
            <Route path="dien-chinh-sach" element={<DienChinhSach />} />
            <Route path="loai-diem" element={<LoaiDiem />} />
            <Route path="ly-do" element={<LyDo />} />
            <Route path="don-vi-tinh" element={<DonViTinh />} />
            <Route path="thiet-bi" element={<ThietBi />} />
            <Route path="chung-chi" element={<ChungChi />} />
            <Route path="kho" element={<Kho />} />
            <Route path="cau-hinh-chung" element={<CauHinhChung />} />
            <Route path="tiet" element={<Tiet />} />
            <Route path="thang-diem-xep-loai" element={<CauHinhXepLoai />} />
            {/*sub-route-systems*/}
          </Route>

          <Route path={NFT_DASHBOARD_PATH} element={<BangDieuKhien />} />
          <Route path="home">
            {/*Declare sub-route here*/}
            {/*sub-route-home*/}
          </Route>

          <Route path="hoc-tap">
            {/*Declare sub-route here*/}
            <Route path="bo-mon" element={<BoMon />} />
            <Route path="khoa-hoc" element={<MonHoc />} />
            <Route path="lop-hoc" element={<LopHoc />} />
            <Route path="phong-hoc" element={<PhongHoc />} />
            <Route path="thoi-khoa-bieu" element={<ThoiKhoaBieu />} />
            <Route path="cap-chung-chi" element={<CapChungChi />} />
            <Route path="quan-ly-diem" element={<QuanLyDiem />} />
            <Route path="chuong-trinh" element={<ChuongTrinh />} />
            {/*sub-route-hoc-tap*/}
          </Route>

          <Route path="diem-danh">
            {/*Declare sub-route here*/}
            <Route path="diem-danh-hoc-vien" element={<DiemDanhHs />} />
            <Route path="diem-danh-nhan-vien" element={<DiemDanhNhanVien />} />
            {/*sub-route-diem-danh*/}
          </Route>

          <Route path="hanh-chinh">
            {/*Declare sub-route here*/}
            <Route path="dang-ky-gio-day" element={<DangKyGioDay />} />
            <Route path="hop-dong-lao-dong" element={<HopDongLaoDong />} />
            <Route path="ngan-sach" element={<NganSach />} />
            <Route path="thu-hoc-phi" element={<ThuHocPhi />} />
            {/*sub-route-hanh-chinh*/}
          </Route>

          <Route path="mua-ban">
            {/*Declare sub-route here*/}
            <Route path="nha-cung-cap" element={<NhaCungCap />} />
            <Route path="mua-hang" element={<MuaHang />} />
            <Route path="ban-hang" element={<BanHang />} />
            <Route path="nhap-kho" element={<NhapKho />} />
            <Route path="xuat-kho" element={<XuatKho />} />
            <Route path="chuyen-kho" element={<ChuyenKho />} />
            <Route path="thu-tien" element={<ThuTien />} />
            <Route path="chi-tien" element={<ChiTien />} />
            <Route path="thu-chi-khac" element={<ThuChiKhac />} />
            {/*sub-route-mua-ban*/}
          </Route>

          <Route path="nguoi-dung">
            {/*Declare sub-route here*/}
            <Route path="nhan-vien" element={<NhanVien />} />
            <Route path="hoc-vien" element={<Students />} />
            <Route path="phu-huynh" element={<Parents />} />
            <Route path="giao-vien" element={<GiaoVien />} />
            {/*sub-route-nguoi-dung*/}
          </Route>

          <Route path="bao-cao">
            <Route path="tong-hop-uu-dai-hoc-phi" element={<TongHopUuDaiHocPhi />} />
            <Route path="uu-dai-hoc-phi-theo-hoc-vien" element={<UuDaiHocPhiTheoHocVien />} />
            <Route path="chi-tiet-qua-trinh-dao-tao-hoc-vien" element={<ChiTietQuaTrinhDaoTaoHocVien />} />
            <Route path="nhap-kho" element={<NhapKhoBC />} />
            <Route path="xuat-kho" element={<XuatKhoBC />} />
            <Route path="tong-hop-xuat-nhap-kho" element={<TonKho />} />
            <Route path="tong-hop-xuat-nhap-kho-hang-hoa" element={<TonKhoHangHoa />} />
            <Route path="bang-diem-hoc-vien-theo-lop" element={<BangDiemHocVienTheoLop />} />
            <Route path="cham-cong" element={<ChamCong />} />
            <Route path="bao-cao-diem-danh-hoc-vien" element={<DiemDanhHocVien />} />
            <Route path="mua-hang" element={<BaoCaoMuaHang />} />
            <Route path="ban-hang" element={<BaoCaoBanHang />} />
            <Route path="cong-no-khach-hang" element={<BaoCaoCongNoKhachHang />} />
            <Route path="cong-no-nha-cung-cap" element={<BaoCaoCongNoNhaCungCap />} />
            <Route path="thu-hoc-phi" element={<BaoCaoThuHocPhi />} />
            <Route path="bao-cao-thu-chi-chi-tiet" element={<BaoCaoThuChi />} />
            {/*Declare sub-route here*/}
            {/*sub-route-bao-cao*/}
          </Route>

          {/* ================================================= */}
          {/* TEMPLATE ======================================== */}
          {/* ================================================= */}
          {/* <Route path={NFT_DASHBOARD_PATH} element={<NftDashboard />} /> */}
          <Route path={MEDICAL_DASHBOARD_PATH} element={<MedicalDashboard />} />
          <Route path="apps">
            <Route path="feed" element={<NewsFeed />} />
          </Route>
          <Route path="forms">
            <Route path="advanced-forms" element={<AdvancedForm />} />
          </Route>
          <Route path="data-tables" element={<DataTables />} />
          <Route path="charts" element={<Charts />} />
          <Route path="maps">
            <Route path="google-maps" element={<Google />} />
            <Route path="leaflet-maps" element={<Leaflet />} />
            <Route path="react-simple-maps" element={<ReactSimple />} />
            <Route path="pigeon-maps" element={<Pigeons />} />
          </Route>
          <Route path="server-error" element={<ServerError />} />
          <Route path="*" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="payments" element={<Payments />} />
          </Route>
          <Route path="ui-components">
            <Route path="button" element={<Buttons />} />
            <Route path="spinner" element={<Spinners />} />
            <Route path="input" element={<Inputs />} />
            <Route path="checkbox" element={<Checkboxes />} />
            <Route path="radio" element={<Radios />} />
            <Route path="select" element={<Selects />} />
            <Route path="switch" element={<Switches />} />
            <Route path="upload" element={<Uploads />} />
            <Route path="rate" element={<Rates />} />
            <Route path="auto-complete" element={<AutoCompletes />} />
            <Route path="steps" element={<Steps />} />
            <Route path="date-time-picker" element={<DateTimePickers />} />
            <Route path="dropdown" element={<Dropdowns />} />
            <Route path="breadcrumbs" element={<Breadcrumbs />} />
            <Route path="tabs" element={<Tabs />} />
            <Route path="avatar" element={<Avatars />} />
            <Route path="badge" element={<Badges />} />
            <Route path="collapse" element={<Collapse />} />
            <Route path="pagination" element={<Pagination />} />
            <Route path="modal" element={<Modals />} />
            <Route path="popover" element={<Popovers />} />
            <Route path="popconfirm" element={<Popconfirms />} />
            <Route path="progress" element={<Progress />} />
            <Route path="result" element={<Results />} />
            <Route path="alert" element={<Alerts />} />
            <Route path="notification" element={<NotificationsUI />} />
            <Route path="skeleton" element={<Skeletons />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="reset-pass" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
