import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import ChiNhanhSelect from '@app/components/common/common/ChiNhanhSelect';
import GiaoVienMonHocSelect from '@app/components/common/common/GiaoVienMonHocSelect';
import KhoiSelect from '@app/components/common/common/KhoiSelect';
import LopSelect from '@app/components/common/common/LopSelect';
import MonHocSelect from '@app/components/common/common/MonHocSelect';
import PhongSelect from '@app/components/common/common/PhongSelect';
import TietSelect from '@app/components/common/common/TietSelect';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FormInstance } from 'antd';
import DatePicker from 'antd/lib/date-picker';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

interface IProps {
  isEditing?: boolean;
  form?: FormInstance;
  dataForm?: any;
}

const ThoiKhoaBieuForm: React.FC<IProps> = ({ form, isEditing }) => {
  const { khoi_id, lop_id, mon_hoc_id, giao_vien_id, ngay, tiet_id } = form?.getFieldsValue(true) || {};

  const [khoiID, setKhoiID] = useState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lopID, setLopID] = useState();
  const [monHocID, setMonHocID] = useState();
  const [giaoVienID, setGiaoVien] = useState();
  const [ngayTKB, setNgay] = useState();

  // Đặt lại giá trị state khi giá trị thay đổi
  useEffect(() => {
    setKhoiID(khoi_id);
    setMonHocID(mon_hoc_id);
    setGiaoVien(giao_vien_id);
  }, [giao_vien_id, khoi_id, mon_hoc_id]);

  function onChangeKhoi(e: any) {
    setKhoiID(e);
    form?.setFieldValue('lop_id', null);
    form?.setFieldValue('mon_hoc_id', null);
  }

  function onChangeMon(e: any) {
    setMonHocID(e);
    form?.setFieldValue('giao_vien_id', null);
  }

  function onChangeGiaoVien(e: any) {
    setGiaoVien(e);
    form?.setFieldValue('tiet_id', null);
  }

  function onChangeNgay(e: any) {
    setNgay(e);
    form?.setFieldValue('tiet_id', null);
  }

  return (
    <LopHocChiTietStyles>
      <BaseRow gutter={[12, 0]}>
        <BaseCol span={12}>
          <KhoiSelect
            name="khoi_id"
            label="Cấp độ"
            onChange={onChangeKhoi}
            initValues={khoi_id}
            rules={[{ required: true, message: 'Mã không được bỏ trống!' }]}
          ></KhoiSelect>
        </BaseCol>
        <BaseCol span={12}>
          <LopSelect
            name="lop_id"
            label="Lớp"
            khoiID={khoiID || khoi_id}
            onChange={setLopID}
            initValues={lop_id}
            rules={[{ required: true, message: 'Lớp không được bỏ trống!' }]}
          ></LopSelect>
        </BaseCol>
        <BaseCol span={12}>
          <MonHocSelect
            name="mon_hoc_id"
            label="Khoá học"
            khoiID={khoiID || khoi_id}
            onChange={onChangeMon}
            initValues={mon_hoc_id}
            rules={[{ required: true, message: 'Khoá học không được bỏ trống!' }]}
          ></MonHocSelect>
        </BaseCol>
        <BaseCol span={12}>
          <GiaoVienMonHocSelect
            name="giao_vien_id"
            label="Giáo viên"
            monHocID={monHocID || mon_hoc_id}
            onChange={onChangeGiaoVien}
            initValues={giao_vien_id}
            rules={[{ required: true, message: 'Giáo viên không được bỏ trống!' }]}
          ></GiaoVienMonHocSelect>
        </BaseCol>
        <BaseCol span={12}>
          <PhongSelect
            name="phong_hoc_id"
            label="Phòng"
            rules={[{ required: true, message: 'Phòng học không được bỏ trống!' }]}
          ></PhongSelect>
        </BaseCol>

        <BaseCol span={12}>
          <BaseForm.Item
            name="ngay"
            label="Ngày"
            initialValue={ngayTKB}
            rules={[{ required: true, message: 'Ngày không được bỏ trống!' }]}
          >
            <DatePicker
              size="small"
              placeholder="Chọn"
              format="DD/MM/YYYY"
              style={{ width: '100%' }}
              onChange={(e: any) => onChangeNgay(e)}
              disabled={isEditing}
            />
          </BaseForm.Item>
        </BaseCol>

        <BaseCol span={12}>
          <TietSelect
            name="tiet_id"
            label="Tiết"
            giaoVienID={giaoVienID || giao_vien_id}
            ngay={ngayTKB || ngay}
            initValues={tiet_id}
            rules={[{ required: true, message: 'Tiết không được bỏ trống!' }]}
          ></TietSelect>
        </BaseCol>

        <BaseCol span={8}>
          <ChiNhanhSelect
            name="chi_nhanh_id"
            label="Chi nhánh"
            rules={[{ required: true, message: 'Phòng học không được bỏ trống!' }]}
            hidden
          ></ChiNhanhSelect>
        </BaseCol>
      </BaseRow>
    </LopHocChiTietStyles>
  );
};

const LopHocChiTietStyles = styled.div`
  .ant-input-disabled,
  .ant-input-number-disabled,
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker.ant-picker-disabled,
  .ant-picker-input > input[disabled],
  .ant-input[disabled] {
    background: #ffffff;
    border-radius: 0px;
    border: 0px;
    color: #000;
    cursor: text;
  }
  .table-form {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input,
    .ant-input-number-input,
    .ant-input-number {
      border-radius: 0px;
    }
    .ant-form-item {
      margin-bottom: 0px;
    }
    .ant-input-disabled,
    .ant-input-number-disabled,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-picker.ant-picker-disabled,
    .ant-picker-input > input[disabled],
    .ant-input[disabled] {
      background: #f9f9f9;
      border: 1px solid var(--border-base-color);
      color: #000;
      cursor: text;
    }
  }
  .hidden-arrow-down {
    .ant-select-arrow {
      display: none;
    }
  }
  .border-1 {
    border: 1px solid var(--border-base-color);
    text-align: center;
  }
  .ant-select-disabled .ant-select-arrow,
  .ant-picker.ant-picker-disabled .ant-picker-suffix {
    display: none;
  }
`;
export default ThoiKhoaBieuForm;
