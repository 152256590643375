import { UploadOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseUpload, BaseUploadProps } from '@app/components/common/BaseUpload/BaseUpload';
import { API_BASE_URL } from '@app/configs/api-configs';
import { UploadFile as UploadFileInterface } from 'antd/lib/upload';
import React from 'react';

interface IProps {
  children: React.ReactNode;
  fileList: UploadFileInterface[];
  setFileList: any;
  title: string;
  fileMau?: string; // file mẩu
}

const UploadFile: React.FC<IProps> = ({ children, fileList, setFileList, title, fileMau }) => {
  const props: BaseUploadProps = {
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: any) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };
  const pathFile = `${API_BASE_URL}/filemau/${fileMau}`;

  return (
    <BaseRow>
      <BaseCol>
        <BaseUpload accept=".pdf,.doc,.docx,.xls,.xlsx" maxCount={1} {...props}>
          <BaseButton type="primary" icon={<UploadOutlined />}>
            {title}
          </BaseButton>
        </BaseUpload>
        {fileList.length > 0 && <>{children}</>}
      </BaseCol>
      <BaseCol>
        <BaseButton type="link">
          <BaseTypography.Link href={pathFile} target="_blank">
            Tải file mẫu
          </BaseTypography.Link>
        </BaseButton>
      </BaseCol>
    </BaseRow>
  );
};

export default UploadFile;
