export const ConvertTextCheckBox = (key: string): string => {
  switch (key) {
    case 'export':
      return 'Xuất file';
    case 'index':
      return 'Xem';
    case 'create':
      return 'Thêm';
    case 'show':
      return 'Chi tiết';
    case 'edit':
      return 'Sửa';
    case 'delete':
      return 'Xóa';
    case 'import':
      return 'Nhập file';
    case 'assign':
      return 'Phân công';
    case 'admin_system':
      return 'Quản trị hệ thống';
    case 'admin_department':
      return 'Quản trị phòng ban';
    default:
      return key;
  }
};

export const ConvertTextUser = (key: string): string => {
  switch (key) {
    case 'HV':
      return 'Học viên';
    case 'GV':
      return 'Giáo viên';
    case 'NV':
      return 'Nhân viên';
    case 'PH':
      return 'Phụ huynh';
    default:
      return key;
  }
};

export const ConvertTextRoles = (key: any): any => {
  switch (key) {
    case 'roles':
      return 'Vai trò';
    case 'users':
      return 'Người dùng';
    case 'bo-mon':
      return 'Bộ môn';
    case 'chi-nhanh':
      return 'Chi nhánh';
    case 'chung-chi':
      return 'Chứng nhận';
    case 'dien-chinh-sach':
      return 'Diện chính sách';
    case 'don-vi-tinh':
      return 'Đơn vị tính';
    case 'giao-vien':
      return 'Giáo viên';
    case 'hoc-vien':
      return 'Học viên';
    case 'hoc-vien-co-phu-huynh':
      return 'Học viên có phụ huynh';
    case 'huyen':
      return 'Huyện';
    case 'khoi':
      return 'Cấp độ';
    case 'cap-chung-chi':
      return 'Cấp chứng nhận';
    case 'diem-danh-hoc-vien':
      return 'Điểm danh học viên';
    case 'diem-danh-nhan-vien':
      return 'Điểm danh nhân viên';
    case 'mua-hang':
      return 'Mua hàng';
    case 'no-hoc-phi':
      return 'Nợ học phí';
    case 'loai-diem':
      return 'Loại điểm';
    case 'loai-hop-dong':
      return 'Loại hợp đồng';
    case 'lop-hoc':
      return 'Lớp học';
    case 'ly-do':
      return 'Lý do';
    case 'mon-hoc':
      return 'Môn học';
    case 'mon-hoc-co-loai-diem':
      return 'Khoá học có loại điểm';
    case 'ngan-sach':
      return 'Ngân sách';
    case 'nhom-san-pham':
      return 'Nhóm sản phẩm';
    case 'nien-khoa':
      return 'Niên khoá';
    case 'phong-hoc':
      return 'Phòng học';
    case 'phu-huynh':
      return 'Phụ huynh';
    case 'san-pham':
      return 'Sản phẩm';
    case 'tai-khoan-ke-toan':
      return 'Tài khoản kế toán';
    case 'thiet-bi':
      return 'Thiết bị';
    case 'thoi-gian-lam-viec':
      return 'Thời gian làm việc';
    case 'thoi-khoa-bieu':
      return 'Thời khoá biểu';
    case 'thu-hoc-phi':
      return 'Thu học phí';
    case 'tiet':
      return 'Tiết';
    case 'tinh':
      return 'Tỉnh';
    case 'uu-dai':
      return 'Ưu đãi';
    case 'xa':
      return 'Xã';
    case 'quan-ly-diem':
      return 'Quản lý điểm';
    case 'dang-ky-gio-day':
      return 'Đăng ký giờ dạy';
    case 'hop-dong':
      return 'Hợp đồng';
    case 'thu-tien':
      return 'Thu tiền';
    case 'chi-tien':
      return 'Chi tiền';
    case 'cau-hinh-chung':
      return 'Cấu hình chung';
    case 'giao-vien-phu-trach-mon-hoc':
      return 'Giáo viên phụ trách môn';
    case 'nha-cung-cap':
      return 'Nhà cung cấp';
    case 'nhap-kho':
      return 'Nhập kho';
    case 'san-pham-ton-kho':
      return 'Sản phẩm tồn kho';
    case 'xuat-kho':
      return 'Xuất kho';
    case 'ban-hang':
      return 'Bán hàng';
    case 'chuyen-kho':
      return 'Chuyển kho';
    case 'bao-cao':
      return 'Báo cáo';
    case 'ket-qua-danh-gia':
      return 'Kết quả đánh giá';
    case 'bao-cao-bang-diem-hoc-vien-theo-lop':
      return 'BC điểm học viên theo lớp';
    case 'bao-cao-cham-cong':
      return 'BC chấm công';
    case 'bao-cao-chi-tiet-qua-trinh-dao-tao-hoc-vien':
      return 'BC chi tiết đào đạo học viên';
    case 'bao-cao-diem-danh-hoc-vien':
      return 'Báo cáo điểm danh học viên';
    case 'bao-cao-nhap-kho':
      return 'BC nhập kho';
    case 'bao-cao-tong-hop-uu-dai-hoc-phi':
      return 'BC tổng hợp ưu đãi học phí';
    case 'bao-cao-tong-hop-xuat-nhap-kho':
      return 'BC tổng hợp xuất nhập kho';
    case 'bao-cao-uu-dai-hoc-phi-theo-hoc-vien':
      return 'BC ưu đãi học phí học viên';
    case 'bao-cao-xuat-kho':
      return 'BC xuất kho';
    case 'bao-cao-thu-chi':
      return 'BC thu chi';
    case 'thu-chi-khac':
      return 'Thu chi khác';
    case 'thang-diem-xep-loai':
      return 'Thang điểm xếp loại';
    case 'cap-do':
      return 'Cấp độ';
    case 'chuong-trinh':
      return 'Chương trình';
    case 'vi':
      return 'Ví';
    case 'kho':
      return 'Kho';
    case 'bao-cao-thu-hoc-phi':
      return 'Báo cáo thu học phí';
    case 'bao-cao-mua-hang':
      return 'Báo cáo mua hàng';
    case 'bao-cao-ban-hang':
      return 'Báo cáo bán hàng';
    case 'bao-cao-cong-no':
      return 'Báo cáo công nợ';
    case 'giao-dich-vi':
      return 'Giao dịch ví';
    case 'khoa-hoc':
      return 'khoá học';
    default:
      return key;
  }
};

export const convertColor = (color: string) => {
  switch (color) {
    case 'red':
      return 'Đỏ';
    case 'orange':
      return 'Cam';
    case 'yellow':
      return 'Vàng';
    case 'green':
      return 'Xanh lá';
    case 'blue':
      return 'Xanh dương';
    case 'purple':
      return 'Tím';
    default:
  }
};
